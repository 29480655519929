import { CursorGlyphsType } from "game-engine/types/cursor";

const CursorGlyphs: CursorGlyphsType = {
  default: {
    offsetX: 0,
    offsetY: 0,
    outlined: true,
    pixels: [
      [1, 0, 0, 0, 0, 0],
      [1, 1, 0, 0, 0, 0],
      [1, 1, 1, 0, 0, 0],
      [1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1],
      [0, 0, 0, 1, 1, 0],
      [0, 0, 0, 0, 1, 1],
    ],
  },
  arrowLeft: {
    offsetX: 0,
    offsetY: -5,
    outlined: true,
    pixels: [
      [0, 0, 0, 0, 1, 0, 0],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 1, 1, 1, 1],
      [0, 0, 0, 1, 1, 0, 0],
      [0, 0, 0, 0, 1, 0, 0],
    ],
  },
  arrowRight: {
    offsetX: -8,
    offsetY: -5,
    outlined: true,
    pixels: [
      [0, 0, 1, 0, 0, 0, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [1, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1, 1],
      [1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 0, 0, 0],
      [0, 0, 1, 0, 0, 0, 0],
    ],
  },
  arrowUp: {
    offsetX: -5,
    offsetY: 0,
    outlined: true,
    pixels: [
      [0, 0, 0, 0, 1, 0, 0, 0, 0],
      [0, 0, 0, 1, 1, 1, 0, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 1, 1, 1, 1, 1, 1, 1, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
    ],
  },
  arrowDown: {
    offsetX: -5,
    offsetY: -8,
    outlined: true,
    pixels: [
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [1, 1, 1, 1, 1, 1, 1, 1, 1],
      [0, 1, 1, 1, 1, 1, 1, 1, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 0, 0, 1, 1, 1, 0, 0, 0],
      [0, 0, 0, 0, 1, 0, 0, 0, 0],
    ],
  },
  notAllowed: {
    color: { r: 207, g: 100, b: 97, a: 255 },
    offsetX: -5,
    offsetY: -5,
    outlined: true,
    outlineNoDiagonal: true,
    pixels: [
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
      [0, 1, 0, 0, 0, 0, 1, 1, 0],
      [1, 0, 0, 0, 0, 1, 1, 0, 1],
      [1, 0, 0, 0, 1, 1, 0, 0, 1],
      [1, 0, 0, 1, 1, 0, 0, 0, 1],
      [1, 0, 1, 1, 0, 0, 0, 0, 1],
      [0, 1, 1, 0, 0, 0, 0, 1, 0],
      [0, 0, 1, 1, 1, 1, 1, 0, 0],
    ],
  },
};

export default CursorGlyphs;
