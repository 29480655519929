import {
  CinematicSceneType,
  CinematicTypeEnum,
  Direction,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { CinematicId } from "game-files/ids";
import DevScene03 from "game-files/scenes/_dev-scenes/DevScene03";
import MainCharacterConfig from "game-files/characters/MainCharacter";
import TestCharacterConfig from "game-files/characters/TestCharacter";

const TestCinematicScene01: CinematicSceneType = {
  id: CinematicId.TestCinematicScene01,
  type: CinematicTypeEnum.scene,

  uniqueSceneId: "DevScene03", // necessary for placing items in scene + for scenes outside scenes layout
  scene: DevScene03,
  audio: false, // turn off scene audio (that is defined in the scene config)

  characters: [
    {
      config: MainCharacterConfig,
      position: { x: 233, y: 134 },
      facing: Direction.left,
    },
    {
      config: TestCharacterConfig,
      position: undefined, // defined in ACTION.walkIntoScene
      facing: Direction.right,
    },
  ],

  fadeIn: { durationSec: 2 }, // or boolean
  fadeOut: true,

  actions: [
    ACTION.walkIntoScene({
      characterId: TestCharacterConfig.id,
      fromDirection: Direction.left,
      toPosition: { x: 118, y: 140 },
    }),

    ACTION.talk({
      characterId: TestCharacterConfig.id,
      dialog: {
        en: `Well, this is a cinematic scene preview.`,
        cz: `No, tohle je ukázka cinematic scény.`,
      },
    }),

    ACTION.talk({
      characterId: MainCharacterConfig.id,
      dialog: {
        en: `Yes, now we can talk like in a movie.`,
        cz: `Ano, teď můžeme kecat jako ve filmu.`,
      },
    }),

    ACTION.talk({
      characterId: TestCharacterConfig.id,
      dialog: {
        en: `Welp, I'm gonna head out.`,
        cz: `No, já to tu balím.`,
      },
    }),

    ACTION.walkOutOfScene({
      characterId: TestCharacterConfig.id,
      toDirection: Direction.left,
      fromPosition: { x: 118, y: 140 },
      unskippableWalkOut: true,
    }),
  ],
};

export default TestCinematicScene01;
