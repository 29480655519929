import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
  TextAlign,
  TextVerticalAlign,
} from "game-engine/types";

import { CinematicId } from "game-files/ids";

const TestCinematicScreen01: CinematicScreenType = {
  id: CinematicId.TestCinematicScreen01,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.scene, // TODO - AUTO-HIDE GUI FOR CinematicScreenSize.viewport

  fadeIn: true, // TODO - MAKE IT WORK
  fadeOut: { durationSec: 2 }, // or boolean // TODO - MAKE IT WORK

  background: {
    color: "#200",
  },

  textDefaults: {
    //visibleFor: { durationSec: 1 }, // NOT RECOMMENDED, VISIBILITY IS BY DEFAULT CALCULATED FROM THE LENGTH OF THE TEXT
    //pauseAfter: { durationSec: 0.5 }, // BETTER TO RELY ON DEFAULT VALUE

    color: { r: 255, g: 0, b: 0, a: 255 },
    shadow: true,
    outlined: true,
  },

  pauseBefore: {
    durationSec: 0.5,
  },
  pauseAfter: {
    durationSec: 0.5,
  },

  sequence: [
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "This is a long text example for automatic duration calculation testing and other not so interesting stuff.",
          cz: "Tohle je ukázka dlouhého textu pro vyzkoušení automatického výpočtu jak dlouho má text být zobrazen.",
        },
      },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "This is a short text.",
          cz: "Tohle je krátký text.",
        },
      },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "This is a customized text item.",
          cz: "Tohle je text s individuálním nastavením.",

          visibleFor: { durationSec: 4 }, // how long is the text visible
          pauseAfter: { durationSec: 2 }, // pause after text disappears

          color: { r: 0, g: 255, b: 255, a: 255 },
          shadow: false,
          outlined: false,
          opacity: 0.5,

          position: { x: 20, y: 20 },
          minWidth: 200,
          maxWidth: 200,
          align: TextAlign.left,
          verticalAlign: TextVerticalAlign.top,
        },
      },
    },
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "This is the last text.",
          cz: "Tohle je poslední text.",
        },
      },
    },
  ],
};

export default TestCinematicScreen01;
