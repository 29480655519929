/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import Box from "game-engine/_dev/basic-components/Box";
import CodeInput from "game-engine/_dev/basic-components/inputs/CodeInput";
import { prettifyCode } from "game-engine/utils";
import useGame from "game-engine/hooks/useGame";

//
// COMPONENT
//
const SectionEventsEditor = (props: { isOpen?: boolean }) => {
  const { gameFns, gamePlay } = useGame();

  const [eventsCode, setEventsCode] = useState<string>(
    prettifyCode(JSON.stringify(gameFns.getEvents(), null, 2))
  );

  useEffect(() => {
    try {
      const parsedEvents = JSON.parse(eventsCode);
      gameFns.setEvents(parsedEvents);
    } catch (e) {
      //errors are expected when editing
    }
  }, [eventsCode]);

  useEffect(() => {
    setEventsCode(prettifyCode(JSON.stringify(gameFns.getEvents(), null, 2)));
  }, [gamePlay.state.events]);

  //
  // RENDER
  //
  return (
    <Box
      label={"Game Events Editor"}
      level={2}
      childMinWidth="90%"
      isOpen={props.isOpen}
    >
      <CodeInput
        height="200px"
        value={eventsCode}
        onChange={setEventsCode}
        noTextWrap
      />
    </Box>
  );
};

export default SectionEventsEditor;
