import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameObjective } from "game-files/gameObjectives";
import { SkillId } from "game-files/ids";
import { SkillType } from "game-engine/types";

const Healing: SkillType = {
  id: SkillId.Healing,

  name: { en: "Scroll of Healing", cz: "Svitek uzdravení" },

  render: undefined, // TODO

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          // TODO - ADD ACTION.characterAnimation FOR READING A SKILL SCROLL
          ACTION.talk({
            dialog: { en: "Healing started", cz: "Uzdravování začíná" },
          }),
        ],
      },

      state_active: {
        duration: undefined,
      },

      state_stop: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "Healing stopped", cz: "Uzdravování končí" },
          }),
        ],
      },

      state_waitForReset: {
        duration: GAME_CONFIG.skills.resetAfter,
      },
    },
  },
};

export default Healing;
