/* eslint-disable react-hooks/exhaustive-deps */

import Item, { ItemProps } from "../Item";
import { ItemDeleteAnimation, ItemPropInSceneOverlay } from "game-engine/types";
import { useEffect, useRef, useState } from "react";

import ANIMATION_CONFIGS from "game-files/animations/ANIMATION_CONFIGS";
import Animation from "game-engine/components/basic-elements/Animation";
import { Group } from "react-konva";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import { TransitionScaleAnimation } from "game-engine/components/basic-elements/Transition/types/scale";
import useGame from "game-engine/hooks/useGame";

const ItemInSceneOverlay = (
  props: ItemProps & { sceneOverlay: ItemPropInSceneOverlay }
) => {
  const { item, sceneOverlay } = props;
  const { gameFns } = useGame();

  const pauseTimerRef = useRef<any>();
  const [playAnimation, setPlayAnimation] = useState(false);

  //
  // ANIMATION - FADE-OUT
  //
  const [fade, setFade] = useState<TransitionFadeAnimation>({ from: 1 });
  const [scale, setScale] = useState<TransitionScaleAnimation>({ from: 1 });

  const fadeOutDurationSec = sceneOverlay.deleteItemFadeOutSec;
  const scaleOutDurationSec = sceneOverlay.deleteItemScaleOutSec;

  //
  // ANIMATION SPRITE
  //
  const [animationImageLoaded, setAnimationImageLoaded] = useState(false);
  const [playSpriteAnimation, setPlaySpriteAnimation] = useState(false);

  const onAnimationImageLoaded = () => {
    setAnimationImageLoaded(true);
  };

  //
  // HANDLE PAUSE BEFORE ITEM DELETE
  //
  useEffect(() => {
    clearTimeout(pauseTimerRef.current);
    const deleteAfterSec = sceneOverlay?.deleteAfterSec;

    const triggerEnd = () => {
      setReadyToDelete(true);

      if (
        sceneOverlay.deleteAnimation ||
        fadeOutDurationSec ||
        scaleOutDurationSec
      ) {
        setPlayAnimation(true);
      }
    };

    if (deleteAfterSec) {
      pauseTimerRef.current = setTimeout(triggerEnd, deleteAfterSec * 1000);
    } else {
      triggerEnd();
    }

    return () => {
      clearTimeout(pauseTimerRef.current);
    };
  }, []);

  //
  // HANDLE ANIMATION
  //
  useEffect(() => {
    if (playAnimation) {
      // Item transitions
      if (fadeOutDurationSec) {
        setFade({
          from: 1,
          to: 0,
          durationSec: fadeOutDurationSec,
          onEnd: () => setFadeOutFinished(true),
        });
      }
      if (scaleOutDurationSec) {
        setScale({
          from: 1,
          to: 0,
          durationSec: scaleOutDurationSec,
          scaleOrigin: props.position,
          onEnd: () => setScaleOutFinished(true),
        });
      }

      // Hide item if no transitions
      if (!fadeOutDurationSec && !scaleOutDurationSec) {
        setFade({ from: 1, to: 0, durationSec: 0 });
      }

      // Sprite Animation
      if (animationImageLoaded) {
        setPlaySpriteAnimation(true);
        playAnimationSound();
      }
    }
  }, [playAnimation, animationImageLoaded]);

  //
  // HANDLE ITEM DELETION
  //
  const [readyToDelete, setReadyToDelete] = useState(false);
  const [fadeOutFinished, setFadeOutFinished] = useState(!fadeOutDurationSec);
  const [scaleOutFinished, setScaleOutFinished] = useState(
    !scaleOutDurationSec
  );
  const [animationFinished, setAnimationFinished] = useState(
    !sceneOverlay.deleteAnimation
  );

  useEffect(() => {
    if (
      readyToDelete &&
      fadeOutFinished &&
      scaleOutFinished &&
      animationFinished
    ) {
      gameFns.deleteItem(item);
    }
  }, [readyToDelete, fadeOutFinished, scaleOutFinished, animationFinished]);

  //
  // SOUNDS
  //
  const playAnimationSound = () => {
    switch (sceneOverlay.deleteAnimation) {
      case ItemDeleteAnimation.stars:
        return gameFns.playSound({ soundConfigId: SOUND_CONFIGS.MagicDust.id });
      case ItemDeleteAnimation.flame:
        return gameFns.playSound({ soundConfigId: SOUND_CONFIGS.Flame01.id });
    }
  };

  //
  // ANIMATIONS
  //
  const getAnimationConfig = () => {
    switch (sceneOverlay.deleteAnimation) {
      case ItemDeleteAnimation.stars:
        return ANIMATION_CONFIGS.Stars;
      case ItemDeleteAnimation.flame:
        return ANIMATION_CONFIGS.Flame;
    }
  };

  const renderAnimation = () => {
    const animationConfig = getAnimationConfig();

    const color =
      sceneOverlay.deleteAnimationColor && animationConfig.colors
        ? animationConfig.colors[sceneOverlay.deleteAnimationColor]
        : null;

    if (animationConfig) {
      return (
        <Group
          position={{
            x: Math.round(animationConfig?.spriteConfig?.frameWidth * -0.5),
            y: Math.round(animationConfig?.spriteConfig?.frameHeight * -0.75),
          }}
        >
          <Animation
            animationConfig={animationConfig}
            playAnimation={playSpriteAnimation}
            onImageLoaded={onAnimationImageLoaded}
            onAnimationEnd={() => {
              setAnimationFinished(true);
              setPlaySpriteAnimation(false);
            }}
            changeColor={color}
            playCount={1}
          />
        </Group>
      );
    }

    return null;
  };

  //
  // RENDER
  //
  return (
    <>
      {/* SPRITE ANIMATION */}
      {props.sceneOverlay?.deleteAnimation ? (
        <Group
          position={props.position || { x: 0, y: 0 }}
          scaleY={props.scale ?? 1}
          scaleX={props.scale ?? 1}
          listening={false}
          opacity={playSpriteAnimation ? 1 : 0}
        >
          {renderAnimation()}
        </Group>
      ) : null}

      {/* ITEM */}
      {sceneOverlay.deleteAfterSec || fadeOutDurationSec ? (
        <Transition fade={fade} scale={scale}>
          <Item {...props} />
        </Transition>
      ) : null}
    </>
  );
};

export default ItemInSceneOverlay;
