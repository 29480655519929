/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  SaveGameProps,
  SavedGameType,
  autosaveGame as autosaveGameFunc,
  clearSavedData as clearSavedDataFunc,
  createActionName,
  deathsaveGame as deathsaveGameFunc,
  deleteSavedGame as deleteSavedGameFunc,
  downloadSavedData as downloadSavedDataFunc,
  getAutosavedGame,
  getDeathsavedGame,
  getNewGameItems,
  getNewGamePlay,
  getPreviewString,
  getSavedData as getSavedDataFunc,
  getSavedSettings,
  getSkillStatesByCurrentObjective,
  playSound as playSoundFunc,
  saveGame as saveGameFunc,
  saveSettings as saveSettingsFunc,
  storeEventInDevTools,
  uploadSavedData as uploadSavedDataFunc,
} from "game-engine/utils";
import {
  CursorContext,
  DevToolsContext,
  EngineConfigContext,
  GameItemsContext,
  GamePlayContext,
} from "game-engine";
import {
  GameEffectFadeScene,
  GameEffectFadeViewport,
  GameEffectShakeViewport,
  GameItemsType,
  GamePlayAudioType,
  GamePlayCurrentSceneType,
  GamePlayCurrentSkillType,
  GamePlayHideGuiType,
  GamePlayLabelOverrideType,
  GamePlayOverrideMusicType,
  GamePlaySceneOverlayAnimation,
  GamePlayType,
  GameSettingsType,
  ItemPropInSceneOverlay,
  ItemSoundType,
  ItemType,
  Language,
  LoggerType,
  Position,
  TranslatedString,
} from "game-engine/types";
import {
  getItemDroppedLabel,
  getItemGrabbedLabel,
  getItemStoredLabel,
} from "game-engine/utils/objects/item";

import { CursorRenderType } from "game-engine/types/cursor";
import GAME_CONFIG from "game-files/gameConfig";
import { GameEventsType } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import Konva from "konva";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import { SkillId } from "game-files/ids";
import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import { getSceneByUniqueId } from "game-files/scenes/SCENE_LAYOUTS";
import { getSceneSkillActionsByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-skill-actions";
import { getSkillConfigById } from "game-files/skills/SKILL_CONFIGS";
import { loadFromLocalStorage } from "game-engine/utils/local-storage";
import { useContext } from "react";

export const LOCAL_STORAGE_MAIN_CHARACTER_POSITION = "mainCharacterPosition";
export const LOCAL_STORAGE_MAIN_CHARACTER_FACING = "mainCharacterFacing";
export const LOCAL_STORAGE_MAIN_CHARACTER_SCALE = "mainCharacterScale";

const useGame = () => {
  const engineConfig = useContext(EngineConfigContext);
  const gamePlay = useContext(GamePlayContext);
  const gameItems = useContext(GameItemsContext);
  const cursor = useContext(CursorContext);
  const devTools = useContext(DevToolsContext);

  const { window, scene } = GAME_CONFIG;
  const sceneOffset: Position = {
    x: Math.round((window.canvasDimensions.x - scene.dimensions.x) / 2),
    y: Math.round(scene.dimensions.offsetY || 0),
  };
  const sceneCenter: Position = {
    x: Math.round(scene.dimensions.x / 2),
    y: Math.round(scene.dimensions.y / 2),
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // GAME FUNCTIONS
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  const gameFns = {
    /////////////////////////////////////////////////////////////////////////////////////
    //
    // TRANSLATIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    t: (text: TranslatedString) => {
      if (text) {
        let translated = text[gamePlay.state.settings.language];
        if (translated) {
          return translated;
        }

        // Fallback on any translation that might exist
        for (const lang of Object.values(Language)) {
          translated = text[lang];
          if (translated) {
            return translated;
          }
        }
      }
      return "";
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE
    //
    /////////////////////////////////////////////////////////////////////////////////////

    //
    // Set Current Scene
    //
    setCurrentScene: (currentScene: GamePlayCurrentSceneType) => {
      gamePlay.setState((prevState) => {
        const updatedGamePlay: GamePlayType = {
          ...prevState,
          currentScene,
          effects: {
            ...prevState.effects,
            fadeScene: undefined, // reset fadeScene effect on scene change
          },
          sceneOverlayAnimation: undefined, // reset scene overlay animations on scene change
        };

        return updatedGamePlay;
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE OVERLAY ANIMATIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    //
    // Set Scene Overlay Animation
    //
    setSceneOverlayAnimation: (
      sceneOverlayAnimation: GamePlaySceneOverlayAnimation
    ) => {
      gamePlay.setState((prevState) => {
        const updatedGamePlay: GamePlayType = {
          ...prevState,
          sceneOverlayAnimation,
        };

        return updatedGamePlay;
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // ITEMS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    //
    // Grab Item
    //
    grabItem: (grabbedItem: ItemType) => {
      gameItems.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((item) =>
          item.id === grabbedItem.id
            ? {
                ...item,
                inInventory: undefined,
                inScene: undefined,
                inCursor: true,
              }
            : item
        ),
      }));

      gameFns.playItemSound({ item: grabbedItem, type: ItemSoundType.grab });

      cursor.setState((prevState) => ({
        ...prevState,
        itemInCursor: grabbedItem,
        isHidden: false,
        dropAnimation: null,
      }));

      gameFns.setOverrideLabel({ label: getItemGrabbedLabel(grabbedItem) });

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: "grab item",
            value: `${grabbedItem?.configId}`,
          }),
          data: {
            itemId: grabbedItem?.id,
            configId: grabbedItem?.configId,
          },
        },
      });
    },

    //
    // Drop Item In Scene
    //
    dropItemInScene: (options: {
      item: ItemType;
      position: Position;
      scale?: number;
      animation?: { from: Position };
    }) => {
      const { item, animation, position, scale } = options;

      const droppedItem = item;
      let dropAnimation = null;
      if (animation) {
        dropAnimation = {
          itemId: droppedItem.id,
          from: animation.from,
        };
      }

      cursor.setState((prevState) => ({
        ...prevState,
        isHidden: false,
        dropAnimation: dropAnimation,
      }));

      gameFns.playItemSound({ item: droppedItem, type: ItemSoundType.drop });

      gameItems.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((item) =>
          item.id === droppedItem.id
            ? {
                ...droppedItem,
                inInventory: undefined,
                inCursor: undefined,
                inScene: {
                  sceneId: gamePlay.state.currentScene.uniqueSceneId,
                  position: position,
                  scale: scale || 1,
                },
              }
            : item
        ),
      }));

      gameFns.setOverrideLabel({ label: getItemDroppedLabel(droppedItem) });

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: "drop item",
            value: `${droppedItem?.configId}`,
          }),
          data: {
            itemId: droppedItem?.id,
            configId: droppedItem?.configId,
          },
        },
      });
    },

    //
    // Drop Item In Inventory Slot (SWAP IT IF INVENTORY SLOT ALREADY HAS AN ITEM IN IT)
    //
    dropItemInInventorySlot: (options: {
      item: ItemType;
      slotIndex: number;
    }) => {
      const { item, slotIndex } = options;

      const itemInSlot = gameFns.getItemInInventorySlot(slotIndex);
      let grabbedItem: ItemType;
      let storedItem: ItemType;

      const logInfo = (p: {
        logMessage: string;
        eventPrefix: string;
        eventName: string;
        eventValue: string;
      }) => {
        const { logMessage, eventPrefix, eventName, eventValue } = p;
        const data = {
          itemInCursor: cursor.state.itemInCursor,
          itemInSlot,
          grabbedItem,
        };
        logger.info(logMessage, data);
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              prefix: eventPrefix,
              name: eventName,
              value: eventValue,
            }),
            data,
          },
        });
      };

      const updatedItems = gameItems.state.items.map((itm) => {
        if (itm.id === item.id) {
          // store item from cursor in inventory
          storedItem = itm;
          return {
            ...itm,
            inScene: undefined,
            inCursor: undefined,
            inInventory: {
              index: slotIndex,
            },
          };
        }
        if (itemInSlot && itm.id === itemInSlot.id) {
          // grab item that previously occupied the inventory slot
          grabbedItem = itm;
          return {
            ...itm,
            inScene: undefined,
            inCursor: true,
            inInventory: undefined,
          };
        }
        return itm;
      });

      gameItems.setState({
        ...gameItems.state, // cannot use prevState approach because grabbedItem and storedItem
        items: updatedItems, // would be assigned asynchronously, making them undefined on return
      });

      // item swapped with another in inventory slot
      if (grabbedItem) {
        gameFns.playItemSound({ item: grabbedItem, type: ItemSoundType.grab });
        gameFns.setOverrideLabel({ label: getItemGrabbedLabel(grabbedItem) });
        logInfo({
          logMessage: `item swapped in inventory slot (${slotIndex})`,
          eventPrefix: `slot ${slotIndex}`,
          eventName: `swap item`,
          eventValue: `${grabbedItem?.configId}`,
        });
      }

      // item stored in empty inventory slot
      else if (storedItem) {
        gameFns.playItemSound({ item: storedItem, type: ItemSoundType.drop });
        gameFns.setOverrideLabel({ label: getItemStoredLabel(storedItem) });
        logInfo({
          logMessage: `item stored in inventory slot (${slotIndex})`,
          eventPrefix: `slot ${slotIndex}`,
          eventName: `store item`,
          eventValue: `${storedItem?.configId}`,
        });
      }

      return {
        grabbedItem,
        storedItem,
      };
    },

    //
    // Grab Item From Inventory Slot
    //
    grabItemFromInventorySlot: (slotIndex: number) => {
      const itemInSlot = gameFns.getItemInInventorySlot(slotIndex);

      gameItems.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((itm) =>
          itm.id === itemInSlot.id
            ? {
                ...itemInSlot,
                inScene: undefined,
                inCursor: true,
                inInventory: undefined,
              }
            : itm
        ),
      }));

      gameFns.playItemSound({ item: itemInSlot, type: ItemSoundType.grab });
      gameFns.setOverrideLabel({ label: getItemGrabbedLabel(itemInSlot) });

      logger.info(`item grabbed from inventory slot (${slotIndex})`, {
        itemInCursor: cursor.state.itemInCursor,
        itemInSlot,
      });
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            prefix: `slot ${slotIndex}`,
            name: `grab item`,
            value: `${itemInSlot?.configId}`,
          }),
          data: {
            itemInSlot,
          },
        },
      });

      return itemInSlot;
    },

    //
    // Get Item From Inventory Slot
    //
    getItemInInventorySlot: (slotIndex: number) => {
      return gameItems.state.items.find(
        (itm) => itm.inInventory && itm.inInventory.index === slotIndex
      );
    },

    //
    // Delete Item
    //
    deleteItem: (item: ItemType | undefined) => {
      gameItems.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((itm) => {
          if (itm.id === item.id) {
            const updatedItem: ItemType = {
              ...itm,
              inScene: undefined,
              inCursor: undefined,
              inInventory: undefined,
              inSceneOverlay: undefined,
              inTrash: true,
            };
            return updatedItem;
          }
          return itm;
        }),
      }));

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `delete item`,
            value: `${item?.configId}`,
          }),
          data: {
            itemId: item?.id,
            configId: item?.configId,
          },
        },
      });
    },

    //
    // Put Item In Scene Overlay
    //
    putItemInSceneOverlay: (
      item: ItemType | undefined,
      sceneOverlayParams: ItemPropInSceneOverlay
    ) => {
      gameItems.setState((prevState) => ({
        ...prevState,
        items: prevState.items.map((itm) => {
          if (itm.id === item.id) {
            const updatedItem: ItemType = {
              ...itm,
              inScene: undefined,
              inCursor: undefined,
              inInventory: undefined,
              inTrash: undefined,
              inSceneOverlay: sceneOverlayParams,
            };
            return updatedItem;
          }
          return itm;
        }),
      }));

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `put item in scene overlay`,
            value: `${item?.configId}`,
          }),
          data: {
            itemId: item?.id,
            configId: item?.configId,
            params: sceneOverlayParams,
          },
        },
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // OBJECTIVES
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCurrentObjective: (newObjective: GameObjective) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        currentObjective: newObjective,
      }));
    },

    getCurrentObjective: () => {
      return gamePlay.state.currentObjective;
    },

    objectiveIsPending: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective > gameFns.getCurrentObjective();
    },

    objectiveIsActive: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective === gameFns.getCurrentObjective();
    },

    objectiveIsCompleted: (objective: GameObjective) => {
      // taking advantage of objectives being numbers
      return objective < gameFns.getCurrentObjective();
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // EVENTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setEvents: (newEvents: GameEventsType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        events: newEvents,
      }));

      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `set game events`,
          }),
          data: newEvents,
        },
      });
    },

    getEvents: () => {
      return gamePlay.state.events;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SKILLS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getActiveSkillIds: (options?: {
      excludeSkillsWithNoDuration?: boolean;
    }) => {
      const activeSkillIds = [];

      Object.entries(gamePlay.state.currentSkills || {}).forEach((entry) => {
        const skillId = entry[0] as SkillId;
        const currentSkill = entry[1] as GamePlayCurrentSkillType;

        const excludeBasedOnDuration =
          options?.excludeSkillsWithNoDuration &&
          !currentSkill.activatedForTime &&
          !currentSkill.activeForSceneChangeCount;

        if (!excludeBasedOnDuration && currentSkill?.isActive) {
          activeSkillIds.push(skillId);
        }
      });

      return activeSkillIds;
    },

    getCurrentSkillStates: (skillId: SkillId) => {
      const skillConfig = getSkillConfigById(skillId);
      const currentStates = getSkillStatesByCurrentObjective({
        dataByCurrentObjective: skillConfig.states,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      });

      return currentStates;
    },

    startSkill: (skillId: SkillId) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        const currentTimeMilliseconds = Date.now();

        const skill = getSkillConfigById(skillId);

        const currentStates = getSkillStatesByCurrentObjective({
          dataByCurrentObjective: skill.states,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        });

        const activatedForTime =
          currentStates.state_active?.duration?.durationSec ?? 0;
        const pausedForTime =
          currentStates.state_waitForReset?.duration?.durationSec ?? 0;

        currentSkills[skillId] = {
          isActive: true,
          progress: 1,
          activatedAtTime: currentTimeMilliseconds,
          activatedForTime: activatedForTime * 1000, // convert to milliseconds
          activeForSceneChangeCount:
            currentStates.state_active?.duration?.sceneChangeCount,

          isPaused: false,
          pausedAtTime: undefined,
          pausedForTime: pausedForTime * 1000, // convert to milliseconds
          pauseForSceneChangeCount:
            currentStates.state_waitForReset?.duration?.sceneChangeCount,
        };

        return {
          ...prevState,
          currentSkills,
        };
      });
    },

    setSkillProgress: (skillId: SkillId, progress: number) => {
      if (gamePlay.state.currentSkills[skillId]?.isActive) {
        // update progress only if the skill is active
        gamePlay.setState((prevState) => {
          const currentSkills = { ...(prevState.currentSkills || {}) };

          currentSkills[skillId] = {
            ...currentSkills[skillId],
            progress,
          };

          return {
            ...prevState,
            currentSkills,
          };
        });
      }
    },

    stopSkill: (
      skillId: SkillId,
      options?: { appendStopActions?: boolean; prependStopActions?: boolean }
    ) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        const storedSkill = currentSkills[skillId];
        const currentTimeMilliseconds = Date.now();

        currentSkills[skillId] = {
          isActive: false,
          progress: undefined,
          activatedAtTime: undefined,
          activatedForTime: undefined,
          activeForSceneChangeCount: undefined,

          isPaused: true,
          pausedAtTime: storedSkill?.pausedForTime
            ? currentTimeMilliseconds
            : undefined,
          pausedForTime: storedSkill?.pausedForTime,
          pauseForSceneChangeCount: storedSkill?.pauseForSceneChangeCount,

          appendStopActions: options?.appendStopActions,
          prependStopActions: options?.prependStopActions,
        };

        return {
          ...prevState,
          currentSkills,
        };
      });
    },

    stopAllSkills: (options?: {
      appendStopActions?: boolean;
      prependStopActions?: boolean;
    }) => {
      Object.keys({ ...(gamePlay.state.currentSkills || {}) }).forEach(
        (skillId) => {
          gameFns.stopSkill(skillId as SkillId, options);
        }
      );
    },

    resetSkill: (skillId: SkillId) => {
      gamePlay.setState((prevState) => {
        const currentSkills = { ...(prevState.currentSkills || {}) };
        delete currentSkills[skillId];

        return {
          ...prevState,
          currentSkills: Object.keys(currentSkills)?.length
            ? currentSkills
            : undefined,
        };
      });
    },

    getSkillActionsOnStart: (skillId: SkillId) => {
      const skill = getSkillConfigById(skillId);
      const currentStates = getSkillStatesByCurrentObjective({
        dataByCurrentObjective: skill.states,
        currentObjective: gameFns.getCurrentObjective(),
        events: gameFns.getEvents(),
      });

      // skill-defined actions on start
      let actionsOnStart =
        currentStates?.state_start?.getActions({
          gamePlay: gamePlay.state,
          gameItems: gameItems.state,
        }) || [];

      // Start skill
      actionsOnStart.push(ACTION.startSkill(skillId));

      // scene-defined skill actions on start
      const currentSceneConfig = getSceneByUniqueId(
        gamePlay.state.currentScene.uniqueSceneId
      );

      if (
        currentSceneConfig?.onSkillStart &&
        currentSceneConfig?.onSkillStart[skillId]
      ) {
        const { actionsBeforeSkill, actions } =
          getSceneSkillActionsByCurrentObjective({
            actionsByCurrentObjective:
              currentSceneConfig?.onSkillStart[skillId]
                .actionsByCurrentObjective,
            currentObjective: gameFns.getCurrentObjective(),
          });

        if (actionsBeforeSkill?.length) {
          actionsOnStart = [...actionsBeforeSkill, ...(actionsOnStart || [])];
        }
        if (actions?.length) {
          actionsOnStart.push(...actions);
        }
      }

      // Stop skill if no duration
      const hasNoDuration =
        !currentStates?.state_active?.duration ||
        (!currentStates?.state_active?.duration?.durationSec &&
          !currentStates?.state_active?.duration?.sceneChangeCount);

      if (hasNoDuration) {
        // this ensures that onStart actions run before stopping the skill
        actionsOnStart.push(ACTION.stopSkill(skill.id));
      }

      return actionsOnStart;
    },

    isSkillStopIgnoredBySceneTag: (skillId: SkillId) => {
      const currentStates = gameFns.getCurrentSkillStates(skillId);

      if (!currentStates?.state_stop?.ignoreSceneTags?.length) {
        return false;
      }

      const sceneTagsSet = new Set(
        getSceneByUniqueId(gamePlay.state.currentScene.uniqueSceneId)?.tags ||
          []
      );

      return currentStates?.state_stop?.ignoreSceneTags.some((tag) =>
        sceneTagsSet.has(tag)
      );
    },

    getSkillActionsOnStop: (skillId: SkillId) => {
      const currentStates = gameFns.getCurrentSkillStates(skillId);
      const actionsOnStop =
        currentStates?.state_stop?.getActions({
          gamePlay: gamePlay.state,
          gameItems: gameItems.state,
        }) || [];

      return actionsOnStop;
    },

    incrementSkillSceneChangeCount: () => {
      const currentSkills = { ...gamePlay.state.currentSkills };
      const skillIdsToStop = [];
      const skillIdsToReset = [];

      Object.values(SkillId).forEach((skillId) => {
        const currentSkill = currentSkills[skillId];
        if (
          currentSkill?.isActive &&
          currentSkill.activeForSceneChangeCount !== undefined
        ) {
          const updatedActiveSceneChangeCount =
            currentSkill.activeForSceneChangeCount - 1;
          currentSkills[skillId].activeForSceneChangeCount =
            updatedActiveSceneChangeCount;

          if (
            updatedActiveSceneChangeCount <= 0 &&
            !gameFns.isSkillStopIgnoredBySceneTag(skillId)
          ) {
            skillIdsToStop.push(skillId);
          }
        }

        if (
          currentSkill?.isPaused &&
          currentSkill.pauseForSceneChangeCount !== undefined
        ) {
          const updatedPausedSceneChangeCount =
            currentSkill.pauseForSceneChangeCount - 1;
          currentSkills[skillId].pauseForSceneChangeCount =
            updatedPausedSceneChangeCount;

          if (updatedPausedSceneChangeCount <= 0) {
            skillIdsToReset.push(skillId);
          }
        }
      });

      return { skillIdsToStop, skillIdsToReset };
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // IGNORE IDLE
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCharacterIdsForIdleIgnore: (characterIds: string[]) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        ignoreIdle: {
          ...prevState.ignoreIdle,
          characterIds,
        },
      }));
    },

    getCharacterIdsForIdleIgnore: () => {
      return gamePlay.state.ignoreIdle?.characterIds || [];
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CLICK EVENTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getClickedPosition: (e: Konva.KonvaPointerEvent) => {
      const pointerPosition = e.target.getStage().getPointerPosition();
      const position = {
        x: Math.round(pointerPosition.x),
        y: Math.round(pointerPosition.y),
      };
      return position;
    },

    getClickedScenePosition: (e: Konva.KonvaPointerEvent) => {
      const pointerPosition = e.target.getStage().getPointerPosition();
      const position = {
        x: Math.round(pointerPosition.x - sceneOffset.x),
        y: Math.round(pointerPosition.y - sceneOffset.y),
      };
      return position;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CURSOR FUNCTIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setCursorVisibility: (value: boolean) => {
      // NOTE - THIS FUNC IS UNNECESSARY, CURSOR IS AUTOMATICALLY HIDDEN BY SHOWING GUI BLOCKER
      logger.info(`cursor ${value ? "visible" : "hidden"}`);

      cursor.setState((prevState) => ({
        ...prevState,
        isHidden: !value,
      }));
    },

    setCursorRenderMode: (value: CursorRenderType) => {
      logger.info(`cursor render mode set to "${cursor.state.renderMode}"`);

      cursor.setState((prevState) => ({
        ...prevState,
        renderMode: value,
      }));
    },

    setItemInCursor: (item: ItemType | undefined) => {
      cursor.setState((prevState) => ({
        ...prevState,
        itemInCursor: item,
      }));
    },

    getItemInCursor: () => {
      return cursor.state.itemInCursor;
    },

    deleteItemInCursor: () => {
      const item = cursor.state.itemInCursor;
      if (item) {
        gameFns.setItemInCursor(undefined);
        gameFns.deleteItem(item);
      }
    },

    setCursorItemDropAnimation: (
      dropAnimation: { itemId: string; from: Position } | undefined
    ) => {
      cursor.setState((prevState) => ({
        ...prevState,
        dropAnimation: dropAnimation,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GAME EFFECTS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setEffectShakeViewport: (effect: GameEffectShakeViewport) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          viewportShake: effect,
        },
      }));

      logger.info(`shake viewport effect set`, effect);
    },

    setEffectFadeViewport: (effect: GameEffectFadeViewport) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          fadeViewport: effect,
        },
      }));

      logger.info(`viewport fade effect set`, effect);
    },

    setEffectFadeScene: (effect: GameEffectFadeScene) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        effects: {
          ...prevState.effects,
          fadeScene: effect,
        },
      }));

      logger.info(`scene fade effect set`, effect);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GUI FUNCTIONS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setOverrideLabel: (labelOverride: GamePlayLabelOverrideType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        labelOverride: labelOverride,
      }));

      logger.info(`action label set to '${gameFns.t(labelOverride?.label)}'`);
    },

    setHideGui: (hideGui: GamePlayHideGuiType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        hideGui,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // NEW GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    newGame: (params?: { skipIntro?: boolean }) => {
      try {
        // Set new-game gamePlay
        gamePlay.setState(
          getNewGamePlay({
            currentSettings: gamePlay.state.settings,
            skipIntro: params?.skipIntro,
          })
        );

        // Set new-game items
        gameItems.setState(getNewGameItems());

        // Loggers
        logger.info("new game loaded");
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              name: `NEW GAME`,
            }),
          },
        });

        return true;
      } catch (e) {
        logger.error("new game failed", e);
      }
      return false;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SAVE / LOAD GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    saveGame: (options: {
      name: string;
      overwriteSavedGame?: SavedGameType;
    }) => {
      saveGameFunc(gameFns.getSaveGameData(options));
      logger.info("game saved", options);
      storeEventInDevTools({
        devTools,
        event: {
          name: createActionName({
            name: `save game`,
            value: `'${getPreviewString(options.name)}'`,
          }),
          data: {
            name: options.name,
            overwriteGame: options.overwriteSavedGame?.name,
          },
        },
      });
    },

    loadGame: (savedGame: SavedGameType) => {
      try {
        if (!(savedGame?.gamePlay && savedGame?.gameItems)) {
          throw new Error("saved game incomplete");
        }

        // base load game on new game data in case loaded game is missing new features
        const newGamePlay = getNewGamePlay({
          currentSettings: gamePlay.state.settings,
        });

        const autosavedSettings = getSavedSettings() || {};

        // SET LOADED DATA
        gamePlay.setState({
          ...newGamePlay,
          ...savedGame.gamePlay,
          isLoadGame: true,
          isAutoSaving: true,
          settings: { ...newGamePlay.settings, ...autosavedSettings }, // ignore settings from game, keep current settings
          mainCharacterIsDead: undefined,
          labelOverride: undefined,
          audio: {
            overrideMusic: undefined,
          },
          events: {
            ...newGamePlay.events,
            ...savedGame.gamePlay.events,
          },
          currentScene: {
            ...savedGame.gamePlay.currentScene,
            walkIn: undefined,
          },
        });

        const newGameItems: GameItemsType = getNewGameItems();
        const loadGameItems: ItemType[] = [...savedGame.gameItems?.items];
        const loadGameItemIds: string[] = loadGameItems.map((item) => item.id);

        // if a new item is added to the game, and the save file doesn't know about it
        // the new item will not be a part of the game -> go through game items, and add
        // new game items that are missing in the save (by id)
        newGameItems?.items?.forEach((newGameItem) => {
          if (!loadGameItemIds.includes(newGameItem.id)) {
            loadGameItems.push(newGameItem);
          }
        });

        gameItems.setState({
          ...newGameItems, // in case loaded game is missing some new features
          ...savedGame.gameItems,
          items: loadGameItems,
        });

        logger.info("game loaded", savedGame);
        storeEventInDevTools({
          devTools,
          event: {
            name: createActionName({
              name: `load game`,
              value: `'${getPreviewString(savedGame.name)}'`,
            }),
            data: {
              name: savedGame.name,
            },
          },
        });
        return true;
      } catch (e) {
        logger.error("game load failed", e, savedGame);
      }

      return false;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUTOSAVE / LOAD AUTOSAVED GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    autosaveGame: () => {
      if (gamePlay.state.isAutoSaving) {
        const savedGame = gameFns.getSaveGameData({ name: "autosave" });
        autosaveGameFunc(savedGame);
        logger.info("game auto saved");
      }
    },

    loadAutosavedGame: () => {
      const autosavedGame = getAutosavedGame();
      if (autosavedGame) {
        gameFns.loadGame(autosavedGame);
        return true;
      }
      return false;
    },

    setAutoSaving: (value) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        isAutoSaving: value,
      }));
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // DEATHSAVE / LOAD DEATHSAVED GAME
    //
    /////////////////////////////////////////////////////////////////////////////////////

    deathsaveGame: () => {
      gameFns.setAutoSaving(false); // necessary for cases when user refreshes window after dying!
      deathsaveGameFunc(gameFns.getSaveGameData({ name: "deathsave" }));
      logger.info("game auto saved before death");
    },

    loadDeathsavedGame: () => {
      const deathsavedGame = getDeathsavedGame();
      gameFns.loadGame(deathsavedGame);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // CREATE SAVED GAME DATA
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getSaveGameData: (options: {
      name: string;
      overwriteSavedGame?: SavedGameType;
    }) => {
      // Get Main Character Info
      const mainCharacterPosition = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_POSITION)
      );
      const mainCharacterFacing = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_FACING)
      );
      const mainCharacterScale = JSON.parse(
        loadFromLocalStorage(LOCAL_STORAGE_MAIN_CHARACTER_SCALE)
      );

      const saveGameItems: SaveGameProps = {
        name: options.name || "saved-game",
        saveVersion: GAME_CONFIG.saveVersion,
        gamePlay: {
          ...gamePlay.state,
          mainCharacterPosition,
          mainCharacterFacing,
          mainCharacterScale,
        },
        gameItems: gameItems.state,
        overwriteSavedGame: options.overwriteSavedGame,
      };

      return saveGameItems;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // SAVED DATA
    //
    /////////////////////////////////////////////////////////////////////////////////////

    getSavedData: () => {
      const savedData = getSavedDataFunc();
      return savedData;
    },

    clearSavedData: () => {
      clearSavedDataFunc();
      logger.info("saved data cleared");
    },

    downloadSavedData: () => {
      downloadSavedDataFunc();
      logger.info("saved data downloaded");
    },

    uploadSavedData: async () => {
      try {
        await uploadSavedDataFunc();
        logger.info("saved data uploaded");
        return true;
      } catch (e) {
        logger.error("saved data upload failed", e);
      }
      return false;
    },

    deleteSavedGame: (deleteGame: SavedGameType) => {
      deleteSavedGameFunc(deleteGame);
      logger.info("saved game deleted", deleteGame);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // MAIN CHARACTER DEATH
    //
    /////////////////////////////////////////////////////////////////////////////////////

    killMainCharacter: () => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        mainCharacterIsDead: true,
      }));
      logger.info("main character died");
    },

    isMainCharacterDead: () => {
      return gamePlay.state.mainCharacterIsDead;
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUDIO - MUSIC AND BACKGROUND SOUND
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setAudio: (audio: GamePlayAudioType) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        audio: {
          ...prevState.audio,
          ...audio,
        },
      }));

      logger.info("audio set", audio);
    },

    setOverrideMusic: (overrideMusic: GamePlayOverrideMusicType) => {
      gameFns.setAudio({
        overrideMusic: overrideMusic,
      });

      logger.info("special music set", overrideMusic);
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // AUDIO - DIRECT-PLAY SOUNDS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    playSound: (options: {
      soundConfigId: string;
      volume?: number;
      repeat?: number;
      onEnd?: () => void;
    }) => {
      const { soundConfigId, volume = 1, repeat = 1, onEnd } = options;

      // Use setTimeout to run the playSoundFunc asynchronously
      setTimeout(() => {
        try {
          playSoundFunc({
            soundConfigId,
            volume,
            repeat,
            onEnd,
            logger,
            gamePlay: gamePlay.state,
          });
        } catch (error) {
          console.error("Error playing sound:", error);
        }
      }, 0);
    },

    playItemSound: (options: { item: ItemType; type: ItemSoundType }) => {
      const { item, type } = options;
      const itemConfig = getItemConfigById(item?.configId);
      let soundConfigId = itemConfig?.sounds
        ? itemConfig.sounds[type]?.soundConfigId
        : undefined;

      if (!soundConfigId) {
        switch (type) {
          case ItemSoundType.grab:
            soundConfigId = SOUND_CONFIGS.Test_Grab.id;
            break;
          case ItemSoundType.drop:
            soundConfigId = SOUND_CONFIGS.Test_Drop.id;
            break;
        }
      }

      gameFns.playSound({
        soundConfigId: soundConfigId,
        volume: GAME_CONFIG.audio.itemSoundVolume,
      });
    },

    /////////////////////////////////////////////////////////////////////////////////////
    //
    // GAME SETTINGS
    //
    /////////////////////////////////////////////////////////////////////////////////////

    setSettings: (settings: Partial<GameSettingsType>) => {
      gamePlay.setState((prevState) => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          ...settings,
        },
      }));
    },

    saveSettings: () => {
      saveSettingsFunc({ gameSettings: gamePlay.state.settings });
      logger.info("game settings saved");
    },

    loadSettings: () => {
      const savedSettings: GameSettingsType = getSavedSettings();
      gameFns.setSettings(savedSettings);
      logger.info("game settings loaded");
    },
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // LOGGER
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  const customConsoleLog = (options: {
    message: any;
    background: string;
    visible: boolean;
    data?: any[];
  }) => {
    if (options.visible) {
      (options.data ? console.groupCollapsed : console.log)(
        "%c" + options.message,
        `background-color: ${options.background}; padding: 4px 10px`
      );
      if (options.data) {
        console.info(...options.data);
        console.groupEnd();
      }
    }
  };

  const logger: LoggerType = {
    info: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerInfoColor,
        visible: engineConfig.state.loggerInfo,
        data,
      }),

    graphics: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerGraphicsColor,
        visible: engineConfig.state.loggerGraphics,
        data,
      }),

    action: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerActionsColor,
        visible: engineConfig.state.loggerActions,
        data,
      }),

    audio: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerAudioColor,
        visible: engineConfig.state.loggerAudio,
        data,
      }),

    error: (message, ...data) =>
      customConsoleLog({
        message,
        background: engineConfig.state.loggerErrorsColor,
        visible: engineConfig.state.loggerErrors,
        data,
      }),
  };

  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  //
  // RETURN DATA
  //
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////
  ///////////////////////////////////////////////////////////////////////////////////////

  return {
    gamePlay,
    gameItems,
    engineConfig,
    cursor,
    sceneOffset,
    sceneCenter,
    logger,
    gameFns,
  };
};

//
// EXPORT
//
export default useGame;

export type GameFnsType = ReturnType<typeof useGame>["gameFns"];
