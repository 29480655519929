/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderAnimationType,
  CharacterRenderMode,
  CharacterRenderType,
  Direction,
  Position,
  SceneCharacterPlayAnimationType,
  TalkRenderOptionsType,
  TranslatedString,
} from "game-engine/types";
import { useEffect, useMemo, useState } from "react";

import CharacterDialog from "./dialog";
import { CharacterId } from "game-files/ids";
import CharacterRender from "./render-mode";
import { Group } from "react-konva";
import { getCharacterRenderByCurrentObjective } from "game-engine/utils/get-by-current-objective";
import useCharacterIdle from "./hooks/useCharacterIdle";
import useGame from "game-engine/hooks/useGame";

export enum CharacterPrimaryRenderMode {
  "default" = "default",
  "walk" = "walk",
  "talk" = "talk",
  "animations" = "animations",
}

export enum CharacterOverrideRenderMode {
  "celestialFlame" = "celestialFlame",
}

export type CharacterProps = {
  config: CharacterConfigType;
  characterRender?: CharacterRenderType; //SHOULD ONLY BE USED FOR DEV TOOLS!
  renderMode?: CharacterRenderMode;
  facing?: Direction;
  position: Position;
  ignoreIdleAnimations?: boolean;
  walkAnimationFrame?: number;
  scale?: number;
  dialog?: TranslatedString;
  onClick?: (e?: any) => void;
  onImagesLoaded?: () => void;
  talkRenderOptions?: TalkRenderOptionsType;
  playAnimation?: SceneCharacterPlayAnimationType;
  renderOrigin?: boolean;
  originColor?: string;
  isInvisibilityRender?: boolean; // this means the character is rendered offscreen canvas for alpha masking
};

const Character = (props: CharacterProps) => {
  const {
    config,
    onImagesLoaded,
    ignoreIdleAnimations,
    renderOrigin,
    originColor,
    isInvisibilityRender,
  } = props;
  const { gamePlay, engineConfig, logger, gameFns } = useGame();

  //
  // DATA BY CURRENT OBJECTIVE
  //
  const characterRender = useMemo<CharacterRenderType>(() => {
    if (props.characterRender) {
      // THIS SHOULD ONLY BE USED FOR DEV TOOLS
      return props.characterRender;
    }

    return getCharacterRenderByCurrentObjective({
      dataByCurrentObjective: config.render,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
  }, [config, gamePlay.state.currentObjective, gamePlay.state.events]);

  const idleAnimations = useMemo<CharacterRenderAnimationType[]>(() => {
    return characterRender?.animations?.filter((a) => a.isIdle) || [];
  }, [characterRender]);

  //
  // HANDLE IMAGE LOADING
  //
  const [imageLoader, setImageLoader] = useState({
    default: false,
    walk: false,
    talk: false,
    animations: false,
  });

  useEffect(() => {
    const waitToLoadCount = Object.values(imageLoader).filter((v) => !v).length;
    if (!waitToLoadCount) {
      logger.info(
        `scene character images loaded (${config?.id})`,
        imageLoader,
        config
      );
      if (onImagesLoaded) onImagesLoaded();
    }
  }, [imageLoader]);

  const onImageLoaded = (key: CharacterPrimaryRenderMode) => {
    setImageLoader((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [key]: true };
      return updatedLoaded;
    });
  };

  //
  // STATE
  //
  const isDead = () => {
    return (
      config.id === CharacterId.MainCharacter && gameFns.isMainCharacterDead()
    );
  };

  //
  // STATE - RENDER MODE OVERRIDES
  //
  const [overrideRenderMode, setOverrideRenderMode] =
    useState<CharacterOverrideRenderMode>();

  useEffect(() => {
    if (config.id === CharacterId.MainCharacter) {
      setOverrideRenderMode(
        gamePlay.state.currentSkills?.CelestialFlame?.isActive
          ? CharacterOverrideRenderMode.celestialFlame
          : undefined
      );
    }
  }, [gamePlay.state.currentSkills?.CelestialFlame?.isActive]);

  //
  // STATE - RENDER MODE
  //
  const [renderMode, setRenderMode] = useState(
    props.renderMode || CharacterRenderMode.default
  );

  const renderDialog =
    (renderMode === CharacterRenderMode.talk ||
      renderMode === CharacterRenderMode.talkIdle ||
      engineConfig.state.renderCharacterDialogLong ||
      engineConfig.state.renderCharacterDialogShort) &&
    !isDead();

  useEffect(() => {
    if (isDead()) {
      // DEAD CHARACTER CANNOT CHANGE RENDER MODE
      logger.info(
        `render mode ignored, character is dead (${config.id})`,
        props
      );
      return;
    }
    if (
      props.renderMode === CharacterRenderMode.talkIdle &&
      renderMode !== CharacterRenderMode.default
    ) {
      // PREVENT IDLE TALK IF CHARACTER IS ALREADY DOING SOMETHING
      logger.info(`idle talk ignored (${config.id})`, props);
      return;
    }

    setRenderMode(props.renderMode || CharacterRenderMode.default);
  }, [props.renderMode]);

  //
  // STATE - FACING
  //
  const [facing, setFacing] = useState<Direction>(
    props.facing || Direction.left
  );

  useEffect(() => {
    if (props.facing) {
      setFacing(props.facing);
    }
  }, [props.facing]);

  //
  // STATE - IS-INVISIBLE
  //
  const [isInvisible, setIsInvisible] = useState<boolean>(); // TODO - USE IT TO MAKE THE CHARACTER INVISIBLE

  useEffect(() => {
    if (config.id === CharacterId.MainCharacter) {
      const invisibilityIsActive =
        gamePlay.state.currentSkills?.Invisibility?.isActive;

      const invisibilityTransitionIsFinished =
        gamePlay.state.currentSkills?.Invisibility?.progress === 2;

      setIsInvisible(invisibilityIsActive && invisibilityTransitionIsFinished);
    }
  }, [
    gamePlay.state.currentSkills?.Invisibility?.isActive,
    gamePlay.state.currentSkills?.Invisibility?.progress,
  ]);

  //
  // ON IDLE
  //
  const { onIdleEnd, idleIndex } = useCharacterIdle({
    characterConfig: config,
    idleAnimations,
    renderMode,
    setRenderMode,
  });

  //
  // LOGIC
  //
  const onClick = (e) => {
    logger.info(`character clicked (${config.id})`, config);
    if (props.onClick) {
      props.onClick(e);
    }
  };

  //
  // RENDER
  //
  return (
    <>
      <Group
        opacity={
          isInvisibilityRender
            ? 1 // invisibility render is the separately rendered layer for distortion effect alpha channel - must be visible
            : isInvisible
            ? 0.01
            : 1
        }
      >
        <CharacterRender
          config={config}
          position={props.position}
          scale={props.scale}
          facing={facing}
          renderMode={renderMode}
          overrideRenderMode={overrideRenderMode}
          characterRender={characterRender}
          onClick={onClick}
          onImageLoaded={onImageLoaded}
          onIdleEnd={onIdleEnd}
          idleIndex={idleIndex}
          idleAnimations={idleAnimations}
          ignoreIdleAnimations={ignoreIdleAnimations}
          walkAnimationFrame={props.walkAnimationFrame}
          talkRenderOptions={props.talkRenderOptions}
          playAnimation={props.playAnimation}
          renderOrigin={renderOrigin}
          originColor={originColor}
        />
      </Group>

      {!isInvisibilityRender && renderDialog ? (
        <CharacterDialog
          characterConfig={config}
          characterPosition={props.position}
          dialog={props.dialog}
          color={
            overrideRenderMode === CharacterOverrideRenderMode.celestialFlame
              ? { r: 102, g: 202, b: 236, a: 255 }
              : undefined
          }
        />
      ) : null}
    </>
  );
};

export default Character;
