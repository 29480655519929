/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderType,
  ImageType,
} from "game-engine/types";
import { getCharacterDimensions, getImageOffset } from "game-engine/utils";

import { Group } from "react-konva";
import SpriteAnimatedSingle from "game-engine/components/basic-elements/SpriteAnimatedSingle";
import useGame from "game-engine/hooks/useGame";

const CharacterImageDefault = (props: {
  config: CharacterConfigType;
  characterRender: CharacterRenderType;
  isActive?: boolean;
  onImagesLoaded: () => void;
}) => {
  const { config, characterRender, isActive } = props;
  const { engineConfig, logger } = useGame();

  const image: ImageType =
    characterRender.default.image ?? characterRender.default.sprite;

  const { width, height } = getCharacterDimensions(characterRender);

  if (!image?.src) {
    logger.error(`default character image missing (${config.id})`, config);
    return null;
  }

  const onImageLoaded = () => {
    props.onImagesLoaded();
    logger.graphics(`${config?.id} default image loaded`, config);
  };

  //
  // RENDER
  //
  return (
    <Group
      opacity={isActive ? 1 : 0}
      listening={isActive}
      position={getImageOffset({ width, height })}
    >
      <Group
        x={characterRender.default.offsetX || 0}
        y={characterRender.default.offsetY || 0}
      >
        <SpriteAnimatedSingle
          src={image.src}
          spriteConfig={characterRender.default.spriteConfig}
          loggerString={
            characterRender.default.spriteConfig
              ? `character default animation (${config.id})`
              : undefined
          }
          controlledAnimation={{
            playAnimation: isActive,
          }}
          renderOutline={engineConfig.state.renderCharacterOutline}
          renderFill={engineConfig.state.renderCharacterFill}
          outlineColor={engineConfig.state.characterOutlineColor}
          fillColor={engineConfig.state.characterFillColor}
          onImageLoaded={onImageLoaded}
        />
      </Group>
    </Group>
  );
};

export default CharacterImageDefault;
