/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterRenderType,
  Position,
  TalkDirection,
  TalkRenderOptionsType,
} from "game-engine/types";
import {
  getCharacterDimensions,
  getImageOffset,
  getRandomIndex,
  getRandomTrue,
} from "game-engine/utils";
import { useEffect, useRef, useState } from "react";

import GAME_CONFIG from "game-files/gameConfig";
import { Group } from "react-konva";
import Image from "game-engine/components/basic-elements/Image";
import SpriteAnimatedSingle from "game-engine/components/basic-elements/SpriteAnimatedSingle";
import useGame from "game-engine/hooks/useGame";

const CharacterAnimationTalk = (props: {
  config: CharacterConfigType;
  characterRender: CharacterRenderType;
  position?: Position;
  renderOptions?: TalkRenderOptionsType;
  isActive?: boolean;
  onImagesLoaded: () => void;
}) => {
  const { config, isActive, characterRender, onImagesLoaded } = props;
  const { engineConfig, logger } = useGame();

  const { width, height } = getCharacterDimensions(characterRender);

  const randomizer_eyesClosed = useRef({ frameIndex: 0, random: 0 });
  const randomizer_direction = useRef({ frameIndex: 0, random: 0 });

  //
  // HANDLE IMAGE LOADING
  //
  const [imagesLoaded, setImagesLoaded] = useState({
    body: false,
    head: false,
  });

  useEffect(() => {
    if (Object.values(imagesLoaded).filter((loaded) => !loaded).length === 0) {
      if (onImagesLoaded) {
        onImagesLoaded();
      }
      logger.graphics(`${config?.id} talk images loaded`, config, imagesLoaded);
    }
  }, [imagesLoaded]);

  const onImageLoaded = (key: "body" | "head") => {
    setImagesLoaded((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [key]: true };
      return updatedLoaded;
    });
  };

  //
  // TALK SPRITES WITHIN THE IMAGE
  //
  const getSpriteFrameRenderIndex: (frameIndex: number) => number = (
    frameIndex
  ) => {
    // Randomizers (frameIndex repeats the same value due to Konva.Animation FPS -> store in refs)
    if (randomizer_eyesClosed.current.frameIndex !== frameIndex) {
      randomizer_eyesClosed.current = {
        frameIndex,
        random: getRandomTrue({ probabilityPercent: 25 }) ? 1 : 0,
      };
    }

    if (randomizer_direction.current.frameIndex !== frameIndex) {
      randomizer_direction.current = {
        frameIndex,
        random: getRandomIndex(3),
      };
    }

    // Render Params
    const eyesClosed = props.renderOptions?.eyesClosed ?? "random";

    const hasEyesClosed =
      eyesClosed === "random"
        ? randomizer_eyesClosed.current.random
        : eyesClosed;

    const direction = props.renderOptions?.direction ?? TalkDirection.random;

    let headTilt;
    switch (direction) {
      case TalkDirection.random:
        headTilt = [0, 4, 8][randomizer_direction.current.random];
        break;
      case TalkDirection.up:
        headTilt = 4;
        break;
      case TalkDirection.down:
        headTilt = 8;
        break;
      default:
        headTilt = 0;
    }

    const mouthOffset = frameIndex % 2;
    const blinkOffset = hasEyesClosed ? 2 : 0;

    return mouthOffset + blinkOffset + headTilt;
  };

  const spriteConfig = {
    frameDurationMilliseconds:
      GAME_CONFIG.character.animations.talk.frameDuration,
    ...characterRender.talk?.headSpriteConfig,
  };

  //
  // RENDER
  //
  return characterRender.talk ? (
    <Group
      opacity={isActive ? 1 : 0}
      listening={isActive}
      x={characterRender.talk.offsetX || 0}
      y={characterRender.talk.offsetY || 0}
    >
      <Group position={getImageOffset({ width, height })}>
        <Image
          src={characterRender.talk.bodyImage.src}
          srcTransitionOnChange={characterRender.talk.bodyImage?.transition}
          renderOutline={engineConfig.state.renderCharacterOutline}
          renderFill={engineConfig.state.renderCharacterFill}
          outlineColor={engineConfig.state.characterOutlineColor}
          fillColor={engineConfig.state.characterFillColor}
          onImageLoaded={() => onImageLoaded("body")}
        />
      </Group>

      <Group
        position={getImageOffset({
          width: spriteConfig.frameWidth,
          height: spriteConfig.frameHeight,
        })}
      >
        <Group
          x={characterRender.talk.headOffsetX || 0}
          y={characterRender.talk.headOffsetY || 0}
        >
          <SpriteAnimatedSingle
            src={characterRender.talk.headSprite.src}
            spriteConfig={spriteConfig}
            controlledAnimation={{ playAnimation: isActive }}
            getSpriteFrameRenderIndex={getSpriteFrameRenderIndex}
            loggerString={`talk animation, ${config.id}`}
            renderOutline={engineConfig.state.renderCharacterOutline}
            renderFill={engineConfig.state.renderCharacterFill}
            outlineColor={engineConfig.state.characterOutlineColor}
            fillColor={engineConfig.state.characterFillColor}
            onImageLoaded={() => onImageLoaded("head")}
          />
        </Group>
      </Group>
    </Group>
  ) : null;
};

export default CharacterAnimationTalk;
