/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";

import ActionsTalk from "../scene-editor-create-new/components/ActionsTalk";
import Box from "../basic-components/Box";
import Button from "../basic-components/Button";
import { CinematicId } from "game-files/ids";
import CinematicPreview from "./components/CinematicPreview";
import Code from "../basic-components/Code";
import DevSidebarLeft from "game-engine/tabs/TabDeveloper/components/SidebarLeft";
import { EditorDialogActions } from "../scene-editor-create-new";
import Sidebar from "../basic-components/Sidebar";
import { getCode_SceneTalkActions } from "game-engine/utils";
import styled from "styled-components";
import useGame from "game-engine/hooks/useGame";

const Wrapper = styled.div`
  position: relative;
  flex: 1;
  overflow: hidden;

  display: flex;
  justify-content: stretch;
  flex-wrap: wrap;
`;

const Main = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const ScenePreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #000;
`;

//
// COMPONENT
//
const CinematicEditor = (props: { cinematicId: CinematicId }) => {
  const { cinematicId } = props;
  const { gameFns } = useGame();

  useEffect(() => {
    // set gamePlay on init (needed for GUI)
    gameFns.setCurrentScene({
      cinematic: {
        id: cinematicId,
        onEnd: undefined,
      },
    });
  }, []);

  const [replay, setReplay] = useState(false);
  useEffect(() => {
    if (replay) {
      setReplay(false);
    }
  }, [replay]);

  const [dialogActions, setDialogActions] = useState<EditorDialogActions>({
    sharedDialogOptionIds: [],
    customDialogOptions: [],
  });

  //
  // RENDER
  //
  return (
    <Wrapper>
      <DevSidebarLeft width="400px" isNotBrowserHeight isOpen={false} />

      <Main>
        <ScenePreviewWrapper>
          {!replay && cinematicId && (
            <CinematicPreview cinematicId={cinematicId} />
          )}
        </ScenePreviewWrapper>
      </Main>

      <Sidebar name="Scene Editor" position="right" width="500px" isOpen>
        <Box
          label={"Cinematic Options"}
          isOpen
          childMinWidth="90%"
          contentPaddingBottom="40px"
        >
          <Button
            onClick={() => {
              setReplay(true);
            }}
          >
            {`Replay`}
          </Button>
        </Box>

        <Box
          label={"Actions Generator"}
          childMinWidth="90%"
          contentPaddingLeft="20px"
          isOpen
        >
          <ActionsTalk
            dialogActions={dialogActions}
            setDialogActions={setDialogActions}
          />

          <Code code={getCode_SceneTalkActions(dialogActions)} />

          <Button
            onClick={() => {
              navigator.clipboard.writeText(
                getCode_SceneTalkActions(dialogActions)
              );
            }}
          >
            Copy actions code
          </Button>
        </Box>
      </Sidebar>
    </Wrapper>
  );
};

export default CinematicEditor;
