import {
  AnimationColorEnum,
  BlendingMode,
  SpriteDirection,
} from "game-engine/types";

import { SceneOverlayAnimationId } from "game-files/ids";
import { createAnimationConfig } from "./src/_files";

//
// ANIMATION CONFIGS
//
const ANIMATION_CONFIGS = {
  //////////////////////////////////////////////////////////////////////

  Stars: createAnimationConfig({
    filename: "stars",
    name: "Stars",
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 30,
      frameHeight: 36,
      frameCount: 16,
      frameDurationMilliseconds: 80,
    },
    blendingMode: undefined,
    colors: {
      [AnimationColorEnum.white]: {
        colorWhite: [255, 255, 255],
        colorBlack: [150, 150, 150],
      },
      [AnimationColorEnum.red]: {
        colorWhite: [255, 200, 200],
        colorBlack: [255, 0, 0],
      },
      [AnimationColorEnum.orange]: {
        colorWhite: [255, 250, 200],
        colorBlack: [255, 150, 0],
      },
      [AnimationColorEnum.yellow]: {
        colorWhite: [255, 255, 150],
        colorBlack: [180, 180, 0],
      },
      [AnimationColorEnum.green]: {
        colorWhite: [200, 255, 200],
        colorBlack: [0, 150, 0],
      },
      [AnimationColorEnum.blue]: {
        colorWhite: [200, 200, 255],
        colorBlack: [0, 0, 255],
      },
      [AnimationColorEnum.purple]: {
        colorWhite: [230, 200, 255],
        colorBlack: [128, 0, 128],
      },
      [AnimationColorEnum.pink]: {
        colorWhite: [255, 220, 255],
        colorBlack: [250, 100, 100],
      },
      [AnimationColorEnum.cyan]: {
        colorWhite: [200, 255, 255],
        colorBlack: [0, 150, 150],
      },
    },
  }),

  //////////////////////////////////////////////////////////////////////

  Flame: createAnimationConfig({
    filename: "flame",
    name: "Flame",
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 34,
      frameHeight: 44,
      frameCount: 16,
      frameDurationMilliseconds: 50,
    },
    blendingMode: undefined,
    colors: {
      [AnimationColorEnum.white]: {
        colorWhite: [255, 255, 255],
        colorBlack: [100, 100, 100],
      },
      [AnimationColorEnum.red]: {
        colorWhite: [255, 200, 150],
        colorBlack: [150, 0, 0],
      },
      [AnimationColorEnum.orange]: {
        colorWhite: [255, 200, 120],
        colorBlack: [140, 30, 0],
      },
      [AnimationColorEnum.yellow]: {
        colorWhite: [255, 255, 100],
        colorBlack: [200, 150, 0],
      },
      [AnimationColorEnum.green]: {
        colorWhite: [200, 255, 200],
        colorBlack: [0, 150, 0],
      },
      [AnimationColorEnum.blue]: {
        colorWhite: [200, 200, 255],
        colorBlack: [0, 0, 255],
      },
      [AnimationColorEnum.purple]: {
        colorWhite: [230, 200, 255],
        colorBlack: [128, 0, 128],
      },
      [AnimationColorEnum.pink]: {
        colorWhite: [255, 220, 255],
        colorBlack: [250, 100, 100],
      },
      [AnimationColorEnum.cyan]: {
        colorWhite: [200, 255, 255],
        colorBlack: [0, 150, 150],
      },
    },
  }),

  //////////////////////////////////////////////////////////////////////

  CelestialFlame: createAnimationConfig({
    filename: "celestial-flame",
    name: "Flying Fire",
    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 62,
      frameHeight: 71,
      frameCount: 40,
      frameDurationMilliseconds: 60,
      playCount: undefined,
    },
    blendingMode: undefined, // BlendingMode.Lighten,
  }),

  //////////////////////////////////////////////////////////////////////
  //
  // SCENE OVERLAY ANIMATIONS
  //
  //////////////////////////////////////////////////////////////////////

  SceneOverlayFrost: createAnimationConfig({
    filename: "scene-overlay-frost",
    name: "Scene Overlay - Frost",
    spriteConfig: {
      direction: SpriteDirection.Vertical,
      frameWidth: 320,
      frameHeight: 160,
      frameCount: 20,
      frameDurationMilliseconds: 120,
    },
    asSceneOverlayAnimation: {
      sceneOverlayAnimationId: SceneOverlayAnimationId.frost,
      pause: { durationSec: 2 },
      fadeOut: { durationSec: 10 },
    },
    blendingMode: BlendingMode.Screen,
  }),
};

//
// EXPORT AND UTILS
//
export default ANIMATION_CONFIGS;

export const getAnimationBySceneOverlayAnimationId = (
  sceneOverlayAnimationId: SceneOverlayAnimationId
) => {
  if (!sceneOverlayAnimationId) {
    return null;
  }
  const animation = Object.values(ANIMATION_CONFIGS).find(
    (animationConfig) =>
      animationConfig?.asSceneOverlayAnimation?.sceneOverlayAnimationId ===
      sceneOverlayAnimationId
  );
  if (!animation) {
    console.error(
      `Scene overlay animation '${sceneOverlayAnimationId}' not found in ANIMATION_CONFIGS!`
    );
  }
  return animation;
};
