/* eslint-disable react-hooks/exhaustive-deps */

import { ACTION, getActionsByCurrentObjective } from "game-engine/utils";
import { Position, SceneBackgroundType, SceneType } from "game-engine/types";

import SceneBackground from "../../SceneBackground";
import useGame from "game-engine/hooks/useGame";

const useSceneBackground = (props: {
  runActions;
  scene: SceneType;
  sceneBackground: SceneBackgroundType;
  onBackgroundImageLoaded: () => void;
}) => {
  const { runActions, scene, sceneBackground } = props;
  const { gameFns } = useGame();

  const onBackgroundClick = (data: { clickedPosition?: Position }) => {
    const { clickedPosition } = data;
    const actions = getActionsByCurrentObjective({
      actionsByCurrentObjective:
        scene.images.background?.actionsByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
      itemInCursor: gameFns.getItemInCursor(),
    });

    runActions(
      [
        ACTION.face({
          facePosition: clickedPosition,
        }),
        ...actions,
      ],
      { clickedPosition, clickedDepthY: 0 }
    );
  };

  //
  // RENDER
  //
  return {
    renderSceneBackground: (renderProps?: { invisibilityRender?: boolean }) => (
      <SceneBackground
        scene={scene}
        sceneBackground={sceneBackground}
        onBackgroundClick={onBackgroundClick}
        onImageLoaded={
          renderProps?.invisibilityRender
            ? undefined
            : props.onBackgroundImageLoaded
        }
      />
    ),
  };
};

export default useSceneBackground;
