import CelestialFlame from "./CelestialFlame";
import Frost from "./Frost";
import Healing from "./Healing";
import Invisibility from "./Invisibility";
import { SkillId } from "game-files/ids";
import { SkillType } from "game-engine/types";

const SKILL_CONFIGS: { [key in SkillId]: SkillType } = {
  Healing,
  Invisibility,
  Frost,
  CelestialFlame,
};

export default SKILL_CONFIGS;

export const getSkillConfigById = (skillId: SkillId) => {
  return SKILL_CONFIGS[skillId];
};
