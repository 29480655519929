import TransitionFade, { TransitionFadeAnimation } from "./types/fade";
import TransitionPosition, {
  TransitionPositionAnimation,
} from "./types/position";
import TransitionScale, { TransitionScaleAnimation } from "./types/scale";

export enum TransitionTypeEnum {
  fade = "fade",
  fadeOut = "fadeOut",
  scaleDown = "scaleDown",
}

const Transition = (props: {
  children?: React.ReactNode;
  fade?: TransitionFadeAnimation;
  scale?: TransitionScaleAnimation;
  position?: TransitionPositionAnimation;
}) => {
  return (
    <TransitionFade animatedOpacity={props.fade ?? { from: 1 }}>
      <TransitionPosition animatedPosition={props.position}>
        <TransitionScale animatedScale={props.scale ?? { from: 1 }}>
          {props.children}
        </TransitionScale>
      </TransitionPosition>
    </TransitionFade>
  );
};

export default Transition;
