/* eslint-disable react-hooks/exhaustive-deps */

import {
  ACTION,
  getActionsByCurrentObjective,
  getSceneBackgroundByCurrentObjective,
} from "game-engine/utils";
import {
  ActionQueueType,
  CharacterWalkPath,
  Direction,
  GamePlaySceneFade,
  GamePlaySceneWalkIn,
  Position,
  SceneBackgroundType,
  SceneLayerType,
  SceneType,
  SceneWalkPathsType,
} from "game-engine/types";
import { useEffect, useMemo, useRef, useState } from "react";
import useSceneActions, {
  ActionQueueMetaData,
  StopActionsType,
} from "./logic/useSceneActions";

import CelestialFlameEffect from "./skill-effects/celestial-flame";
import { CharacterId } from "game-files/ids";
import GAME_CONFIG from "game-files/gameConfig";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import { Group } from "react-konva";
import InvisibilityEffect from "./skill-effects/invisibility";
import Konva from "konva";
import SceneEffectFade from "game-engine/components/game-effects/scene-effects/scene-effect-fade";
import SceneFill from "../SceneFill";
import SceneOverlayAnimations from "../SceneOverlayAnimations";
import SceneWalkOutAreas from "../SceneWalkOutAreas";
import Transition from "game-engine/components/basic-elements/Transition";
import { TransitionFadeAnimation } from "game-engine/components/basic-elements/Transition/types/fade";
import { getSceneWalkPathsByCurrentObjective } from "game-engine/utils/get-by-current-objective/get-scene-walk-paths";
import useDebounce from "hooks/useDebounce";
import useDepthMap from "../SceneBackground/depth/useDepthMap";
import useDropMap from "../SceneBackground/drop/useDropMap";
import useGame from "game-engine/hooks/useGame";
import useMainCharacter from "./layers/useMainCharacter";
import useSceneBackground from "./layers/useSceneBackground";
import useSceneCharacters from "./layers/useSceneCharacters";
import useSceneIdle from "./logic/useSceneIdle";
import useSceneLayers from "./layers/useSceneLayers";
import useSceneSkills from "./layers/useSceneSkills";
import useWalkMap from "../SceneBackground/walk/useWalkMap";

const Scene = (props: {
  noAutosave?: boolean;
  scene: SceneType;
  isCinematic?: boolean;
  walkIn?: GamePlaySceneWalkIn;
  fadeIn?: GamePlaySceneFade;
  ignoreIdle?: boolean;
  runActionsRef?: {
    current: (
      actionOrArray: ActionQueueType,
      actionQueueMetaData?: ActionQueueMetaData
    ) => void;
  };
  stopActionsRef?: { current: StopActionsType };
  isVisible?: boolean;
  setGuiBlocker: (data: GUIBlockerProps) => void;
  onGuiBlockerClickRef: any;
  setForceGuiBlockerRef: { current: (b: boolean) => void };
  onSceneLoaded?: () => void;
  animateLoadTransition?: boolean;

  forceWalkIn?: boolean;
  forceWalkOut?: { walkTo: Position; direction: Direction };
}) => {
  const {
    scene,
    noAutosave,
    isCinematic,
    fadeIn,
    walkIn,
    ignoreIdle,
    forceWalkIn,
    forceWalkOut,
    isVisible,
    setGuiBlocker,
    onGuiBlockerClickRef,
    setForceGuiBlockerRef,
    animateLoadTransition,
  } = props;

  const { gameItems, gamePlay, sceneOffset, gameFns, logger } = useGame();

  const { sceneNeighbors } = props.scene || {};

  const sceneRef = useRef<Konva.Group>();

  const sceneBackground = useMemo<SceneBackgroundType>(() => {
    const background = getSceneBackgroundByCurrentObjective({
      dataByCurrentObjective: scene?.images?.background?.dataByCurrentObjective,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });

    return background;
  }, [scene, gamePlay.state.currentObjective, gamePlay.state.events]);

  const sceneWalkPaths = useMemo<SceneWalkPathsType>(() => {
    return getSceneWalkPathsByCurrentObjective({
      dataByCurrentObjective: scene?.sceneWalkPaths,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
  }, [scene, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // SCENE LOADER
  //
  const [sceneLoaded, setSceneLoaded] = useState(false);

  const [fade, setFade] = useState<TransitionFadeAnimation>({
    from: animateLoadTransition ? 1 : 0,
  });

  useEffect(() => {
    if (sceneLoaded) {
      if (animateLoadTransition) {
        setFade({
          from: 1,
          to: 0,
          durationSec: GAME_CONFIG.scene.initFadeInDurationSec,
        });
      }

      logger.info(`scene loaded (${scene.uniqueId})`, scene);
      if (props.onSceneLoaded) {
        props.onSceneLoaded();
      }
    }
  }, [sceneLoaded]);

  //
  // IMAGE LOADER
  //
  const initImageLoader: { [key: string]: boolean } = {
    background: false,
    walkMap: false,
    dropMap: false,
    depthMap: false,
    sceneCharacters: false,
  };
  scene?.images?.layers?.forEach((imageLayer) => {
    initImageLoader[imageLayer.id] = false;
  });

  const [imageLoader, setImageLoader] = useState(initImageLoader);

  const imageLoaded = (id) => {
    setImageLoader((prevImageLoader) => {
      const updatedLoaded = { ...prevImageLoader, [id]: true };
      return updatedLoaded;
    });
  };

  useEffect(() => {
    const waitToLoadCount = Object.values(imageLoader).filter((v) => !v).length;
    if (!waitToLoadCount) {
      logger.info(`scene images loaded (${scene.uniqueId})`, imageLoader);
      setSceneLoaded(true);
    }
  }, [imageLoader]);

  const onBackgroundImageLoaded = () => {
    logger.info(`background image loaded`);
    imageLoaded("background");
  };

  const onSceneLayerImageLoaded = (sceneLayer: SceneLayerType) => {
    logger.info(`scene layer image loaded (${sceneLayer.id})`);
    imageLoaded(sceneLayer.id);
  };

  const onWalkMapImageLoaded = () => {
    logger.info("walk map image loaded");
    imageLoaded("walkMap");
  };

  const onDropMapImageLoaded = () => {
    logger.info("drop map image loaded");
    imageLoaded("dropMap");
  };

  const onDepthMapImageLoaded = () => {
    logger.info("depth map image loaded");
    imageLoaded("depthMap");
  };

  const onSceneCharactersImagesLoaded = () => {
    logger.info("scene characters images loaded");
    imageLoaded("sceneCharacters");
  };

  //
  // LOGIC
  //
  const [walkMapLoaded, setWalkMapLoaded] = useState(false);
  useEffect(() => {
    if (walkMapLoaded) {
      onWalkMapImageLoaded();
    }
  }, [walkMapLoaded]);

  const onWalkMapClick = (data: {
    position?: Position;
    walkPath: CharacterWalkPath;
  }) => {
    if (data.walkPath?.length) {
      runActions(
        [
          ACTION.walk({
            characterId: mainCharacter.config.id,
            walkPath: data.walkPath,
          }),
        ],
        { clickedPosition: data.position }
      );
    }
  };

  //
  // SETUPS
  //
  const { mainCharacter, setMainCharacter, mainCharacterRender } =
    useMainCharacter({
      mainCharacterOptions: scene.mainCharacterOptions,
      sceneWalkPaths,
      isCinematic,
    });

  const { sceneCharacters, getSceneCharacter, setSceneCharacter } =
    useSceneCharacters({ scene });

  const { renderDropMap, dropItemInScene } = useDropMap({
    scene,
    sceneBackground,
    sceneCharacters,
    mainCharacter,
    mainCharacterRender,
    onDropMapImageLoaded,
  });

  const { renderDepthMap, getPixelDepth } = useDepthMap({
    scene,
    sceneBackground,
    onDepthMapImageLoaded,
  });

  const { renderWalkMap, renderWalkPath, getWalkPath } = useWalkMap({
    scene,
    sceneBackground,
    mainCharacter,
    mainCharacterRender,
    onWalkMapClick,
    getPixelDepth,
    setWalkMapLoaded,
  });

  const { runActions, stopActions, actionQueue } = useSceneActions({
    scene,
    sceneRef,
    sceneWalkPaths,
    isVisible,
    mainCharacter,
    setMainCharacter,
    getSceneCharacter,
    setSceneCharacter,
    getWalkPath,
    getPixelDepth,
    setGuiBlocker,
    onGuiBlockerClickRef,
  });

  if (props.runActionsRef) {
    props.runActionsRef.current = runActions;
  }
  if (props.stopActionsRef) {
    props.stopActionsRef.current = stopActions;
  }

  const { renderSceneBackground } = useSceneBackground({
    runActions,
    scene,
    sceneBackground,
    onBackgroundImageLoaded,
  });

  const { renderSceneLayers } = useSceneLayers({
    scene,
    sceneBackground,
    runActions,
    stopActions,
    mainCharacter,
    setMainCharacter,
    sceneCharacters,
    getPixelDepth,
    dropItemInScene,
    onSceneLayerImageLoaded,
    onSceneCharactersImagesLoaded,
  });

  useSceneIdle({
    scene,
    mainCharacter: mainCharacter.config,
    runActions,
    actionQueue,
    ignoreIdle,
  });

  const [sceneReadyForPlaying, setSceneReadyForPlaying] = useState(false);

  const sceneInitActionsEndedRef = useRef(false);
  const walkOutInitiatedRef = useRef(false);

  useSceneSkills({
    runActions,
    stopActions,
    sceneInitActionsEndedRef,
    walkOutInitiatedRef,
  });

  //
  // SCENE INIT
  //
  const [sceneInitialized, setSceneInitialized] = useState(false);

  useEffect(() => {
    if (forceWalkIn || (isVisible && !sceneInitialized && walkMapLoaded)) {
      // ACTIONS - fade in
      const fadeInActions = fadeIn
        ? [
            ACTION.sceneFadeIn({
              durationSec:
                typeof fadeIn !== "boolean"
                  ? fadeIn.durationSec
                  : GAME_CONFIG.cinematics.fadeSceneDurationSec,
            }),
          ]
        : [];

      // ACTIONS - walk in
      const walkInActions = !walkIn
        ? []
        : [
            ACTION.walkIntoScene({
              characterId: CharacterId.MainCharacter,
              fromDirection: walkIn.fromDirection,
            }),
          ];

      // ACTIONS - gui
      const guiActions = isCinematic
        ? []
        : [
            ACTION.execute({
              funcName: "enable GUI blocker events",
              func: () => {
                props.setForceGuiBlockerRef?.current(false);
              },
              blockGui: true,
            }),
          ];

      // ACTIONS - init (defined in scene config)
      const initActions = getActionsByCurrentObjective({
        actionsByCurrentObjective: scene.onSceneInit?.actionsByCurrentObjective,
        currentObjective: gameFns.getCurrentObjective(),
        itemInCursor: gameFns.getItemInCursor(),
      });

      // ACTIONS - skills to stop/reset on scene change count reached
      const skillActions = [];
      if (!isCinematic) {
        const { skillIdsToStop, skillIdsToReset } =
          gameFns.incrementSkillSceneChangeCount();

        skillIdsToStop.forEach((skillIdToStop) =>
          skillActions.push(ACTION.stopSkill(skillIdToStop))
        );
        skillIdsToReset.forEach((skillIdsToReset) =>
          skillActions.push(ACTION.resetSkill(skillIdsToReset))
        );
      }

      // Run actions on init
      setSceneInitialized(true);
      runActions([
        ...fadeInActions,
        ...walkInActions,
        ...guiActions,
        ...initActions,
        ...skillActions,
        ACTION.execute({
          funcName: "scene init actions ended",
          func: () => {
            sceneInitActionsEndedRef.current = true;
            setSceneReadyForPlaying(true);
          },
        }),
      ]);
    }
  }, [walkMapLoaded, isVisible]);

  useEffect(() => {
    // THIS IS USED FOR SCENE EDITOR TO PREVIEW WALK-IN ANIMATION
    if (forceWalkIn) {
      const walkInActions = !walkIn
        ? []
        : [
            ACTION.walkIntoScene({
              characterId: CharacterId.MainCharacter,
              fromDirection: walkIn.fromDirection,
            }),
          ];

      runActions([...walkInActions]);
    }
  }, [forceWalkIn]);

  //
  // AUTOSAVE GAME
  //
  const debouncedAutosave = useDebounce(gameFns.autosaveGame, 500); // delay in milliseconds

  useEffect(() => {
    if (!noAutosave) {
      debouncedAutosave();
    }
  }, [
    noAutosave,
    gamePlay.state.currentScene.uniqueSceneId,
    gamePlay.state.currentObjective,
    gamePlay.state.events,
    gameItems.state.items,
    mainCharacter.position,
    mainCharacter.facing,
    mainCharacter.scale,
  ]);

  //
  // HANDLE HIDE GUI
  //
  useEffect(() => {
    if (isVisible && !isCinematic) {
      gameFns.setHideGui({ isHidden: false });
    }
  }, [isVisible, isCinematic]);

  //
  // RENDER SCENE FUNC
  //
  const renderScene = (renderProps?: {
    mainCharacterRender?: boolean;
    invisibilityBackground?: boolean;
    invisibilityForeground?: boolean;
  }) => {
    const isRegularSceneRender =
      !renderProps?.mainCharacterRender &&
      !renderProps?.invisibilityBackground &&
      !renderProps?.invisibilityForeground;

    return (
      <Group
        clipX={0}
        clipY={0}
        clipWidth={GAME_CONFIG.scene.dimensions.x}
        clipHeight={GAME_CONFIG.scene.dimensions.y}
        listening={
          !(
            renderProps?.invisibilityBackground ||
            renderProps?.invisibilityForeground ||
            renderProps?.mainCharacterRender
          )
        }
      >
        {isRegularSceneRender ? <SceneFill color="black" /> : null}

        {renderProps?.mainCharacterRender ||
        renderProps?.invisibilityForeground ? null : (
          <>
            {renderSceneBackground({
              invisibilityRender: renderProps?.invisibilityBackground,
            })}
          </>
        )}

        {isRegularSceneRender ? (
          <>
            {renderDepthMap()}
            {renderWalkMap()}
            {renderWalkPath()}
            {renderDropMap()}
          </>
        ) : null}

        {renderSceneLayers(renderProps)}
      </Group>
    );
  };

  const renderInvisibilityBackground = () => {
    return renderScene({ invisibilityBackground: true });
  };
  const renderInvisibilityForeground = () => {
    return renderScene({ invisibilityForeground: true });
  };
  const renderMainCharacterOnly = () => {
    return renderScene({ mainCharacterRender: true });
  };

  //
  // RENDER
  //
  return (
    <Group
      position={sceneOffset}
      ref={sceneRef}
      opacity={isVisible ? 1 : 0}
      listening={isVisible && sceneLoaded}
    >
      {/* RENDER SCENE */}
      <Group>{renderScene()}</Group>

      {/* SKILL EFFECT - INVISIBILITY DISTORTION EFFECT - RENDERED ON TOP OF THE COMPLETE SCENE */}
      <InvisibilityEffect
        renderInvisibilityBackground={renderInvisibilityBackground}
        renderInvisibilityForeground={renderInvisibilityForeground}
        renderMainCharacterOnly={renderMainCharacterOnly}
        mainCharacter={mainCharacter}
        isSceneVisible={isVisible}
        sceneReadyForPlaying={sceneReadyForPlaying}
        sceneOffset={sceneOffset}
        setForceGuiBlockerRef={setForceGuiBlockerRef}
      />

      {/* SKILL EFFECT - CELESTIAL FLAME */}
      <CelestialFlameEffect />

      {/* SCENE OVERLAY ANIMATIONS (E.G. FROST EFFECT) */}
      <SceneOverlayAnimations />

      {/* DO NOT CLIP - WALK OUT AREAS SHOULD BE AVAILABLE EVEN OUTSIDE THE GAME */}
      {!isCinematic ? (
        <SceneWalkOutAreas
          sceneWalkPaths={sceneWalkPaths}
          sceneNeighbors={sceneNeighbors}
          runActions={runActions}
          walkOutInitiatedRef={walkOutInitiatedRef}
          setForceGuiBlockerRef={setForceGuiBlockerRef}
          forceWalkOut={forceWalkOut}
        />
      ) : null}

      {/* DO NOT SHOW SCENE UNTIL IT IS FULLY RENDERED */}
      <Transition fade={fade}>
        <SceneFill color={GAME_CONFIG.cinematics.fadeFillColor} />
      </Transition>

      {/* SCENE EFFECTS */}
      <SceneEffectFade sceneLoaded={sceneLoaded} isActiveOnInit={!!fadeIn} />
    </Group>
  );
};

export default Scene;
