/* eslint-disable react-hooks/exhaustive-deps */

import { Group, Rect } from "react-konva";
import { Position, TextAlign } from "game-engine/types";

import CursorGlyph from "./cursor-glyph";
import CursorGlyphs from "game-engine/glyphs/Cursor";
import GAME_CONFIG from "game-files/gameConfig";
import ITEM_CONFIGS from "game-files/items/ITEM_CONFIGS";
import Item from "../Item";
import { PixelMatrixType } from "game-engine/utils";
import Pixels from "game-engine/components/basic-elements/Pixels";
import Text from "game-engine/components/basic-elements/Text";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

export enum DevCursorType {
  LineHorizontal = "line-horizontal",
  LineVertical = "line-vertical",
  Cross = "cross",
  Coordinates = "coordinates",
}
const Cursor = (props: { position: Position; devCursor?: DevCursorType }) => {
  const { engineConfig, sceneOffset, cursor } = useGame();

  const itemInCursor = cursor.state.itemInCursor;

  const isHidden = cursor.state.isHidden;
  const opacity = isHidden
    ? 0
    : engineConfig.state.renderBrowserCursor
    ? 0.5
    : 1;

  //
  // POSITION
  //
  const position = {
    x: Math.round(props.position?.x || 0),
    y: Math.round(props.position?.y || 0),
  };

  //
  // SHAPE
  //
  const glyphs = CursorGlyphs;

  const coordinatesCursor: PixelMatrixType = useMemo(
    () => [
      [1, 1, 1],
      [1, 0, 1],
      [1, 1, 1],
    ],
    []
  );

  const getLine = (direction: "horizontal" | "vertical") => {
    const { x: sceneWidth, y: sceneHeight } = GAME_CONFIG.scene.dimensions;
    const lineWidth = 1;
    const color = "#f008";

    return direction === "horizontal" ? (
      <Rect
        width={sceneWidth * 3}
        height={lineWidth}
        x={-sceneWidth}
        fill={color}
      />
    ) : (
      <Rect
        width={lineWidth}
        height={sceneHeight * 3}
        y={-sceneHeight}
        fill={color}
      />
    );
  };

  const getDevCursor = () => {
    if (props.devCursor === DevCursorType.LineHorizontal) {
      return getLine("horizontal");
    }

    if (props.devCursor === DevCursorType.LineVertical) {
      return getLine("vertical");
    }

    if (props.devCursor === DevCursorType.Cross) {
      return (
        <Group>
          {getLine("horizontal")}
          {getLine("vertical")}
        </Group>
      );
    }

    // coordinates
    return (
      <Group>
        <Pixels
          position={{
            x: -2,
            y: -2,
          }}
          matrix={coordinatesCursor}
          opacity={opacity}
          outlined
        />
        <Text
          position={{ x: 0, y: 3 }}
          text={`${position.x - sceneOffset.x}x${position.y - sceneOffset.y}`}
          color={{ r: 255, g: 255, b: 255, a: 255 }}
          align={TextAlign.center}
        />
      </Group>
    );
  };

  //
  // RENDER
  //
  return (
    <Group position={position} listening={false}>
      {engineConfig.state.renderSceneCoordinatesCursor || props.devCursor ? (
        <>
          {/* DEV CURSOR */}
          {getDevCursor()}
        </>
      ) : (
        <>
          {/* GAME CURSORS */}
          <Group opacity={opacity}>
            <Group opacity={itemInCursor ? 0 : 1}>
              {/* DEFAULT CURSOR SHAPES */}
              {Object.entries(glyphs).map(([glyphName, glyphData]) => (
                <CursorGlyph
                  key={glyphName}
                  opacity={glyphName === cursor.state.renderMode ? 1 : 0}
                  cursorGlyph={glyphData}
                />
              ))}
            </Group>

            {/* GRABBED ITEM */}
            {Object.values(ITEM_CONFIGS).map((itemConfig) => (
              <Group
                key={itemConfig.configId}
                opacity={itemInCursor?.configId === itemConfig.configId ? 1 : 0}
              >
                <Item
                  item={{
                    id: itemInCursor?.id,
                    configId: itemConfig.configId,
                    inCursor: true,
                  }}
                  position={{ x: 0, y: -1 }}
                  centeredOnOrigin
                />
              </Group>
            ))}
          </Group>
        </>
      )}
    </Group>
  );
};

export default Cursor;
