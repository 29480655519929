import { EditorType, SCENES_LAYOUT_DEFAULT } from "../..";
import {
  LayoutSceneType,
  SceneType,
  ScenesLayoutsDefType,
} from "game-engine/types";
import {
  capitalizeFirstLetter,
  getScenesLayoutBySceneId,
} from "game-engine/utils";

import Box from "game-engine/_dev/basic-components/Box";
import BoxWithThumbnail from "game-engine/_dev/basic-components/Box/BoxWithThumbnail";
import Button from "game-engine/_dev/basic-components/Button";
import Checkbox from "game-engine/_dev/basic-components/inputs/Checkbox";
import InfoTable from "game-engine/_dev/basic-components/InfoTable";
import { InputBaseError } from "game-engine/_dev/basic-components/inputs/_InputBase";
import RadioList from "game-engine/_dev/basic-components/inputs/RadioList";
import SCENE_CONFIGS from "game-files/scenes/SCENE_CONFIGS";
import SceneInfo from "game-engine/_dev/dev-components/SceneInfo";
import Sidebar from "game-engine/_dev/basic-components/Sidebar";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";
import devToolsConfig from "game-engine/configs/devtools-config";
import { getAllWalkableSceneWalkPaths } from "game-engine/utils/get-by-current-objective/get-scene-walk-paths";
import styled from "styled-components";
import { useState } from "react";

const NoData = styled.div`
  font-size: 12px;
  color: #fff8;
  background: #0004;
  flex: 1;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SearchRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 5px;
`;

const BoxUnderHeader = styled.div`
  pointer-events: none;
  margin-top: -40px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & > * {
    pointer-events: auto;
  }
`;

const ScenesLayoutButtons = styled.div`
  width: 100%;
  max-width: 200px;
  margin-left: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;

  button {
    flex: 1;
    max-width: 200px;
    z-index: 100;
  }
`;

//
// COMPONENT
//
const SidebarRight = (props: {
  editor: EditorType;
  setEditor: (e: EditorType) => void;
  updateScenesLayoutId: (options: {
    originalId: string;
    newId: string;
  }) => void;
  updateLayoutScene: (options: {
    updatedLayoutScene: LayoutSceneType;
    row: number;
    column: number;
  }) => void;
  addNewScenesLayout: (options: { newId: string }) => void;
  deleteScenesLayout: (options: { id: string }) => void;
  updateScenesLayoutsDef: (
    updatedLayoutsDef: ScenesLayoutsDefType,
    options?: { highlightedSceneLinkIds: string[] }
  ) => void;
}) => {
  const {
    editor,
    setEditor,
    updateLayoutScene,
    updateScenesLayoutId,
    addNewScenesLayout,
    updateScenesLayoutsDef,
  } = props;

  const updateActiveLayoutScene = (layoutScene: LayoutSceneType) => {
    updateLayoutScene({
      updatedLayoutScene: layoutScene,
      row: editor.activeSceneItem.row,
      column: editor.activeSceneItem.column,
    });
  };

  const defaultSearch = {
    sceneId: "",
    neighborDirections: [],
  };
  const [sceneSearch, setSceneSearch] = useState<{
    sceneId: string;
    neighborDirections: ("left" | "right" | "up" | "down")[];
  }>(defaultSearch);

  const getWalkToDirectionsList = (scene: SceneType) => {
    const directions = Object.entries(scene?.sceneWalkPaths)
      .filter(([d, value]) => value)
      .map(([d, value]) => d);
    return directions;
  };

  const onDeleteScenesLayout = () => {
    if (editor.activeSceneLayoutId) {
      const deleteId = editor.activeSceneLayoutId;
      props.deleteScenesLayout({ id: deleteId });
    }
  };

  //
  // SCENES LAYOUT ID
  //
  const [scenesLayoutIdErrors, setScenesLayoutIdErrors] = useState<
    InputBaseError[]
  >([]);

  const changeScenesLayoutId = (newId) => {
    const scenesLayoutIds = editor.gameScenesData.scenesLayouts.map(
      (l) => l.id
    );
    if (
      newId !== editor.activeSceneLayoutId &&
      scenesLayoutIds.includes(newId)
    ) {
      setScenesLayoutIdErrors([{ message: "ID already in use" }]);
    } else {
      updateScenesLayoutId({
        originalId: editor.activeSceneLayoutId,
        newId,
      });
    }
  };

  //
  // SCENE BOX
  //
  const renderSceneDefBox = (options: {
    scene: SceneType;
    label?: string;
    isOpen?: boolean;
  }) => {
    const { scene, isOpen } = options;

    if (!scene) {
      return null;
    }

    const isActive =
      editor.activeSceneItem?.layoutScene?.scene?.configId === scene.configId;

    const sceneWalkPaths = getAllWalkableSceneWalkPaths({
      dataByCurrentObjective: scene?.sceneWalkPaths,
    });

    const renderComponentUnderHeader = () => {
      const overrideSceneId =
        editor.activeSceneItem?.layoutScene?.overrideSceneId;

      // keep settings (if available in the new scene)
      const noWalkLeft = sceneWalkPaths.left
        ? editor.activeSceneItem?.layoutScene?.noWalkLeft
        : undefined;
      const noWalkRight = sceneWalkPaths.right
        ? editor.activeSceneItem?.layoutScene?.noWalkRight
        : undefined;
      const noWalkUp = sceneWalkPaths.up
        ? editor.activeSceneItem?.layoutScene?.noWalkUp
        : undefined;
      const noWalkDown = sceneWalkPaths.down
        ? editor.activeSceneItem?.layoutScene?.noWalkDown
        : undefined;

      return (
        <BoxUnderHeader>
          <Button
            isActive={isActive}
            onClick={() =>
              updateActiveLayoutScene(
                isActive
                  ? undefined
                  : {
                      scene,
                      overrideSceneId,
                      noWalkLeft,
                      noWalkRight,
                      noWalkUp,
                      noWalkDown,
                    }
              )
            }
          >
            {isActive ? "Unassign" : "Assign Scene"}
          </Button>
        </BoxUnderHeader>
      );
    };

    return (
      <BoxWithThumbnail
        label={options.label || scene.uniqueId}
        level={2}
        useFlex
        childMinWidth="60%"
        imageSrc={scene.preview.src}
        largeImageWhenOpen
        isOpen={isOpen}
        backgroundColor={isActive ? "#ffffff0A" : undefined}
        renderComponentUnderHeader={renderComponentUnderHeader}
      >
        <SceneInfo scene={scene} isOpen />
      </BoxWithThumbnail>
    );
  };

  //
  // SCENE LIST
  //
  const renderSceneDefList = () => {
    let scenesDefs = Object.values(SCENE_CONFIGS).filter((s) => !s.isDev);

    if (sceneSearch?.sceneId?.length) {
      const search = sceneSearch.sceneId.toLowerCase();
      scenesDefs = scenesDefs.filter((scene) =>
        scene.uniqueId?.toLowerCase().includes(search)
      );
    }

    if (sceneSearch?.neighborDirections?.length) {
      scenesDefs = scenesDefs.filter((scene) => {
        const neighborDirections = getWalkToDirectionsList(scene);

        // go through all search directions and check that all are included
        for (const d of sceneSearch.neighborDirections) {
          if (!neighborDirections.includes(d)) {
            return false;
          }
        }

        return true;
      });
    }

    if (!scenesDefs?.length) {
      return <NoData>{"No Scenes"}</NoData>;
    }

    return (
      <>
        {scenesDefs.map((scene, i) => (
          <div key={`${scene.uniqueId}_${i}`}>
            {renderSceneDefBox({ scene, label: scene.configId, isOpen: false })}
          </div>
        ))}
      </>
    );
  };

  //
  // RENDER
  //
  return (
    <Sidebar name="Settings" position="right" width="min(100%, 500px)" isOpen>
      <Box label="Scenes Layout" level={1} isOpen childMinWidth="60%">
        <RadioList
          selectedId={editor.activeSceneLayoutId || SCENES_LAYOUT_DEFAULT}
          items={[
            {
              id: SCENES_LAYOUT_DEFAULT,
              data: undefined,
              label: "All",
            },
            ...editor.gameScenesData.scenesLayoutsDef.scenesLayouts.map(
              (scenesLayout) => {
                return {
                  id: scenesLayout.id,
                  data: scenesLayout,
                  label: scenesLayout.id,
                };
              }
            ),
          ]}
          onChange={(selectedId) =>
            setEditor({
              ...editor,
              activeSceneLayoutId: selectedId,
              activeSceneItem: undefined,
            })
          }
          onSetDefault={() =>
            setEditor({
              ...editor,
              activeSceneLayoutId: undefined,
              activeSceneItem: undefined,
            })
          }
        />

        <ScenesLayoutButtons>
          <Button onClick={onDeleteScenesLayout}>{"Delete"}</Button>
          <Button
            onClick={() =>
              addNewScenesLayout({
                newId: `Scene_Layout_${Math.floor(
                  100000 + Math.random() * 900000
                )}`,
              })
            }
          >
            {"Add"}
          </Button>
        </ScenesLayoutButtons>

        {!editor.activeSceneLayoutId ||
        editor.activeSceneLayoutId === SCENES_LAYOUT_DEFAULT ? (
          <Box
            label="Scene Links"
            level={2}
            childMinWidth="60%"
            contentPaddingTop="10px"
            isOpen
          >
            {editor.gameScenesData.scenesLayoutsDef.sceneLinks.map(
              (sceneLink, i) => {
                const scene1ScenesLayout = getScenesLayoutBySceneId({
                  sceneId: sceneLink.scene1.uniqueId,
                  scenesLayouts: editor.gameScenesData.scenesLayouts,
                });
                const scene2ScenesLayout = getScenesLayoutBySceneId({
                  sceneId: sceneLink.scene2.uniqueId,
                  scenesLayouts: editor.gameScenesData.scenesLayouts,
                });

                const isHighlighted = editor.highlightedSceneLinkIds.includes(
                  sceneLink.sceneLinkId
                );

                return (
                  <Box
                    key={sceneLink.sceneLinkId}
                    label={`Scene Link (${sceneLink.sceneLinkId})`}
                    level={2}
                    childMinWidth="60%"
                    contentPaddingTop="10px"
                    isOpen={isHighlighted}
                    onOpenChange={(isOpen) => {
                      if (isOpen) {
                        // set link as highlighted
                        setEditor({
                          ...editor,
                          highlightedSceneLinkIds: [sceneLink.sceneLinkId],
                        });
                      } else {
                        // set link as not highlighted
                        setEditor({
                          ...editor,
                          highlightedSceneLinkIds:
                            editor.highlightedSceneLinkIds.filter(
                              (id) => id !== sceneLink.sceneLinkId
                            ),
                        });
                      }
                    }}
                    backgroundColor={isHighlighted ? "#6bff6b20" : undefined}
                  >
                    <Box
                      label="Scene 1"
                      level={2}
                      childMinWidth="60%"
                      contentPaddingTop="10px"
                      isOpen
                      backgroundColor={devToolsConfig.background}
                    >
                      <InfoTable
                        data={[
                          {
                            label: "Id",
                            value: sceneLink.scene1.uniqueId,
                          },
                          {
                            label: "Direction",
                            value: sceneLink.scene1.direction,
                          },
                          {
                            label: "Layout",
                            value: scene1ScenesLayout?.id,
                          },
                        ]}
                      />
                    </Box>

                    <Box
                      label="Scene 2"
                      level={2}
                      childMinWidth="60%"
                      contentPaddingTop="10px"
                      isOpen
                      backgroundColor={devToolsConfig.background}
                    >
                      <InfoTable
                        data={[
                          {
                            label: "Id",
                            value: sceneLink.scene2.uniqueId,
                          },
                          {
                            label: "Direction",
                            value: sceneLink.scene2.direction,
                          },
                          {
                            label: "Layout",
                            value: scene2ScenesLayout?.id,
                          },
                        ]}
                      />
                    </Box>

                    <ScenesLayoutButtons>
                      <Button
                        onClick={() => {
                          updateScenesLayoutsDef(
                            {
                              ...editor.gameScenesData.scenesLayoutsDef,
                              sceneLinks:
                                editor.gameScenesData.scenesLayoutsDef.sceneLinks.filter(
                                  (l) => l.sceneLinkId !== sceneLink.sceneLinkId
                                ),
                            },
                            {
                              highlightedSceneLinkIds: [],
                            }
                          );
                        }}
                        backgroundColor={devToolsConfig.background}
                        noBorder
                      >
                        {"Delete"}
                      </Button>
                    </ScenesLayoutButtons>
                  </Box>
                );
              }
            )}
          </Box>
        ) : null}

        {editor.activeSceneLayoutId &&
        editor.activeSceneLayoutId !== SCENES_LAYOUT_DEFAULT ? (
          <Box
            label="Selected Scenes Layout"
            level={2}
            isOpen
            childMinWidth="60%"
            contentPaddingTop="10px"
          >
            <TextInput
              value={editor.activeSceneLayoutId || ""}
              onChange={changeScenesLayoutId}
              submitButton={{ label: "Save" }}
              errors={scenesLayoutIdErrors}
              setErrors={setScenesLayoutIdErrors}
            />
          </Box>
        ) : null}
      </Box>

      {editor.activeSceneLayoutId &&
      editor.activeSceneLayoutId !== SCENES_LAYOUT_DEFAULT ? (
        !editor.activeSceneItem ? (
          <Box label="Scene" level={1} isOpen childMinWidth="60%">
            <NoData>{"◄ Select Item in Scene Layout"}</NoData>
          </Box>
        ) : (
          <>
            <Box label="Scene" level={1} isOpen childMinWidth="60%">
              {!editor.activeSceneItem?.layoutScene?.scene ? (
                <>
                  <NoData>{"No Assigned Scene"}</NoData>
                </>
              ) : (
                <>
                  {renderSceneDefBox({
                    scene: editor.activeSceneItem?.layoutScene?.scene,
                    label: editor.activeSceneItem?.layoutScene?.overrideSceneId,
                    isOpen: false,
                  })}

                  <Box
                    label="Scene Settings"
                    level={2}
                    isOpen
                    childMinWidth="60%"
                  >
                    {/* TODO */}
                    {` TODO: Override ID MUST CHECK SCENE LINKS AND UPDATE THEM AS WELL!`}
                    <TextInput
                      prefix="Override ID"
                      prefixFontSize="12px"
                      value={
                        editor.activeSceneItem?.layoutScene?.overrideSceneId ||
                        ""
                      }
                      onChange={(v) =>
                        updateActiveLayoutScene({
                          ...editor.activeSceneItem?.layoutScene,
                          overrideSceneId:
                            v.trim().length === 0 ? undefined : v.trim(),
                        })
                      }
                    />
                    {["left", "right", "up", "down"].map((d) => {
                      const param = `noWalk${capitalizeFirstLetter(d)}`;
                      return (
                        <Checkbox
                          key={d}
                          label={`Disable Walk ${capitalizeFirstLetter(d)}`}
                          value={editor.activeSceneItem?.layoutScene[param]}
                          onChange={() => {
                            const updatedLayoutScene = {
                              ...editor.activeSceneItem?.layoutScene,
                            };
                            updatedLayoutScene[param] =
                              !editor.activeSceneItem?.layoutScene[param];
                            updateActiveLayoutScene(updatedLayoutScene);
                          }}
                          isDisabled={
                            !getWalkToDirectionsList(
                              editor.activeSceneItem?.layoutScene?.scene
                            ).includes(d)
                          }
                        />
                      );
                    })}
                  </Box>
                </>
              )}
            </Box>

            <Box label="Assignable Scenes" level={1} isOpen childMinWidth="60%">
              <Box label="Search Filters" level={2} isOpen childMinWidth="60%">
                <SearchRow>
                  <TextInput
                    prefix="scene id"
                    value={sceneSearch?.sceneId}
                    onChange={(v) =>
                      setSceneSearch({ ...sceneSearch, sceneId: v })
                    }
                  />

                  <Button
                    isActive={
                      !!(
                        sceneSearch?.sceneId?.length ||
                        sceneSearch.neighborDirections?.length
                      )
                    }
                    onClick={() => setSceneSearch(defaultSearch)}
                  >
                    {"Reset Filters"}
                  </Button>
                </SearchRow>

                {["left", "right", "up", "down"].map((d) => {
                  const isActive = sceneSearch.neighborDirections?.includes(
                    d as any
                  );

                  return (
                    <Checkbox
                      key={d}
                      label={`Neighbor ${capitalizeFirstLetter(d)}`}
                      value={isActive}
                      onChange={() => {
                        setSceneSearch({
                          ...sceneSearch,
                          neighborDirections: isActive
                            ? sceneSearch.neighborDirections.filter(
                                (direction) => direction !== (d as any)
                              )
                            : [...sceneSearch.neighborDirections, d as any],
                        });
                      }}
                    />
                  );
                })}
              </Box>

              {renderSceneDefList()}
            </Box>
          </>
        )
      ) : null}
    </Sidebar>
  );
};

export default SidebarRight;
