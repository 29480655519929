/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterRenderMode,
  Position,
  SceneCharacterDataType,
  SpriteConfigType,
  SpriteDirection,
} from "game-engine/types";
import { Group, Image as KonvaImage } from "react-konva";
import { useCallback, useEffect, useRef, useState } from "react";

import DisplacementMap from "game-files/skills/Invisibility/assets/invisibility-displacement-map.png";
import GAME_CONFIG from "game-files/gameConfig";
import { SkillId } from "game-files/ids";
import { TabType } from "game-engine/tabs";
import TransitionSprite from "game-files/skills/Invisibility/assets/invisibility-transition-alpha-sprite.png";
import useDebounce from "hooks/useDebounce";
import useGame from "game-engine/hooks/useGame";

const DistortionEffect = (props: {
  renderInvisibilityBackground: () => any;
  renderInvisibilityForeground: () => any;
  renderMainCharacterOnly: () => any;
  mainCharacter: SceneCharacterDataType;
  isSceneVisible: boolean;
  sceneReadyForPlaying: boolean;
  sceneOffset: Position;
  isEnding: boolean;
  onEndTransitionFinished: () => void;
}) => {
  const {
    renderInvisibilityBackground,
    renderInvisibilityForeground,
    renderMainCharacterOnly,
    mainCharacter,
    isSceneVisible,
    sceneReadyForPlaying,
    sceneOffset,
    isEnding,
    onEndTransitionFinished,
  } = props;

  const { gameFns } = useGame();

  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = urlParams.get("tab");
  const isInDevTools = activeTab && activeTab !== TabType.player;

  const backgroundRef = useRef(null);
  const foregroundRef = useRef(null);
  const characterRef = useRef(null);

  const debounceDelay = 20;

  const dimensions = GAME_CONFIG.scene.dimensions;
  const offscreenOffset = { x: -dimensions.x * 2, y: 0 }; // RENDER OUTSIDE THE SCENE INTENTIONALLY TO HIDE GROUPS FOR IMAGE CAPTURE
  const captureBox = {
    x: sceneOffset.x + offscreenOffset.x,
    y: sceneOffset.y + offscreenOffset.y,
    width: dimensions.x,
    height: dimensions.y,
  };

  //
  // CAPTURE IMAGE LAYERS TO COMPOSE THE INVISIBILITY DISTORTION EFFECT
  //
  const [imageLayers, setImageLayers] = useState<{
    backgroundImage: string;
    characterImage: string;
    foregroundImage: string;
  } | null>({
    backgroundImage: undefined,
    characterImage: undefined,
    foregroundImage: undefined,
  });

  const mainCharacterIsAnimatedRef = useRef(false);
  useEffect(() => {
    mainCharacterIsAnimatedRef.current =
      mainCharacter?.renderMode === CharacterRenderMode.animation;
  }, [mainCharacter]);

  const captureImages = useCallback(() => {
    if (backgroundRef.current && characterRef.current) {
      setImageLayers({
        backgroundImage: backgroundRef.current.toDataURL(captureBox),
        characterImage: characterRef.current.toDataURL(captureBox),
        foregroundImage: foregroundRef.current.toDataURL(captureBox),
      });

      if (mainCharacterIsAnimatedRef.current) {
        // character is running an animation inside, need to repeatedly update the image
        const timeoutId = setTimeout(captureImages, debounceDelay);
        return () => clearTimeout(timeoutId);
      }
    }
  }, [backgroundRef, characterRef]);

  // Debounced captureImages to avoid lagging
  const debouncedCaptureImages = useDebounce(captureImages, debounceDelay); // delay in milliseconds

  useEffect(() => {
    debouncedCaptureImages();
  }, [isSceneVisible, sceneReadyForPlaying, mainCharacter]);

  //
  // LOAD DISPLACEMENT MAP
  //
  const [displacementMapImg, setDisplacementMapImg] = useState<any>();

  useEffect(() => {
    const displacementMapImage = new Image();
    displacementMapImage.src = DisplacementMap;
    displacementMapImage.onload = () => {
      setDisplacementMapImg(displacementMapImage);
    };
  }, []);

  //
  // RANDOMIZE DISTORTION OFFSET
  //
  const interpolate = (current, target, factor) => {
    return current + (target - current) * factor;
  };

  const distortionTimerRef = useRef<any>();
  const redrawTimerRef = useRef<any>();

  // Control update intervals for target update and redraw
  const distortionIntervalMilliseconds = 120; // Interval for updating target offsets
  const distortionRedrawMilliseconds = 50; // Interval for applying smooth interpolation

  // State containing offsets, targets, and settings for both distortions
  const [distortionData, setDistortionData] = useState({
    offsets: {
      distortion1: { x: 0, y: 0 },
      distortion2: { x: 0, y: 0 },
    },
    targets: {
      distortion1: { x: 0, y: 0 },
      distortion2: { x: 0, y: 0 },
    },
    settings: {
      distortion1: { maxOffsetX: 20, maxOffsetY: 2 },
      distortion2: { maxOffsetX: 20, maxOffsetY: 0 },
    },
  });

  // Function to generate new random targets
  const generateRandomTarget = (maxOffsetX, maxOffsetY) => {
    return {
      x: Math.round((Math.random() - 0.5) * 2 * maxOffsetX),
      y: Math.round((Math.random() - 0.5) * 2 * maxOffsetY),
    };
  };

  useEffect(() => {
    if (isSceneVisible) {
      // Clear previous intervals
      clearInterval(distortionTimerRef.current);
      clearInterval(redrawTimerRef.current);

      // Update target offsets at a fixed interval
      distortionTimerRef.current = setInterval(() => {
        setDistortionData((prev) => ({
          ...prev,
          targets: {
            distortion1: generateRandomTarget(
              prev.settings.distortion1.maxOffsetX,
              prev.settings.distortion1.maxOffsetY
            ),
            distortion2: generateRandomTarget(
              prev.settings.distortion2.maxOffsetX,
              prev.settings.distortion2.maxOffsetY
            ),
          },
        }));
      }, distortionIntervalMilliseconds);

      // Interpolate distortion offsets towards target at a different interval
      redrawTimerRef.current = setInterval(() => {
        setDistortionData((prev) => ({
          ...prev,
          offsets: {
            distortion1: {
              x: interpolate(
                prev.offsets.distortion1.x,
                prev.targets.distortion1.x,
                0.05
              ),
              y: interpolate(
                prev.offsets.distortion1.y,
                prev.targets.distortion1.y,
                0.05
              ),
            },
            distortion2: {
              x: interpolate(
                prev.offsets.distortion2.x,
                prev.targets.distortion2.x,
                0.05
              ),
              y: interpolate(
                prev.offsets.distortion2.y,
                prev.targets.distortion2.y,
                0.05
              ),
            },
          },
        }));
      }, distortionRedrawMilliseconds);

      // Cleanup intervals on unmount
      return () => {
        clearInterval(distortionTimerRef.current);
        clearInterval(redrawTimerRef.current);
      };
    }
  }, [isSceneVisible]);

  //
  // ANIMATED TRANSITION SPRITE
  //
  const [transitionSpriteImg, setTransitionSpriteImg] = useState<any>();

  useEffect(() => {
    const transitionSpriteImage = new Image();
    transitionSpriteImage.src = TransitionSprite;
    transitionSpriteImage.onload = () => {
      setTransitionSpriteImg(transitionSpriteImage);
    };
  }, []);

  const transitionSpriteConfig: SpriteConfigType = {
    direction: SpriteDirection.Vertical,
    frameWidth: 320,
    frameHeight: 160,
    frameCount: 10,
    frameDurationMilliseconds: 140,
  };

  const [transitionIndex, setTransitionIndex] = useState(-1); // -1 = fully transparent, undefined = fully visible
  const transitionIndexRef = useRef(transitionIndex);
  const transitionTimerRef = useRef<any>();

  const startingTransitionUsedRef = useRef(false);
  const endingTransitionUsedRef = useRef(false);

  useEffect(() => {
    transitionIndexRef.current = transitionIndex;

    // transition index is a number
    if (transitionIndex >= 0) {
      startingTransitionUsedRef.current = true; // helper for setting skill progress

      clearTimeout(transitionTimerRef.current);
      transitionTimerRef.current = setTimeout(() => {
        const newIndex =
          transitionIndexRef.current +
          (endingTransitionUsedRef.current ? -1 : 1);

        setTransitionIndex(
          newIndex >= transitionSpriteConfig.frameCount ? undefined : newIndex
        );
      }, transitionSpriteConfig.frameDurationMilliseconds);
    }

    // starting transition ended with fully visible effect
    if (startingTransitionUsedRef.current && transitionIndex === undefined) {
      gameFns.setSkillProgress(SkillId.Invisibility, 2); // 2 marks that transition is finished and character underneath can be hidden
    }

    // ending transition ended with fully hidden effect
    if (endingTransitionUsedRef.current && transitionIndex < 0) {
      onEndTransitionFinished();
    }

    return () => {
      clearTimeout(transitionTimerRef.current);
    };
  }, [transitionIndex]);

  //
  // ANIMATED END TRANSITION
  //
  useEffect(() => {
    if (isEnding) {
      endingTransitionUsedRef.current = true;
      setTransitionIndex(transitionSpriteConfig.frameCount - 1);
    }
  }, [isEnding]);

  //
  // COMPOSE THE FINAL IMAGE OF INVISIBILITY DISTORTION
  //
  const [finalImage, setFinalImage] = useState<string>();
  const finalImageRef = useRef(null);

  const drawDistortionImage = (
    finalCtx,
    backgroundImg,
    characterImg,
    foregroundImg
  ) => {
    if (transitionIndexRef.current === -1) {
      // the effect is fully invisible
      return;
    }

    const width = finalCtx.canvas.width;
    const height = finalCtx.canvas.height;

    // Clear the main canvas
    finalCtx.clearRect(0, 0, width, height);

    // Step 1: Draw the background image with full distortion offset
    finalCtx.drawImage(
      backgroundImg,
      distortionData.offsets.distortion1.x,
      distortionData.offsets.distortion1.y
    );

    // Step 2: Create an offscreen canvas to prepare the masked second layer
    const offscreenCanvas = document.createElement("canvas");
    offscreenCanvas.width = width;
    offscreenCanvas.height = height;
    const offscreenCtx = offscreenCanvas.getContext("2d");

    // Draw the background image with half the distortion offset
    offscreenCtx.drawImage(
      backgroundImg,
      -1 * Math.round(distortionData.offsets.distortion2.x),
      -1 * Math.round(distortionData.offsets.distortion2.y)
    );

    // Step 3: Apply the displacement map as an alpha mask
    offscreenCtx.globalCompositeOperation = "destination-in";
    offscreenCtx.drawImage(displacementMapImg, 0, 0, width, height);

    // Reset the composite operation for the offscreen context
    offscreenCtx.globalCompositeOperation = "source-over";

    // Step 4: Draw the masked image from the offscreen canvas onto the main canvas
    finalCtx.drawImage(offscreenCanvas, 0, 0);

    // Step 5: Apply character and foreground alpha masks
    finalCtx.globalCompositeOperation = "destination-in";
    finalCtx.drawImage(characterImg, 0, 0);

    finalCtx.globalCompositeOperation = "destination-out";
    finalCtx.drawImage(foregroundImg, 0, 0);

    // Render transition alpha
    if (transitionIndexRef.current !== undefined) {
      // use transition alpha only if the index is >= 0
      const transitionOffsetY =
        transitionSpriteConfig.frameHeight * transitionIndexRef.current;

      finalCtx.globalCompositeOperation = "destination-in";
      finalCtx.drawImage(transitionSpriteImg, 0, -transitionOffsetY);
    }

    // Reset composite operation to default for future drawings
    finalCtx.globalCompositeOperation = "source-over";
  };

  //
  // REDRAW IMAGE
  //
  useEffect(() => {
    if (
      imageLayers?.foregroundImage &&
      imageLayers?.backgroundImage &&
      imageLayers?.characterImage &&
      displacementMapImg &&
      transitionSpriteImg
    ) {
      const { backgroundImage, foregroundImage, characterImage } = imageLayers;

      // start transition
      if (transitionIndexRef.current === -1) {
        setTransitionIndex(0);
      }

      const finalCanvas = document.createElement("canvas");
      finalCanvas.width = captureBox.width;
      finalCanvas.height = captureBox.height;
      const finalCtx = finalCanvas.getContext("2d");

      const backgroundImg = new Image();
      backgroundImg.src = backgroundImage;

      const foregroundImg = new Image();
      foregroundImg.src = foregroundImage;

      const characterImg = new Image();
      characterImg.src = characterImage;

      Promise.all([
        new Promise((resolve) => (backgroundImg.onload = resolve)),
        new Promise((resolve) => (foregroundImg.onload = resolve)),
        new Promise((resolve) => (characterImg.onload = resolve)),
      ]).then(() => {
        drawDistortionImage(
          finalCtx,
          backgroundImg,
          characterImg,
          foregroundImg
        );

        const finalImageSrc = finalCanvas.toDataURL();

        const img = new Image();
        img.src = finalImageSrc;
        img.onload = () => {
          if (finalImageRef.current) {
            finalImageRef.current.setImage(img);
            finalImageRef.current.getLayer().batchDraw();
          }
        };

        // for gui preview
        setFinalImage(finalImageSrc);
      });
    }
  }, [
    imageLayers,
    displacementMapImg,
    transitionSpriteImg,
    distortionData.offsets,
  ]);

  //
  // PREVIEW IMAGES IN DEV GUI
  //
  const previewImageInGui = (elementId, imageSrc) => {
    const imgElement = document.getElementById(elementId) as HTMLImageElement;
    if (imgElement) {
      imgElement.src = imageSrc;
      imgElement.width = captureBox.width;
      imgElement.height = captureBox.height;
    }
  };

  const previewImagesInGui = () => {
    const { backgroundImage, foregroundImage, characterImage } = imageLayers;
    previewImageInGui("invisibility-image-preview-background", backgroundImage);
    previewImageInGui("invisibility-image-preview-foreground", foregroundImage);
    previewImageInGui("invisibility-image-preview-character", characterImage);
    previewImageInGui(
      "invisibility-image-preview-displacement",
      DisplacementMap
    );
    previewImageInGui("invisibility-image-preview-final", finalImage);
  };

  useEffect(() => {
    if (isInDevTools) {
      previewImagesInGui();
    }
  }, [imageLayers, finalImage]);

  //
  // RENDER
  //
  return (
    <Group listening={false}>
      {/* Render off-screen */}
      <Group ref={backgroundRef} x={offscreenOffset.x} y={offscreenOffset.y}>
        {renderInvisibilityBackground()}
      </Group>
      <Group ref={characterRef} x={offscreenOffset.x} y={offscreenOffset.y}>
        {renderMainCharacterOnly()}
      </Group>
      <Group ref={foregroundRef} x={offscreenOffset.x} y={offscreenOffset.y}>
        {renderInvisibilityForeground()}
      </Group>

      <KonvaImage
        ref={finalImageRef}
        image={null}
        x={0}
        y={0}
        width={captureBox.width}
        height={captureBox.height}
      />
    </Group>
  );
};

export default DistortionEffect;
