import {
  AnimationColorEnum,
  ItemDeleteAnimation,
  SceneType,
} from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP.png";
import IMAGE_Layer_01_Background from "./assets/layer - 01 - background.png";
import IMAGE_Layer_02_Chest from "./assets/layer - 02 - chest.png";
import IMAGE_Preview from "./assets/preview.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";

const DevScene04: SceneType = {
  configId: "DevScene04", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: "DevScene04", // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE NAME
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Drop items onto chests",
      cz: undefined,
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE AUDIO
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: undefined,
        volume: undefined,
        loop: undefined,
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE WALK PATHS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: undefined,
      right: undefined,
      up: undefined,
      down: undefined,
    },
  },

  images: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE BACKGROUND
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          image: { src: IMAGE_Layer_01_Background },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
        },
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-1",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: -140, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [ACTION.deleteItemInCursor()],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Instantly delete item in cursor",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-2",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: -95, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  deleteAfterSec: 1,
                  deleteItemFadeOutSec: 1,
                  deleteItemScaleOutSec: 1,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Pause cursor item on screen and then fade-out + scale-out.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-3",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: -50, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  position: { x: 111, y: 58 },
                  deleteAfterSec: 1,
                  deleteItemFadeOutSec: 1,
                  deleteAnimation: ItemDeleteAnimation.stars,
                  deleteAnimationColor: AnimationColorEnum.purple,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Pause cursor item on screen at specified position, fade out with stars.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-4",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: 0, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  deleteAnimation: ItemDeleteAnimation.stars,
                  deleteAnimationColor: AnimationColorEnum.red,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Immediately play stars animation.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-5",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: 50, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  deleteAnimation: ItemDeleteAnimation.flame,
                  deleteAnimationColor: AnimationColorEnum.orange,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Disappear with flame.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-6",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: 95, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  position: { x: 256, y: 58 },
                  deleteAfterSec: 1,
                  deleteItemFadeOutSec: 1,
                  deleteAnimation: ItemDeleteAnimation.flame,
                  deleteAnimationColor: AnimationColorEnum.cyan,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Pause fade out and disappear with flame.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "layer-7",
        depthY: 65,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Chest },
            offset: { x: 140, y: 0 },
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: {
              default: [
                ACTION.putItemInCursorToSceneOverlay({
                  deleteAfterSec: 1,
                  deleteItemScaleOutSec: 1,
                  deleteAnimation: ItemDeleteAnimation.flame,
                  deleteAnimationColor: AnimationColorEnum.purple,
                }),
              ],
            },
            actions: [
              ACTION.talk({
                dialog: {
                  en: "Pause scale out and disappear with flame.",
                  cz: undefined,
                },
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: undefined,
  onSceneInit: undefined,
  characters: undefined,
};

export default DevScene04;
