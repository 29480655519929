import { ITEM_GENDER, getItemSuffixes } from "game-files/items/_utils";

import IMG_default from "./assets/default.png";
import { ItemConfigId } from "game-files/ids";
import { ItemConfigType } from "game-engine/types/item";

const LanternEmpty: ItemConfigType = {
  configId: ItemConfigId.LanternEmpty,
  name: { en: "Lantern", cz: "Lucerna" },

  suffix: getItemSuffixes({ gender: ITEM_GENDER.feminine }),

  // TODO - SOUNDS
  /* sounds: {
    grab: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
    },
    drop: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Drop.id,
    },
  }, */

  render: {
    image: { src: IMG_default },
    width: 11,
    height: 19,
    offsetY: 5,
    noClickThroughAlpha: true,
  },
};

export default LanternEmpty;
