import styled, { css } from 'styled-components'

const Wrapper = styled.div<Props>`
  position: relative;
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.height || '20px'};
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: ${(props) => props.opacity || 1};

  &:hover {
    opacity: ${(props) => props.opacityOnHover || 1};
  }

  background: ${(props) => props.backgroundColor || 'none'};
`

const Shape = styled.div<Props>`
  position: relative;
  width: ${(props) => props.width || '20px'};
  height: ${(props) => props.height || '20px'};
  display: flex;
  align-items: center;
  justify-content: center;

  transition: all ${(props) => props.transitionSec || 0.1}s ease;

  ${(props) =>
    props.rotate &&
    css`
      transform: rotateZ(${props.rotate}deg);
    `}
`

const Line = styled.div<Props & { orientation: 'horizontal' | 'vertical' }>`
  position: absolute;

  --width: ${(props) => props.lineWidth || '2px'};
  --length: ${(props) => props.lineLength || '16px'};

  background: ${(props) => props.color || '#fff'};

  ${(props) =>
    props.orientation === 'horizontal'
      ? css`
          width: var(--length);
          height: var(--width);
          transform: translateX(calc(-1 * var(--width) / 2)),
            translateY(calc(-1 * var(--length) / 2));
        `
      : css`
          width: var(--width);
          height: var(--length);
          transform: translateY(calc(-1 * var(--width) / 2)),
            translateX(calc(-1 * var(--length) / 2));
        `}
`

type Props = {
  width?: string
  height?: string
  rotate?: number
  lineWidth?: string
  lineLength?: string
  backgroundColor?: string
  color?: string
  opacity?: number
  opacityOnHover?: number
  transitionSec?: number
}

//
// COMPONENT
//
const ShapePlus = (props: Props) => {
  //
  // RENDER
  //
  return (
    <Wrapper {...props}>
      <Shape {...props}>
        <Line {...props} orientation="horizontal" />
        <Line {...props} orientation="vertical" />
      </Shape>
    </Wrapper>
  )
}

export default ShapePlus
