/* eslint-disable react-hooks/exhaustive-deps */

import {
  PixelColorMapType,
  PixelMatrixType,
  getOutlinedMatrix,
} from "game-engine/utils";
import { useEffect, useMemo, useRef, useState } from "react";

import { Group } from "react-konva";
import Konva from "konva";
import { Position } from "game-engine/types";

const Pixels = (props: {
  matrix: PixelMatrixType;
  onClick?: (e?: any) => void;
  position?: Position;
  outlined?: boolean;
  outlineNoAllowDiagonal?: boolean;
  colorMap?: PixelColorMapType;
  opacity?: number;
}) => {
  const ref = useRef<Konva.Group>(null);
  const [cached, setIsCached] = useState(false);

  // Memoize the matrix based on the props
  const matrix = useMemo(() => {
    return props.outlined
      ? getOutlinedMatrix(props.matrix, {
          noDiagonalOutline: props.outlineNoAllowDiagonal,
        })
      : props.matrix;
  }, [props.matrix, props.outlined, props.outlineNoAllowDiagonal]);

  // Memoize image data creation
  const imgData = useMemo(() => {
    if (!matrix || !matrix.length) return null;

    const w = matrix[0].length;
    const h = matrix.length;
    const helperCanvas = document.createElement("canvas");
    const helperContext = helperCanvas.getContext("2d");

    helperCanvas.width = w;
    helperCanvas.height = h;

    const imgData = helperContext.createImageData(w, h);

    const colorMap: PixelColorMapType = props.colorMap || {
      "-1": { r: 0, g: 0, b: 0, a: 255 }, // black
      "0": { r: 0, g: 0, b: 0, a: 0 }, // transparent
      "1": { r: 255, g: 255, b: 255, a: 255 }, // white
    };

    for (let i = 0; i < imgData.data.length / 4; i++) {
      const x = i % w;
      const y = Math.floor(i / w);
      const matrixValue = matrix[y][x];

      let color = { r: 0, g: 0, b: 0, a: 0 };

      if (typeof matrixValue !== "number") {
        color = matrixValue;
      } else if (colorMap[matrixValue]) {
        color = colorMap[matrixValue];
      }

      const index = i * 4;
      imgData.data[index] = color.r;
      imgData.data[index + 1] = color.g;
      imgData.data[index + 2] = color.b;
      imgData.data[index + 3] = color.a;
    }

    helperContext.putImageData(imgData, 0, 0);
    return helperCanvas;
  }, [matrix, props.colorMap]);

  // Caching effect
  useEffect(() => {
    if (!cached && ref.current && imgData) {
      const elem = ref.current;
      const imageElem = new Konva.Image({ image: imgData });

      elem.destroyChildren();
      elem.add(imageElem);

      setIsCached(true);
    }
  }, [imgData, cached]);

  // Reset cache if matrix changes
  useEffect(() => {
    /* logger.graphics(
      "pixels matrix updated (if this event is triggered too often, consider using useMemo in matrix definition"
    ); */
    setIsCached(false);
  }, [props.matrix]);

  //
  // RENDER
  //
  return (
    <Group
      position={props.position || { x: 0, y: 0 }}
      opacity={props.opacity}
      ref={ref}
      onClick={props.onClick}
      onTap={props.onClick}
    />
  );
};

export default Pixels;
