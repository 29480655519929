/* eslint-disable react-hooks/exhaustive-deps */

import {
  BlendingMode,
  FrameSequenceValue,
  SpriteConfigType,
} from "game-engine/types";
import { getAnimationFrames, getRandomIndex } from "game-engine/utils";
import { useEffect, useRef, useState } from "react";

import { ChangeImageColorType } from "game-engine/hooks/useLocalImage";
import GAME_CONFIG from "game-files/gameConfig";
import SpriteFrame from "../SpriteFrame";
import useGame from "game-engine/hooks/useGame";

export type SpriteAnimatedSingleProps = {
  src: any;
  spriteConfig: SpriteConfigType;
  onImageLoaded?: () => void;
  onClick?: (e?: any) => void;
  onAnimationEnd?: () => void;
  controlledAnimation?: { playAnimation: boolean };
  handleAnimationFinished?: boolean;
  frameIndex?: number;

  randomFrames?: boolean;
  getSpriteFrameRenderIndex?: (frameIndex: number) => number;

  loggerString?: string;
  renderFill?: boolean;
  fillColor?: string;
  renderOutline?: boolean;
  outlineColor?: string;
  imageRef?: any;
  changeColor?: ChangeImageColorType;
  blendingMode?: BlendingMode;
};

const SpriteAnimatedSingle = (props: SpriteAnimatedSingleProps) => {
  const {
    loggerString,
    handleAnimationFinished = false,
    imageRef,
    changeColor,
  } = props;

  const { logger } = useGame();

  const spriteConfig: SpriteConfigType = {
    ...GAME_CONFIG.sprites.defaultSpriteConfig,

    ...Object.fromEntries(
      Object.entries(props.spriteConfig || {}).map(([key, value]) => [
        key,
        value ?? GAME_CONFIG.sprites.defaultSpriteConfig[key],
      ])
    ),

    frameSequence:
      props.spriteConfig?.frameSequence?.length > 0
        ? props.spriteConfig?.frameSequence
        : [FrameSequenceValue.original],
  };

  const isInfiniteLoop = spriteConfig.playCount < 0;

  const [imageLoaded, setImageLoaded] = useState(false);
  const [playAnimation, setPlayAnimation] = useState(false);

  const animationFrames = getAnimationFrames({
    frameCount: spriteConfig.frameCount,
    frameSequence: spriteConfig.frameSequence,
  });
  const totalFrameCount = animationFrames.length;
  const [animationFrame, setAnimationFrame] = useState(0);
  const [animationFinished, setAnimationFinished] = useState(false);

  //
  // CURRENT FRAME
  //
  const [currentFrameIndex, setCurrentFrameIndex] = useState(
    props.frameIndex ?? 0
  );
  const currentFrameIndexRef = useRef(currentFrameIndex);

  useEffect(() => {
    currentFrameIndexRef.current = currentFrameIndex;

    // set rendered frame (it is not linear, but picked from custom animation sequence of frames)
    let renderedFrame = 0;
    if (props.getSpriteFrameRenderIndex) {
      renderedFrame = props.getSpriteFrameRenderIndex(currentFrameIndex);
    } else if (props.randomFrames) {
      renderedFrame = getRandomIndex(spriteConfig.frameCount);
    } else {
      renderedFrame = animationFrames[currentFrameIndex];
    }
    setAnimationFrame(renderedFrame);
  }, [currentFrameIndex]);

  //
  // CURRENT REPEAT COUNT
  //
  const [currentPlayCount, setCurrentPlayCount] = useState(0);
  const currentPlayCountRef = useRef(0);

  useEffect(() => {
    currentPlayCountRef.current = currentPlayCount;
  }, [currentPlayCount]);

  //
  // UPDATE FRAME
  //
  const timerRef = useRef<NodeJS.Timeout>();

  const updateFrame = () => {
    const nextFrameIndex = (currentFrameIndexRef.current + 1) % totalFrameCount;

    // Increment play count if a full cycle is completed
    if (nextFrameIndex === 0) {
      const nextPlayCount = currentPlayCountRef.current + 1;
      setCurrentPlayCount(nextPlayCount);

      // Stop animation if play count is reached
      if (
        spriteConfig.playCount > 0 &&
        nextPlayCount >= spriteConfig.playCount
      ) {
        stopAnimation();

        return;
      }
    }

    setCurrentFrameIndex(nextFrameIndex);
  };

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    if (playAnimation) {
      timerRef.current = setInterval(
        updateFrame,
        spriteConfig.frameDurationMilliseconds
      );
    }

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [playAnimation]);

  //
  // STOP ANIMATION
  //
  const stopAnimation = () => {
    if (handleAnimationFinished) {
      setAnimationFinished(true);
    }
    setPlayAnimation(false);
    props.onAnimationEnd?.();
  };

  //
  // START ANIMATION ON IMAGE LOAD (OR CONTROLLED EXTERNALLY)
  //
  useEffect(() => {
    if (
      (!props.controlledAnimation ||
        props.controlledAnimation?.playAnimation) &&
      imageLoaded &&
      spriteConfig.frameCount > 1 &&
      (isInfiniteLoop || spriteConfig.playCount > 0) &&
      !animationFinished
    ) {
      setPlayAnimation(true);
      logger.graphics(`Animation started (${loggerString})`, props);
    } else {
      setPlayAnimation(false);
    }
  }, [imageLoaded, props.controlledAnimation?.playAnimation]);

  const handleImageLoaded = () => {
    setImageLoaded(true);
    props.onImageLoaded?.();
  };

  //
  // RENDER
  //
  return (
    <SpriteFrame
      src={props.src}
      spriteConfig={spriteConfig}
      frameIndex={animationFrame}
      renderFill={props.renderFill}
      renderOutline={props.renderOutline}
      outlineColor={props.outlineColor}
      fillColor={props.fillColor}
      onClick={props.onClick}
      onImageLoaded={handleImageLoaded}
      imageRef={imageRef}
      changeColor={changeColor}
      blendingMode={props.blendingMode}
    />
  );
};

export default SpriteAnimatedSingle;
