import CinematicEditor from "game-engine/_dev/cinematic-editor";
import { CinematicId } from "game-files/ids";
import CinematicSelection from "game-engine/_dev/cinematic-editor-selection";
import devToolsConfig from "game-engine/configs/devtools-config";
import styled from "styled-components";

const Wrapper = styled.div`
  flex: 1;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  background: ${devToolsConfig.background};
`;

//
// COMPONENT
//
const TabCinematicEditor = () => {
  const params = new URLSearchParams(window.location.search);
  const cinematicId = params.get("cinematicId");

  const onEditCinematic = (cinematicId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set("cinematicId", cinematicId);
    window.location.href = url.toString();
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      {cinematicId ? (
        <CinematicEditor cinematicId={cinematicId as CinematicId} />
      ) : (
        <CinematicSelection onEditCinematic={onEditCinematic} />
      )}
    </Wrapper>
  );
};

export default TabCinematicEditor;
