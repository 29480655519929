import { ACTION, getItemDimensions } from "game-engine/utils";

import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Button from "game-engine/_dev/basic-components/Button";
import { DevToolsContext } from "game-engine";
import ITEM_CONFIGS from "game-files/items/ITEM_CONFIGS";
import Item from "game-engine/components/game-elements/Item";
import styled from "styled-components";
import { useContext } from "react";

const ItemsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 110px);
  gap: 10px;
`;

const ItemWrapper = styled.div`
  height: 110px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
`;

const ItemPreview = styled.div`
  width: 70px;
  height: 90px;
  min-height: 90px;
  margin-top: -20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ItemLabel = styled.div`
  text-align: center;
  font-size: 10px;
  font-weight: 600;
  padding-bottom: 10px;
`;

const NoItemLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-size: 12px;
  font-weight: 600;
  padding-top: 10px;
`;

const PutItemIntoCursorList = () => {
  const devTools = useContext(DevToolsContext);
  const runActions = devTools.state.actions.runActionsRef?.current;

  const previewWidth = 40;
  const previewHeight = 40;
  const previewScale = 2;

  //
  // RENDER
  //
  return (
    <ItemsWrapper>
      <Button
        onClick={() => {
          runActions([ACTION.deleteItemInCursor({})]);
        }}
        padding="0px"
      >
        <ItemWrapper>
          <NoItemLabel>NO ITEM</NoItemLabel>
        </ItemWrapper>
      </Button>

      {Object.values(ITEM_CONFIGS).map((itemConfig) => {
        return (
          <Button
            key={itemConfig.configId}
            onClick={() => {
              runActions([
                ACTION.putNewItemIntoCursor({
                  newItemConfigId: itemConfig.configId,
                }),
              ]);
            }}
            padding="0px"
          >
            <ItemWrapper>
              <ItemPreview>
                <AssetPreview
                  width={previewWidth}
                  height={previewHeight}
                  scale={previewScale}
                  background="#0000"
                >
                  <Item
                    item={{
                      id: itemConfig.configId,
                      configId: itemConfig.configId,
                    }}
                    position={{
                      x: Math.round(previewWidth / 2),
                      y: Math.round(
                        previewHeight / 2 + getItemDimensions(itemConfig).height
                      ),
                    }}
                  />
                </AssetPreview>
              </ItemPreview>

              <ItemLabel>{itemConfig.name.en}</ItemLabel>
            </ItemWrapper>
          </Button>
        );
      })}
    </ItemsWrapper>
  );
};

export default PutItemIntoCursorList;
