import { CinematicId } from "game-files/ids";
import { CinematicType } from "game-engine/types";
import TestCinematicScene01 from "./_test/TestCinematicScene01";
import TestCinematicScene02 from "./_test/TestCinematicScene02";
import TestCinematicScreen01 from "./_test/TestCinematicScreen01";
import TestCinematicScreen02 from "./_test/TestCinematicScreen02";
import TestCinematicScreen03 from "./_test/TestCinematicScreen03";
import TestCinematicScreen04 from "./_test/TestCinematicScreen04";
import TestCinematicScreen05 from "./_test/TestCinematicScreen05";
import TestCinematicScreen06 from "./_test/TestCinematicScreen06";

const CINEMATIC_CONFIGS: { [id in CinematicId]: CinematicType } = {
  TestCinematicScene01,
  TestCinematicScene02,
  TestCinematicScreen01,
  TestCinematicScreen02,
  TestCinematicScreen03,
  TestCinematicScreen04,
  TestCinematicScreen05,
  TestCinematicScreen06,
};

export default CINEMATIC_CONFIGS;

export const getCinematicById = (cinematicId: CinematicId) => {
  // Note that the component name and configId need to be the same!
  return CINEMATIC_CONFIGS[cinematicId];
};
