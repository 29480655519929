import { SceneTag, SkillId } from "game-files/ids";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import { SkillType } from "game-engine/types";

const CelestialFlame: SkillType = {
  id: SkillId.CelestialFlame,

  name: { en: "Celestial Flame", cz: "Nebeský plamen" },

  render: undefined, // TODO

  states: {
    [GameObjective.GAME_START]: {
      state_start: {
        getActions: ({ gamePlay, gameItems }) => [
          ACTION.talk({
            dialog: { en: "Let's do this.", cz: "Jdeme na to." },
          }),

          // TODO - ADD ACTION.characterAnimation FOR THE TRANSFORMATION FROM LANTERN
        ],
      },

      state_active: {
        duration: {
          sceneChangeCount: 1, // stop after each scene change
          durationSec: undefined, // keep until scene changes
        },
      },

      state_stop: {
        ignoreSceneTags: [SceneTag.DeathByDarkness],
        getActions: ({ gamePlay, gameItems }) => [
          // TODO - ADD ACTION.characterAnimation FOR THE TRANSFORMATION TO LANTERN

          ACTION.talk({
            dialog: { en: "What a feeling!", cz: "To bylo něco!" },
          }),
        ],
      },

      state_waitForReset: {
        duration: undefined,
      },
    },
  },
};

export default CelestialFlame;
