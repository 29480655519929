import {
  CinematicScreenSize,
  CinematicScreenType,
  CinematicTypeEnum,
} from "game-engine/types";

import { CinematicId } from "game-files/ids";
import TestBackgroundSky01 from "./assets/test-background-sky-01.jpg";

const TestCinematicScreen03: CinematicScreenType = {
  id: CinematicId.TestCinematicScreen03,
  type: CinematicTypeEnum.screen,
  size: CinematicScreenSize.scene,

  fadeIn: true,
  fadeOut: true,

  background: {
    //position: { x: 5, y: 5 },
    //color: "#005",

    image: { src: TestBackgroundSky01 },

    /* spriteConfig: { // -------------------- IMAGE CAN ALSO BE A SPRITE
      direction: SpriteDirection.Vertical,
      frameWidth: 320,
      frameHeight: 250,
      frameCount: 30,
      frameDurationMilliseconds: 50,
    }, */
  },

  sequence: [
    {
      pauseUntilFinished: true,
      addLayer: {
        text: {
          en: "Example of cinematic text with image background.",
          cz: "Ukázka cinematic textu s obrázkem na pozadí.",
        },
      },
    },
  ],
};

export default TestCinematicScreen03;
