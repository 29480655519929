import { TranslatedString } from "game-engine/types";

//
// GAME OBJECTIVE IDS
//
export const GAME_START = "game_start";

export enum GameObjective {
  GAME_START = 0,
  "obj_01_find_grandma" = 1,
  "obj_02_find_lantern" = 2,
  "obj_03_get_floating_fire" = 3,
  "obj_04_talk_to_grandma" = 4,
  "obj_05_travel_down" = 5,
}

//
// GAME OBJECTIVE DESCRIPTIONS
//
export type GameObjectiveDescriptionType = {
  name: TranslatedString;
  description: TranslatedString[];
};

// TODO - THESE WILL BE USED FOR SIDEBAR TUTORIAL FOR THE GAME
export const GAME_OBJECTIVES_DEF: {
  [key in GameObjective]: GameObjectiveDescriptionType;
} = {
  [GameObjective.GAME_START]: {
    name: {
      en: "This is how the game starts",
      cz: "Takto hra začíná",
    },
    description: [
      {
        en: "This is a test task. In order to fullfil it, you need to get a flask and fill it with water. Then take the flask and click with it on the test character.",
        cz: "Tohle je testovací úkol. Abyste jej splnili, vezměte lahev a naplňte ji vodou. Poté s ní klikněte na test postavu.",
      },
      /* description is an array so that images, subtitles etc. can later be added */
    ],
  },
  [GameObjective.obj_01_find_grandma]: undefined,
  [GameObjective.obj_02_find_lantern]: undefined,
  [GameObjective.obj_03_get_floating_fire]: undefined,
  [GameObjective.obj_04_talk_to_grandma]: undefined,
  [GameObjective.obj_05_travel_down]: undefined,
};

//
// FUNCTIONS
//
export const getMaxObjective = () => {
  const values = Object.values(GameObjective).filter(
    (value) => typeof value === "number"
  ) as number[];
  return Math.max(...values);
};

//
// TEST
//
export const GAME_OBJECTIVES_TEST_NAMES: {
  [key: number]: string;
} = {
  0: GAME_START,
  1: "obj_01_talkToGreenGuy",
  2: "obj_02_giveFlaskToGreenGuy",
};
