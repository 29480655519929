export * from "./character";
export * from "./gameConfig";
export * from "./font";
export * from "./gamePlay";
export * from "./gameSettings";
export * from "./scene";
export * from "./actions";
export * from "./item";
export * from "./gameItems";
export * from "./gameEffects";
export * from "./cursor";
export * from "./audio";
export * from "./logger";
export * from "./animation";
export * from "./cinematic";
export * from "./skill";
export * from "./imageComposition";

export enum Language {
  en = "en",
  cz = "cz",
}

export type TranslatedString = {
  en: string;
  cz: string;
};

export type Dimensions = {
  x: number;
  y: number;
};

export type Position = {
  x: number;
  y: number;
};

export enum TextAlign {
  left = "left",
  center = "center",
  right = "right",
}

export enum TextVerticalAlign {
  top = "top",
  bottom = "bottom",
  center = "center",
}

export enum Direction {
  left = "left",
  right = "right",
  up = "up",
  down = "down",
}

export type VideoType = {
  src: string;
  width?: number;
  height?: number;
  playCount?: number;
  frameDurationMilliseconds?: number;
};

export type ImageType = {
  src: string;
  width?: number;
  height?: number;
  opacity?: number;
  brightness?: number;
  transition?: ImageTransitionType; // this is used if render img.src changes
};

export type ImageTransitionType = {
  durationSec: number;
};

export enum ImageChannel {
  red = "red",
  green = "green",
  blue = "blue",
  alpha = "alpha",
}

export enum BlendingMode {
  SourceOver = "source-over",
  SourceIn = "source-in",
  SourceOut = "source-out",
  SourceAtop = "source-atop",
  DestinationOver = "destination-over",
  DestinationIn = "destination-in",
  DestinationOut = "destination-out",
  DestinationAtop = "destination-atop",
  Lighter = "lighter",
  Copy = "copy",
  Xor = "xor",
  Multiply = "multiply",
  Screen = "screen",
  Overlay = "overlay",
  Darken = "darken",
  Lighten = "lighten",
  ColorDodge = "color-dodge",
  ColorBurn = "color-burn",
  HardLight = "hard-light",
  SoftLight = "soft-light",
  Difference = "difference",
  Exclusion = "exclusion",
  Hue = "hue",
  Saturation = "saturation",
  Color = "color",
  Luminosity = "luminosity",
}

//
// SPRITE
//
export enum SpriteDirection {
  Vertical = "vertical",
  Horizontal = "horizontal",
}

export type SpriteConfigType = {
  frameWidth?: number; // width of individual frames in animation sprite
  frameHeight?: number;
  frameCount?: number;
  frameDurationMilliseconds?: number;
  frameSequence?: AnimationFrameSequence;
  originOffset?: Position; // for trimmed sprites to move them to the correct position before the image was trimmed (e.g. to be correctly placed in a scene)
  clipOffset?: Position; // offset of clip mask
  direction?: SpriteDirection;
  playCount?: number;
};

// allows to specify custom sequence of animation frames
//    - e.g. [0,1,2,2,2,2,2,1,0] for ping-pong style animation with a pause on 2
//
//  'original', 'reversed' etc. will auto-generate frame indexes to avoid the need to write them by hand
//    - previous example = ['original', {pause: 3}, 'reversed']
//
export enum FrameSequenceValue {
  "original" = "original",
  "reversed" = "reversed",
  "firstFrame" = "firstFrame",
  "lastFrame" = "lastFrame",
  "fadeOut" = "fadeOut",
  "fadeOutSmooth" = "fadeOutSmooth",
  "fadeIn" = "fadeIn",
  "fadeInSmooth" = "fadeInSmooth",
  "fadeInAndOut" = "fadeInAndOut",
  "fadeInAndOutSmooth" = "fadeInAndOutSmooth",
}

export type AnimationFrameSequenceItem =
  | number // frame index
  | FrameSequenceValue
  | {
      pause: number; // will copy previous frame 'pause' number of times
    }
  | {
      // will generate sub-sequence of frames (e.g. [4,5,6,7])
      from: number | "firstFrame" | "lastFrame";
      to: number | "firstFrame" | "lastFrame";
    };

export type AnimationFrameSequence = AnimationFrameSequenceItem[];

export type ColorRGBA = { r: number; g: number; b: number; a: number };
