import { ImageChannel, Position } from "game-engine/types";

export const getImageChannelIndex = (channel: ImageChannel) => {
  // data for pixel channels are [R,G,B,A] in sequence
  switch (channel) {
    case ImageChannel.green:
      return 1;
    case ImageChannel.blue:
      return 2;
    case ImageChannel.alpha:
      return 3;
    default:
      return 0; // red channel by default
  }
};

export const getImageChannelPixelMatrix: (options: {
  context: CanvasRenderingContext2D;
  imgWidth: number;
  imgHeight: number;
  channel: ImageChannel;
}) => number[][] = ({ context, imgWidth, imgHeight, channel }) => {
  const imageData = context.getImageData(0, 0, imgWidth, imgHeight).data;
  const channelIndex = getImageChannelIndex(channel);

  // Preallocate 2D array for pixel matrix
  const pixelMatrix = new Array(imgHeight)
    .fill(0)
    .map(() => new Array(imgWidth));

  // Iterate through imageData in steps of 4 (RGBA channels)
  for (let i = 0; i < imageData.length; i += 4) {
    const pixelIndex = i / 4; // Get pixel index (ignoring RGBA)
    const x = pixelIndex % imgWidth;
    const y = Math.floor(pixelIndex / imgWidth);

    // Store the relevant channel value in the matrix
    pixelMatrix[y][x] = imageData[i + channelIndex];
  }

  return pixelMatrix;
};

//
// GET IMAGE OFFSET
//
export const getImageOffset = (props: { width: number; height: number }) => {
  const w = props.width;
  const h = props.height;
  const imageOffset: Position = {
    x: Math.round(-1 * w * 0.5),
    y: Math.round(-1 * h),
  };

  return imageOffset;
};
