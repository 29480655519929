import {
  getCode_CommentSectionTitle,
  getCode_CommentSubSectionTitle,
  getCode_ImageImports,
  getCode_SceneAudio,
  getCode_SceneBackground,
  getCode_SceneCharacter,
  getCode_SceneLayer,
  getCode_SceneTalkActions,
  getCode_SceneWalkPaths,
  indentCode,
  prettifyCode,
  removeCommentsFromCode,
} from "game-engine/utils";

import { EditorType } from "..";
import { GameObjective } from "game-files/gameObjectives";
import { getCode_SceneName_Conditional } from "game-engine/utils/code-generation/code-scene-name";

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
//
// GET SCENE CODE WITH GENERATED IMPORTS
//
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export const getSceneCode: (props: { editor: EditorType }) => string = ({
  editor,
}) => {
  if (!editor?.scene || !editor.scene) {
    return "";
  }

  const sceneCode = getSceneCodeContent({ editor });
  const sceneCodeWithoutComments = removeCommentsFromCode(sceneCode);

  const codeLines = [];

  // Types
  const typeImports = [];
  ["SceneType", "CursorRenderType", "SpriteDirection"].forEach((typeImport) => {
    // check if import is being used in code and import
    if (sceneCodeWithoutComments.includes(typeImport)) {
      typeImports.push(typeImport);
    }
  });
  if (typeImports?.length) {
    codeLines.push(
      `import { ${typeImports.join(", ")} } from "game-engine/types";`
    );
  }

  // GameObjective
  if (sceneCodeWithoutComments.includes("GameObjective")) {
    codeLines.push(
      `import { GameObjective } from "game-files/gameObjectives";`
    );
  }

  // ACTION
  if (sceneCodeWithoutComments.includes("ACTION")) {
    codeLines.push(`import { ACTION } from "game-engine/utils";`);
  }

  // SHARED_DATA
  if (sceneCodeWithoutComments.includes("SHARED_DATA")) {
    codeLines.push(`import SHARED_DATA from "game-files/scenes/SHARED_DATA";`);
  }

  // MUSIC_CONFIGS
  if (sceneCodeWithoutComments.includes("MUSIC_CONFIGS")) {
    codeLines.push(
      `import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";`
    );
  }

  // SOUND_CONFIGS
  if (sceneCodeWithoutComments.includes("SOUND_CONFIGS")) {
    codeLines.push(
      `import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";`
    );
  }

  //
  // RETURN PRETTIFIED CODE
  //
  codeLines.push(``);
  codeLines.push(sceneCode);

  return prettifyCode(codeLines.join("\n"));
};

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
//
// GET SCENE CODE WITHOUT COMMON IMPORTS (these need to be generated from this code)
//
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////

export const getSceneCodeContent: (props: { editor: EditorType }) => string = ({
  editor,
}) => {
  if (!editor?.scene || !editor.scene) {
    return "";
  }

  const noExamples = editor?.noCodeExamples;

  const codeLines = [];

  //
  // IMPORTS
  //

  // Images
  const imagePreviewName = "IMAGE_Preview";

  const images = [
    ...["image", "walkMap", "dropMap", "depthMap"].map((key) => {
      return {
        name: editor.meta.background.names[key],
        filename: editor.meta.background.filenames[key],
      };
    }),

    ...editor.sceneLayersMeta.map((layer) => {
      return {
        name: layer.sceneLayerId,
        filename: layer.filename,
      };
    }),

    { name: imagePreviewName, filename: editor.meta.previewFileName },
  ];

  codeLines.push(getCode_ImageImports(images));
  codeLines.push(``);

  //
  // SCENE DEF
  //
  codeLines.push(`
    const ${editor.meta.componentName}: SceneType = {
      configId: "${editor.meta.componentName}", // --------- KEEP SAME AS COMPONENT NAME
      isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
      isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
      sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
      uniqueId: undefined, // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
      preview: { src: ${imagePreviewName} },
    `);

  //
  // SCENE NAME
  //
  const sceneName = editor.scene.name[GameObjective.GAME_START];
  codeLines.push(getCode_CommentSectionTitle("Scene Name"));
  codeLines.push(`
    name: {
      [GameObjective.GAME_START]: { 
        en: "${sceneName.en}",
        cz: "${sceneName.cz}"${
    editor.noCodeExamples
      ? ""
      : `
        /*
        ${indentCode(getCode_SceneName_Conditional(), 4, {
          ignoreFirstLine: true,
        })}
        */`
  }
      },
    },
    `);

  //
  // SCENE AUDIO
  //
  codeLines.push(getCode_CommentSectionTitle("Scene Audio"));
  codeLines.push(`
    audio: {
      [GameObjective.GAME_START]: ${getCode_SceneAudio(
        editor.scene.audio[GameObjective.GAME_START],
        { includeConditionalExample: !noExamples }
      )} },
      `);

  //
  // WALK PATHS
  //
  codeLines.push(getCode_CommentSectionTitle("Scene Walk Paths"));
  codeLines.push(`
    sceneWalkPaths: {
      [GameObjective.GAME_START]: ${getCode_SceneWalkPaths(editor)} },`);

  //
  // SCENE IMAGES
  //
  codeLines.push(``);
  codeLines.push(`images: {`);
  codeLines.push(getCode_CommentSectionTitle("Scene Background"));
  codeLines.push(`
    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]:${getCode_SceneBackground(editor, {
          includeConditionalExample: !noExamples,
        })}
      },
      
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]:{
          actionsWithItem: undefined,
          actions: ${getCode_SceneTalkActions(
            editor.meta.background.dialogActions
          )}}
      }
    },`);

  codeLines.push(``);
  codeLines.push(getCode_CommentSectionTitle("Scene Layers"));
  codeLines.push(`layers: [`);
  editor.sceneLayersMeta.forEach((layerMeta, i) => {
    codeLines.push(getCode_CommentSubSectionTitle("Scene Layer"));
    codeLines.push(
      getCode_SceneLayer(
        editor.scene.images.layers.find(
          (layer) => layer.id === layerMeta.sceneLayerId
        ),
        layerMeta,
        i,
        { noExamples: noExamples }
      )
    );
  });
  codeLines.push(`]`);
  // images closing brace
  codeLines.push(`},`);

  //
  // SCENE ACTIONS
  //
  codeLines.push(``);
  codeLines.push(getCode_CommentSectionTitle("Scene Actions"));

  // ON-SCENE-IDLE
  codeLines.push(`
    onSceneIdle: {
      // idleAfterMinSec: 1,
      // idleAfterMaxSec: 2,
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
          // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)`);

  codeLines.push(
    noExamples
      ? `actions: []`
      : `actions: [
            /*
            // EXAMPLE OF IDLE ACTIONS
            ACTION.sequence([
              ACTION.talk({
                characterId: CharacterId.TestCharacter,
                dialog: [
                  {
                    en: \`ACTION 1 - CHAR A\`,
                    cz: \`ACTION 1 - CHAR A\`,
                  },
                ],
              }),
              ACTION.talk({
                dialog: [
                  {
                    en: \`ACTION 1 - CHAR B\`,
                    cz: \`ACTION 1 - CHAR B\`,
                  },
                ],
              }),
            ]),

            ACTION.talk({
              characterId: CharacterId.TestCharacter,
              dialog: [
                {
                  en: \`ACTION 2 - CHAR A\`,
                  cz: \`ACTION 2 - CHAR A\`,
                },
              ],
            }),
            */

          ]`
  );

  codeLines.push(`
        }
      }
    },`);

  // ON-SCENE-INIT
  codeLines.push(`
    onSceneInit: {
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED`);
  codeLines.push(
    noExamples
      ? `actions: []`
      : `actions: [

            /*
            // EXAMPLE OF CONDITIONAL INIT ACTIONS
            ACTION.conditional(({ gamePlay, gameItems }) => [
              {
                condition: gamePlay.events.test_treeClickCount === 0,
                actions: [
                  ACTION.talkRandom({
                    characterId: CharacterId.TestCharacter,
                    dialogOptions: [
                      {
                        en: \`ACTION FOR treeClickCount === 0.\`,
                        cz: \`ACTION FOR treeClickCount === 0.\`,
                      },
                    ],
                  }),
                ],
              },

              {
                condition: gamePlay.events.test_treeClickCount > 0,
                actions: [
                  ACTION.talkRandom({
                    characterId: CharacterId.TestCharacter,
                    dialogOptions: [
                      {
                        en: \`ACTION FOR treeClickCount > 0.\`,
                        cz: \`ACTION FOR treeClickCount > 0.\`,
                      },
                    ],
                  }),
                ],
              },
              
            ]),
            */

          ]`
  );
  codeLines.push(`
        }
      }
    },`);

  // ON-SCENE-SKILL-START
  if (noExamples) {
    codeLines.push(`\nonSkillStart: {},`);
  } else {
    codeLines.push(`
      onSkillStart: {
        /* 
        // EXAMPLE OF SKILL ACTIONS
        [SkillId.Healing]: {
          actionsByCurrentObjective: {
            [GameObjective.GAME_START]: {
              actionsBeforeSkill: [
                ACTION.conditional(({ gamePlay, gameItems }) => [
                  {
                    condition:
                      gamePlay.events.test_sickTreeState_HealingSkill === 0,
                    actions: [
                      ACTION.talk({
                        dialog: {
                          en: "Action before skill is started",
                          cz: undefined,
                        },
                      }),
                    ],
                  },
                ]),
              ],
              actions: [
                ACTION.conditional(({ gamePlay, gameItems }) => [
                  {
                    condition:
                      gamePlay.events.test_sickTreeState_HealingSkill === 0,
                    actions: [
                      ACTION.talk({
                        dialog: {
                          en: "Action after skill is started",
                          cz: undefined,
                        },
                      }),
                    ],
                  },
                ]),
              ],
            },
          },
        }, */
      },
  `);
  }

  //
  // SCENE CHARACTERS
  //
  codeLines.push(``);
  codeLines.push(getCode_CommentSectionTitle("Scene Characters"));
  if (noExamples) {
    codeLines.push(`characters: []`);
  } else {
    codeLines.push(`characters: [
      /*
      ${indentCode(getCode_SceneCharacter(), 3, { ignoreFirstLine: true })}
      */
    ],`);
  }

  // closing brace
  codeLines.push(`}`);
  codeLines.push(``);
  codeLines.push(`export default ${editor.meta.componentName};`);

  //
  // RETURN PRETTIFIED CODE
  //
  return prettifyCode(codeLines.join("\n"));
};
