import TransitionFade, {
  TransitionFadeAnimation,
} from "game-engine/components/basic-elements/Transition/types/fade";
import { useEffect, useRef, useState } from "react";

import { BlendingMode } from "game-engine/types";
import { Group } from "react-konva";
import SceneFill from "game-engine/components/game-elements/SceneFill";
import useGame from "game-engine/hooks/useGame";

const CelestialFlameEffect = () => {
  const { gamePlay } = useGame();

  const tintColor = `#0096FF`;
  const tintOpacity = 0.3; // TODO - MAKE THIS '1' FOR deathByDarkness SCENES

  const flickerColor = `#afe7ff`;
  const flickerOpacity = 0.3;

  // HANDLE FADE IN-OUT
  const [fade, setFade] = useState<TransitionFadeAnimation>({
    from: gamePlay.state.currentSkills?.CelestialFlame?.isActive ? 1 : 0,
  });

  const isFirstRenderRef = useRef(true);
  const isActiveOnInitRef = useRef(
    gamePlay.state.currentSkills?.CelestialFlame?.isActive
  );

  useEffect(() => {
    if (gamePlay.state.currentSkills?.CelestialFlame?.isActive) {
      setFade({
        from: 0,
        to: 1,
        durationSec:
          isFirstRenderRef.current && isActiveOnInitRef.current ? 0 : 1,
      });
    } else {
      setFade({
        from: 1,
        to: 0,
        durationSec: 1,
      });
    }

    isFirstRenderRef.current = false;
  }, [gamePlay.state.currentSkills?.CelestialFlame?.isActive]);

  // FLICKERING EFFECT
  const [flickerOpacityAnimated, setFlickerOpacityAnimated] = useState(1);
  const flickerOpacityMin = 0; // Minimum opacity for flickering
  const flickerOpacityMax = 1; // Maximum opacity for flickering
  const flickerStepDurationSec = 0.5; // Duration for the opacity change step (in seconds)
  const flickerUpdateIntervalMs = 100; // Duration for the opacity to redraw (between each step, milliseconds)

  useEffect(() => {
    // Only run the flickering logic if the effect is active
    if (!gamePlay.state.currentSkills?.CelestialFlame?.isActive) {
      return;
    }

    const interval = setInterval(() => {
      // Get a random opacity value between flickerOpacityMin and flickerOpacityMax
      const randomOpacity =
        Math.random() * (flickerOpacityMax - flickerOpacityMin) +
        flickerOpacityMin;

      const currentOpacity = flickerOpacityAnimated;

      // Smoothly interpolate between the current opacity and the new random opacity
      const interpolatedOpacity =
        currentOpacity +
        (randomOpacity - currentOpacity) * (flickerStepDurationSec / 2); // Adjust factor for smoothness
      setFlickerOpacityAnimated(interpolatedOpacity);
    }, flickerUpdateIntervalMs);

    return () => clearInterval(interval); // Clean up on unmount
  }, [
    gamePlay.state.currentSkills?.CelestialFlame?.isActive,
    flickerOpacityAnimated,
    flickerOpacityMin,
    flickerOpacityMax,
    flickerStepDurationSec,
    flickerUpdateIntervalMs,
  ]);

  // RENDER
  return (
    <TransitionFade animatedOpacity={fade}>
      <Group listening={false}>
        <SceneFill
          color={tintColor}
          blendingMode={BlendingMode.Color}
          opacity={tintOpacity}
        />

        <Group opacity={flickerOpacityAnimated}>
          <SceneFill
            color={flickerColor}
            blendingMode={BlendingMode.SoftLight}
            opacity={flickerOpacity}
          />
        </Group>
      </Group>
    </TransitionFade>
  );
};

export default CelestialFlameEffect;
