import GameConfigs from "game-engine/_dev/dev-components/GameConfigs";
import GameEngine from "game-engine/_dev/dev-components/GameEngine";
import GamePlay from "game-engine/_dev/dev-components/GamePlay";
import GameState from "../../../_dev/dev-components/GameState";
import Sidebar from "../../../_dev/basic-components/Sidebar";

const SidebarLeft = (props: {
  width?: string;
  isOpen?: boolean;
  isNotBrowserHeight?: boolean;
}) => {
  //
  // RENDER
  //
  return (
    <Sidebar
      name="Settings"
      position="left"
      width={props.width || "420px"}
      forcedHeight={props.isNotBrowserHeight ? undefined : "100vh"}
      isOpen={props.isOpen ?? true}
    >
      <GamePlay isOpen />
      <GameEngine />
      <GameState />
      <GameConfigs />
    </Sidebar>
  );
};

export default SidebarLeft;
