import {
  ActionQueueType,
  CinematicSceneType,
  SceneCharacterType,
  SceneCharactersType,
  SceneMainCharacterOptions,
  SceneType,
} from "game-engine/types";

import { ACTION } from "../actions";
import { CharacterId } from "game-files/ids";
import { GameObjective } from "game-files/gameObjectives";

export const getSceneFromCinematicScene = (props: {
  cinematicScene: CinematicSceneType;
  onEndActions?: ActionQueueType;
}) => {
  const { cinematicScene, onEndActions = [] } = props;

  const trimmedSceneConfig = { ...cinematicScene.scene };
  delete trimmedSceneConfig.characters;
  delete trimmedSceneConfig.mainCharacterOptions;
  delete trimmedSceneConfig.onSceneIdle;
  delete trimmedSceneConfig.onSceneInit;

  // CHARACTERS
  const characters: SceneCharactersType = [];
  let mainCharacterOptions: SceneMainCharacterOptions = {
    isHidden: true,
  };

  cinematicScene.characters?.forEach((cinematicCharacter) => {
    // Setup main character
    if (cinematicCharacter.config.id === CharacterId.MainCharacter) {
      mainCharacterOptions = {
        isHidden: false,
        position: cinematicCharacter.position,
        facing: cinematicCharacter.facing,
      };
    }
    // Process remaining scene characters
    else {
      const trimmedCharacterConfig = { ...cinematicCharacter.config };
      delete trimmedCharacterConfig.cursorOnHover;
      delete trimmedCharacterConfig.idleConfig;
      delete trimmedCharacterConfig.onClick;
      delete trimmedCharacterConfig.onSceneIdle;

      const sceneCharacter: SceneCharacterType = {
        config: trimmedCharacterConfig,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            position: cinematicCharacter.position ?? { x: -1000, y: -1000 },
            scale: cinematicCharacter.scale,
            facing: cinematicCharacter.facing,
          },
        },
      };
      characters.push(sceneCharacter);
    }
  });

  // ACTIONS
  const cinematicActions = cinematicScene.actions || [];

  const fadeOutActions = cinematicScene.fadeOut
    ? [
        ACTION.sceneFadeOut({
          durationSec: (cinematicScene.fadeOut as any).durationSec ?? 1,
        }),
      ]
    : [];

  const actions = [...cinematicActions, ...fadeOutActions, ...onEndActions];

  // SCENE CONFIG
  const sceneConfig: SceneType = {
    ...trimmedSceneConfig,

    onSceneInit: {
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: { actions },
      },
    },

    mainCharacterOptions,
    characters,
  };

  return sceneConfig;
};
