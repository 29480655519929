import { Direction, SceneType } from "game-engine/types";

import { ACTION } from "game-engine/utils";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP_00.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP_00.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP_00.png";
import IMAGE_Layer_01_Background from "./assets/layer - 01 - background_00.png";
import IMAGE_Layer_02_Assets_Right from "./assets/layer - 02 - assets right_00.png";
import IMAGE_Layer_03_Assets_Left from "./assets/layer - 03 - assets left_00.png";
import IMAGE_Layer_04_Bush from "./assets/layer - 04 - bush_00.png";
import IMAGE_Layer_05_Foreground_Trees from "./assets/layer - 05 - foreground trees_00.png";
import IMAGE_Preview from "./assets/preview_00.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import { SkillId } from "game-files/ids";
import TestCharacterConfig from "game-files/characters/TestCharacter";

const DevScene05: SceneType = {
  configId: "DevScene05", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: "DevScene05", // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE NAME
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Invisibility Skill - click on the guy while on/off",
      cz: undefined,

      /*
      // EXAMPLE OF CONDITIONAL SCENE NAME
      conditional: ({ events }) => [
      
        {
          condition: events.test_treeClickCount > 3,
          state: {
            en: "SCENE NAME FOR treeClickCount > 3",
            cz: "SCENE NAME FOR treeClickCount > 3",
          },
        },
      
      ],
      */
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE AUDIO
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: undefined,
        volume: undefined,
        loop: undefined,
      },

      /*      
      // EXAMPLE OF CONDITIONAL SCENE AUDIO
      conditional: ({ events }) => [
      
        {
          condition: events.test_treeClickCount > 3,
          state: {
            music: {
              musicConfigId: MUSIC_CONFIGS.Test_03.id,
            },
            sound: {
              soundConfigId: SOUND_CONFIGS.Test_Bird.id,
              volume: 0.5,
              loop: true,
            },
          },
        },
      
      ],
      */
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE WALK PATHS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: {
        edgeWalkY: 122,
        walkTo: { x: 104, y: 124 },
      },
      right: {
        edgeWalkY: 121,
        walkTo: { x: 267, y: 122 },
      },
      up: {
        edgeWalkX: 168,
        walkTo: { x: 159, y: 46 },
      },
      down: {
        edgeWalkX: 201,
        walkTo: { x: 201, y: 130 },
      },

      /*
      // EXAMPLE OF CONDITIONAL SCENE WALK-PATHS
      conditional: ({ events }) => [
      
        {
          condition: events.test_treeClickCount > 1,
          state: {
            right: {
              edgeWalkY: 100,
              walkTo: { x: 278, y: 106 },
            },
          },
        }

      ]
      */
    },
  },

  images: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE BACKGROUND
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          fillColor: undefined,
          image: {
            src: IMAGE_Layer_01_Background,
            transition: undefined,
          },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
          depthSettings: {
            scaleMin: undefined,
            scaleMax: undefined,
          },

          /*      
          // EXAMPLE OF CONDITIONAL SCENE BACKGROUND
          conditional: ({ events }) => [
          
            {
              condition: events.test_sickTreeState === 1,
              state: {
                image: { src: IMAGE_Layer_01_Background_A_B_Sprite },
                imageSpriteConfig: {
                  direction: SpriteDirection.Vertical,
                  playCount: 1, // plays once
                  frameCount: 10,
                  frameWidth: GAME_CONFIG.scene.dimensions.x,
                  frameHeight: GAME_CONFIG.scene.dimensions.y,
                  //frameDurationMilliseconds: 80,
                },
          
                dropMap: { src: IMAGE_Layer_00_DROP_MAP }, //   STATE NEEDS TO BE COMPLETE
                walkMap: { src: IMAGE_Layer_00_WALK_MAP }, //   STATE NEEDS TO BE COMPLETE
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP }, // STATE NEEDS TO BE COMPLETE
              },
            },
          
            {
              condition: events.test_sickTreeState > 1,
              state: {
                image: { src: IMAGE_Layer_01_Background_B },
          
                dropMap: { src: IMAGE_Layer_00_DROP_MAP }, //   STATE NEEDS TO BE COMPLETE
                walkMap: { src: IMAGE_Layer_00_WALK_MAP }, //   STATE NEEDS TO BE COMPLETE
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP }, // STATE NEEDS TO BE COMPLETE
              },
            },
          
          ],
          */
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsWithItem: undefined,
          actions: [
            ACTION.talkRandom({
              dialogOptions: [
                ...SHARED_DATA.dialogOptions.forest,
                ...SHARED_DATA.dialogOptions.forestBackground,
              ],
            }),
          ],
        },
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_02_Assets_Right",
        depthY: 42,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Assets_Right },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,

            /* 
            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
            
              {
                condition: events.test_treeClickCount > 0,
                state: {
                  image: {
                    src: ImageLayerTree1_State2,
                    transition: { durationSec: 1 },
                  },
                },
              },

            ],
            */
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_03_Assets_Left",
        depthY: 31,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_03_Assets_Left },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.treeOld,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_04_Bush",
        depthY: 88,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_04_Bush },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.bush],
              }),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_05_Foreground_Trees",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_05_Foreground_Trees },
            spriteConfig: undefined,
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [...SHARED_DATA.dialogOptions.tree],
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
        // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)
        actions: [
          /*
          // EXAMPLE OF IDLE ACTIONS
          ACTION.sequence([
            ACTION.talk({
              characterId: CharacterId.TestCharacter,
              dialog: [
                {
                  en: `ACTION 1 - CHAR A`,
                  cz: `ACTION 1 - CHAR A`,
                },
              ],
            }),
            ACTION.talk({
              dialog: [
                {
                  en: `ACTION 1 - CHAR B`,
                  cz: `ACTION 1 - CHAR B`,
                },
              ],
            }),
          ]),

          ACTION.talk({
            characterId: CharacterId.TestCharacter,
            dialog: [
              {
                en: `ACTION 2 - CHAR A`,
                cz: `ACTION 2 - CHAR A`,
              },
            ],
          }),
          */
        ],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED
        actions: [
          /*
          // EXAMPLE OF CONDITIONAL INIT ACTIONS
          ACTION.conditional(({ gamePlay, gameItems }) => [
            {
              condition: gamePlay.events.test_treeClickCount === 0,
              actions: [
                ACTION.talkRandom({
                  characterId: CharacterId.TestCharacter,
                  dialogOptions: [
                    {
                      en: `ACTION FOR treeClickCount === 0.`,
                      cz: `ACTION FOR treeClickCount === 0.`,
                    },
                  ],
                }),
              ],
            },

            {
              condition: gamePlay.events.test_treeClickCount > 0,
              actions: [
                ACTION.talkRandom({
                  characterId: CharacterId.TestCharacter,
                  dialogOptions: [
                    {
                      en: `ACTION FOR treeClickCount > 0.`,
                      cz: `ACTION FOR treeClickCount > 0.`,
                    },
                  ],
                }),
              ],
            },
            
          ]),
          */
        ],
      },
    },
  },

  onSkillStart: {
    /* 
    // EXAMPLE OF SKILL ACTIONS
    [SkillId.Healing]: {
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsBeforeSkill: [
            ACTION.conditional(({ gamePlay, gameItems }) => [
              {
                condition:
                  gamePlay.events.test_sickTreeState_HealingSkill === 0,
                actions: [
                  ACTION.talk({
                    dialog: {
                      en: "Action before skill is started",
                      cz: undefined,
                    },
                  }),
                ],
              },
            ]),
          ],
          actions: [
            ACTION.conditional(({ gamePlay, gameItems }) => [
              {
                condition:
                  gamePlay.events.test_sickTreeState_HealingSkill === 0,
                actions: [
                  ACTION.talk({
                    dialog: {
                      en: "Action after skill is started",
                      cz: undefined,
                    },
                  }),
                ],
              },
            ]),
          ],
        },
      },
    }, */
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [
    {
      config: TestCharacterConfig,
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          position: { x: 250, y: 114 },
          facing: Direction.left,

          onClickOverride: {
            actionsByCurrentObjective: {
              [GameObjective.GAME_START]: {
                actions: [
                  // EXAMPLE OF CONDITIONAL ACTIONS WITH INVISIBILITY SKILL
                  ACTION.conditional(({ gamePlay, gameItems }) => [
                    {
                      condition: gamePlay.currentSkills?.Invisibility?.isActive,
                      actions: [
                        ACTION.talk({
                          characterId: TestCharacterConfig.id,
                          dialog: {
                            en: "Hey, who touched me?!",
                            cz: "Hej, kdo to na mě sáhnul?!",
                          },
                        }),
                        ACTION.talk({
                          dialog: {
                            en: "He he.",
                            cz: "He he.",
                          },
                        }),
                      ],
                    },
                    {
                      condition:
                        !gamePlay.currentSkills?.Invisibility?.isActive,
                      actions: [
                        ACTION.talk({
                          characterId: TestCharacterConfig.id,
                          dialog: {
                            en: `You can't touch me, I can see you.`,
                            cz: `Na mě si nesáhneš, vidím tě.`,
                          },
                        }),
                      ],
                    },
                  ]),
                ],
                actionsWithItem: {
                  default: [],
                  // EXAMPLE OF ASSIGNING DIFFERENT ACTIONS TO SPECIFIC ITEMS
                  // [ItemConfigId.Test_Key]: [],
                  // [ItemConfigId.Test_Flask]: [],
                  // [ItemConfigId.Test_FlaskWater]: [],
                },
              },
            },
          },

          // EXAMPLE OF CONDITIONAL CHARACTER OPTIONS
          conditional: ({ events }) => [
            {
              condition: events.test_treeClickCount > 3,
              state: {
                facing: Direction.left,
                position: { x: 200, y: 100 },
              },
            },
          ],
        },
      },
    },
  ],
};

export default DevScene05;
