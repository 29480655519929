import { EditorType, SceneMeta } from "../..";

import Box from "game-engine/_dev/basic-components/Box";
import GAME_CONFIG from "game-files/gameConfig";
import { GAME_EVENTS } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import PositionInput from "game-engine/_dev/basic-components/inputs/PositionInput";
import { SceneType } from "game-engine/types";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";
import TranslatedStringInput from "game-engine/_dev/basic-components/inputs/TranslatedStringInput";
import { getSceneNameByCurrentObjective } from "game-engine/utils";

const SectionSceneSettings = (props: {
  isOpen?: boolean;
  onOpenChange?: (v) => void;
  editor: EditorType;
  updateScene: (scene: SceneType) => void;
  updateMeta: (meta: SceneMeta) => void;
}) => {
  const { editor, updateScene, updateMeta } = props;

  //
  // RENDER
  //
  return (
    <Box
      label="Scene Settings"
      level={1}
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      useFlex
      childMinWidth="90%"
      gap="30px"
      contentPaddingTop="20px"
      contentPaddingBottom="40px"
      contentPaddingLeft="20px"
      contentPaddingRight="0px"
    >
      <TextInput
        label="Component Name"
        value={editor?.meta.componentName}
        onChange={(v) => updateMeta({ ...editor.meta, componentName: v })}
      />

      <TranslatedStringInput
        label="Scene Name"
        value={getSceneNameByCurrentObjective({
          dataByCurrentObjective: editor.scene.name,
          currentObjective: GameObjective.GAME_START,
          events: GAME_EVENTS,
        })}
        onChange={(s) =>
          updateScene({
            ...editor.scene,
            name: {
              [GameObjective.GAME_START]: s,
            },
          })
        }
      />

      <PositionInput
        label="Character Depth Scaling (%)"
        prefixes={{ x: "min", y: "max" }}
        value={{
          x: Math.round(
            (editor.scene.images.background.dataByCurrentObjective[
              GameObjective.GAME_START
            ].depthSettings.scaleMin ||
              GAME_CONFIG.scene.defaultDepthSettings.scaleMin) * 100
          ),
          y: Math.round(
            (editor.scene.images.background.dataByCurrentObjective[
              GameObjective.GAME_START
            ].depthSettings.scaleMax ||
              GAME_CONFIG.scene.defaultDepthSettings.scaleMax) * 100
          ),
        }}
        onChange={(p) =>
          updateScene({
            ...editor.scene,
            images: {
              ...editor.scene.images,
              background: {
                dataByCurrentObjective: {
                  [GameObjective.GAME_START]: {
                    ...editor.scene.images.background.dataByCurrentObjective[
                      GameObjective.GAME_START
                    ],

                    depthSettings: {
                      ...editor.scene.images.background.dataByCurrentObjective[
                        GameObjective.GAME_START
                      ].depthSettings,
                      scaleMin: Math.round(p.x) / 100,
                      scaleMax: Math.round(p.y) / 100,
                    },
                  },
                },
              },
            },
          })
        }
        rangeControllerX={{
          min: 0,
          max: 100,
          step: 5,
        }}
        rangeControllerY={{
          min: 0,
          max: 100,
          step: 5,
        }}
      />
    </Box>
  );
};

export default SectionSceneSettings;
