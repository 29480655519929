import {
  ActionCinematicOnEnd,
  ActionId,
  Action_Cinematic,
  GamePlaySceneFade,
  GamePlaySceneWalkIn,
} from "game-engine/types";

import { CinematicId } from "game-files/ids";
import { createActionName } from ".";

//
// CINEMATIC SCENE ACTION
//
type PlayCinematicProps = {
  cinematicId: CinematicId;
  onEnd: ActionCinematicOnEnd;
};

type PlayCinematicReturn = () => Action_Cinematic;

export const playCinematic: (
  props?: PlayCinematicProps
) => PlayCinematicReturn = (props) => () => {
  return {
    ...props,
    _name: createActionName({
      name: `play cinematic`,
      value: props.cinematicId,
    }),
    id: ActionId.cinematic,
    cinematicId: props.cinematicId,
    onEnd: props.onEnd,
  };
};

//
// CINEMATIC SEQUENCE
//
type PlayCinematicSequenceProps = {
  cinematicIds: CinematicId[];
  onEnd: {
    setSceneId?: string;
    returnToThisScene?: boolean;
    sceneFadeIn?: GamePlaySceneFade;
    sceneWalkIn?: GamePlaySceneWalkIn;
  };
  pauseBetween?: {
    durationSec: number;
  };
};

type PlayCinematicSequenceReturn = () => Action_Cinematic;

export const playCinematicSequence: (
  props?: PlayCinematicSequenceProps
) => PlayCinematicSequenceReturn = (props) => () => {
  const cinematicId = props.cinematicIds[0];

  const onEnd: ActionCinematicOnEnd = {
    ...props.onEnd,
    pause: props.pauseBetween,
    // this will trigger remaining cinematics in sequence onEnd:
    setCinematicIds: [...props.cinematicIds].slice(1),
  };

  return playCinematic({ cinematicId, onEnd })();
};
