import { ACTION, capitalizeFirstLetter } from "game-engine/utils";
import { useContext, useEffect, useState } from "react";

import Box from "../../../../basic-components/Box";
import Button from "game-engine/_dev/basic-components/Button";
import { CharacterId } from "game-files/ids";
import { DevToolsContext } from "game-engine";
import { Direction } from "game-engine/types";
import Divider from "game-engine/_dev/basic-components/Divider";
import { MainCharacterAnimationId } from "game-files/characters/MainCharacter";
import TextInput from "game-engine/_dev/basic-components/inputs/TextInput";

const SectionMainCharacter = (props: { isOpen?: boolean }) => {
  const devTools = useContext(DevToolsContext);

  const runActions = devTools.state.actions.runActionsRef?.current;

  const [isDisabled, setIsDisabled] = useState(!runActions);
  useEffect(() => {
    setIsDisabled(!runActions);
  }, [runActions]);

  //
  // ACTIONS - TALK
  //
  const [talkText, setTalkText] = useState<string>(
    "This is an example of how to talk"
  );

  const actionTalk = () => {
    runActions([
      ACTION.talk({
        characterId: CharacterId.MainCharacter,
        dialog: [
          {
            en: talkText,
            cz: undefined,
          },
        ],
      }),
    ]);
  };

  //
  // ACTIONS - FACE DIRECTION
  //
  const actionFaceDirection = (direction: Direction) => {
    runActions([
      ACTION.face({
        characterId: CharacterId.MainCharacter,
        faceDirection: direction,
      }),
    ]);
  };

  //
  // ACTIONS - KILL MAIN CHARACTER
  //
  const actionKillMainCharacter = () => {
    runActions([ACTION.saveGameBeforeDeath(), ACTION.killMainCharacter()]);
  };

  //
  // ACTIONS - KILL MAIN CHARACTER
  //
  const actionCharacterAnimation = (animationId) => {
    runActions([ACTION.characterAnimation({ animationId })]);
  };

  //
  // RENDER
  //
  return (
    <Box
      label="Main Character"
      childMinWidth="80%"
      contentPaddingBottom="20px"
      isOpen={props.isOpen}
      level={2}
      gap="0px"
    >
      <Divider name="Animations:" marginTop="0px" />

      <Box renderContentOnly childMinWidth="40%" isOpen>
        {Object.values(MainCharacterAnimationId).map((animationId) => (
          <Button
            key={animationId}
            isDisabled={isDisabled}
            onClick={() => actionCharacterAnimation(animationId)}
          >
            {`${capitalizeFirstLetter(animationId)}`}
          </Button>
        ))}
      </Box>

      <Divider name="Talk:" />

      <Box renderContentOnly childMinWidth="80%" isOpen>
        <TextInput value={talkText} onChange={setTalkText} />
        <Button isDisabled={isDisabled} onClick={actionTalk}>
          {"Talk"}
        </Button>
      </Box>

      <Divider name="Face Direction:" />

      <Box renderContentOnly childMinWidth="40%" isOpen>
        {[Direction.left, Direction.right].map((direction) => (
          <Button
            key={direction}
            isDisabled={isDisabled}
            onClick={() => actionFaceDirection(direction)}
          >
            {`Face ${direction}`}
          </Button>
        ))}
      </Box>

      <Divider name="Death:" />

      <Button isDisabled={isDisabled} onClick={actionKillMainCharacter}>
        {"Kill Main Character"}
      </Button>
    </Box>
  );
};

export default SectionMainCharacter;
