/* eslint-disable react-hooks/exhaustive-deps */

import {
  SceneCharacterDataType,
  SceneCharacterType,
  SceneType,
} from "game-engine/types";
import {
  getSceneCharacterDataByCurrentObjective,
  getSceneNameByCurrentObjective,
  stringifyTranslations,
} from "game-engine/utils";

import Box from "game-engine/_dev/basic-components/Box";
import CharacterList from "game-engine/_dev/basic-components/CharacterList";
import InfoTable from "game-engine/_dev/basic-components/InfoTable";
import ItemList from "game-engine/_dev/basic-components/ItemList";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const SceneInfo = (props: {
  scene: SceneType;
  boxLevel?: number;
  isOpen?: boolean;
}) => {
  const { scene } = props;
  const { gamePlay, gameItems, gameFns } = useGame();

  const thumbnailSize = 46;

  const sceneCharactersInfo = useMemo<
    {
      sceneCharacter: SceneCharacterType;
      sceneCharacterData: SceneCharacterDataType;
    }[]
  >(() => {
    const data = scene?.characters?.map((sceneCharacter) => {
      return {
        sceneCharacter,
        sceneCharacterData: getSceneCharacterDataByCurrentObjective({
          config: sceneCharacter.config,
          dataByCurrentObjective: sceneCharacter.dataByCurrentObjective,
          currentObjective: gameFns.getCurrentObjective(),
          events: gameFns.getEvents(),
        }),
      };
    });

    return data || [];
  }, [scene, gamePlay.state.currentObjective, gamePlay.state.events]);

  const sceneNeighbors = [
    scene?.sceneNeighbors?.sceneId_left && "Left",
    scene?.sceneNeighbors?.sceneId_right && "Right",
    scene?.sceneNeighbors?.sceneId_up && "Up",
    scene?.sceneNeighbors?.sceneId_down && "Down",
  ]
    .filter((v) => v)
    .join(", ");

  const itemsInScene = gameItems.state.items.filter(
    (item) => item.inScene?.sceneId === scene?.uniqueId
  );

  const sceneName = useMemo(() => {
    return getSceneNameByCurrentObjective({
      dataByCurrentObjective: scene?.name,
      currentObjective: gameFns.getCurrentObjective(),
      events: gameFns.getEvents(),
    });
  }, [scene, gamePlay.state.currentObjective, gamePlay.state.events]);

  //
  // RENDER
  //
  return (
    scene && (
      <>
        <Box
          renderContentOnly
          label="Info"
          useFlex
          gap="10px"
          childMinWidth="60%"
          level={2}
          isOpen
          contentPaddingTop="10px"
          contentPaddingBottom="20px"
        >
          <InfoTable
            data={[
              {
                label: "unique id",
                value: scene.uniqueId,
              },
              {
                label: "config id",
                value: scene.configId,
              },
              {
                label: "neighbors",
                value: sceneNeighbors?.length ? sceneNeighbors : "✕",
              },
              {
                label: "scene name",
                value: stringifyTranslations(sceneName),
              },
            ]}
          />
        </Box>

        <Box
          label={`Characters${
            sceneCharactersInfo?.length
              ? ` (${sceneCharactersInfo?.length})`
              : ""
          }`}
          useFlex
          gap="10px"
          childMinWidth="60%"
          level={2}
        >
          <CharacterList
            isInSceneInfo
            sceneCharactersInfo={sceneCharactersInfo}
            thumbnailSettings={{ width: thumbnailSize, height: thumbnailSize }}
            previewSettings={{ width: 100, height: 100, scale: 3 }}
          />
        </Box>

        <Box
          label={`Items${
            itemsInScene?.length ? ` (${itemsInScene?.length})` : ""
          }`}
          useFlex
          gap="10px"
          childMinWidth="60%"
          level={2}
        >
          <ItemList
            items={itemsInScene}
            thumbnail={{ width: thumbnailSize, height: thumbnailSize }}
            preview={{ width: 70, height: 70, scale: 4 }}
            infoInScene={{ noSceneId: true }}
          />
        </Box>
      </>
    )
  );
};

export default SceneInfo;
