import { ITEM_GENDER, getItemSuffixes } from "game-files/items/_utils";

import IMG_default from "./assets/default.png";
import { ItemConfigId } from "game-files/ids";
import { ItemConfigType } from "game-engine/types/item";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";

const Test_FlaskToxic: ItemConfigType = {
  configId: ItemConfigId.Test_FlaskToxic,
  name: { en: "Flask with dubious liquid", cz: "Láhev s podivnou kapalinou" },

  suffix: getItemSuffixes({ gender: ITEM_GENDER.feminine }),

  sounds: {
    grab: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
    },
    drop: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Drop.id,
    },
  },

  render: {
    image: { src: IMG_default },
    width: 9,
    height: 15,
    offsetY: 3,
    noClickThroughAlpha: true,
  },
};

export default Test_FlaskToxic;
