import {
  ActionQueueType,
  CharacterConfigType,
  ColorRGBA,
  Direction,
  GameAudioType,
  GamePlaySceneFade,
  ImageCompositionLayerType,
  ImageCompositionType,
  ImageType,
  Position,
  SceneType,
  SpriteConfigType,
  TextAlign,
  TextVerticalAlign,
  TranslatedString,
} from ".";

import { CinematicId } from "game-files/ids";

export enum CinematicTypeEnum {
  "scene" = "scene",
  "screen" = "screen",
}

export type CinematicType = CinematicSceneType | CinematicScreenType;

export type CinematicSequenceType = {
  cinematicIds: CinematicId[];
  pauseBetween?: {
    durationSec: number;
  };
};

//
// CINEMATIC SCENE
//
export type CinematicSceneType = {
  id: CinematicId;
  type: CinematicTypeEnum.scene;
  hideGui?: boolean;

  fadeIn?: GamePlaySceneFade;
  fadeOut?: GamePlaySceneFade;

  scene: SceneType;
  uniqueSceneId: string; // necessary for placing items in scene
  audio?: CinematicAudioType;

  characters: {
    config: CharacterConfigType;
    position: Position;
    scale?: number;
    facing?: Direction;
  }[];

  actions: ActionQueueType;
};

export type CinematicAudioType = false | GameAudioType;

//
// CINEMATIC SCREEN
//
export type CinematicScreenType = {
  id: CinematicId;
  type: CinematicTypeEnum.screen;
  size: CinematicScreenSize;
  hideGui?: boolean;

  fadeIn?: GamePlaySceneFade;
  fadeOut?: GamePlaySceneFade;

  background?: CinematicScreenRenderType;
  audio?: CinematicAudioType;

  pauseBefore?: { durationSec: number }; // pause before first text appears
  pauseAfter?: { durationSec: number }; // pause after last text appears

  textDefaults?: Partial<CinematicScreenItem_TextOptions>;

  sequence: CinematicScreenSequenceItem[];
};

export enum CinematicScreenSize {
  "scene" = "scene",
  "viewport" = "viewport",
}

export type CinematicScreenRenderType = {
  position?: Position;

  color?: string;

  image?: ImageType;
  spriteConfig?: SpriteConfigType;

  imageComposition?: ImageCompositionType;
};

//
// SEQUENCE ITEMS
//
export type CinematicScreenSequenceItem =
  | CinematicScreenItem_BackgroundAnimation
  | CinematicScreenItem_AddLayer
  | CinematicScreenItem_Pause;

export type CinematicScreenSequenceItemBase = {
  pauseUntilFinished?: boolean;
  hideAfterFinished?: boolean;
};

//
// SEQUENCE ITEM - TEXT
//
export type CinematicScreenItem_TextOptions = {
  text: TranslatedString;

  fadeIn?: boolean | { durationSec };
  fadeOut?: boolean | { durationSec };
  visibleFor?: { durationSec: number }; // how long is the text visible
  pauseAfter?: { durationSec: number }; // pause after text disappears

  color?: ColorRGBA;
  shadow?: boolean;
  outlined?: boolean;
  opacity?: number;

  position?: Position;
  minWidth?: number;
  maxWidth?: number;
  align?: TextAlign;
  verticalAlign?: TextVerticalAlign;
};

//
// SEQUENCE ITEM - ANIMATION
//
export type CinematicScreenItem_BackgroundAnimationOptions = {
  durationSec: number;
  offsetX?: number;
  offsetY?: number;
};

export type CinematicScreenItem_BackgroundAnimation =
  CinematicScreenSequenceItemBase & {
    animateBackground: CinematicScreenItem_BackgroundAnimationOptions;
  };

//
// SEQUENCE ITEM - PAUSE
//
export type CinematicScreenItem_PauseOptions =
  CinematicScreenSequenceItemBase & {
    durationSec: number;
  };

export type CinematicScreenItem_Pause = CinematicScreenSequenceItemBase & {
  pause: CinematicScreenItem_PauseOptions;
};

//
// SEQUENCE ITEM - ADD IMAGE LAYER
//
export type CinematicScreenItem_AddLayerOptions =
  ImageCompositionLayerType & {};

export type CinematicScreenItem_AddLayer = CinematicScreenSequenceItemBase & {
  addLayer: ImageCompositionLayerType;
};
