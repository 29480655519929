/* eslint-disable react-hooks/exhaustive-deps */

import { ImageType, SpriteConfigType } from "game-engine/types";
import { getImageOffset, getItemDimensions } from "game-engine/utils";

import { Group } from "react-konva";
import { ItemConfigType } from "game-engine/types/item";
import SpriteAnimatedSingle from "game-engine/components/basic-elements/SpriteAnimatedSingle";
import useGame from "game-engine/hooks/useGame";

const ItemImageDefault = (props: {
  config: ItemConfigType;
  onImageLoaded?: () => void;
}) => {
  const { engineConfig, logger } = useGame();
  const { config, onImageLoaded } = props;

  const image: ImageType = props.config.render.image;

  if (!image?.src) {
    logger.error(
      `default item image missing (${props.config.configId})`,
      props.config
    );
    return null;
  }

  const { width, height } = getItemDimensions(config);

  const spriteConfig: SpriteConfigType = {
    frameCount: 1,
    ...(props.config.render.spriteConfig || {}),
    frameWidth: width,
    frameHeight: height,
  };

  //
  // RENDER
  //
  return (
    <Group position={getImageOffset({ width, height })}>
      {/* HANDLE STATIC IMAGES AS SINGLE-FRAME SPRITES FOR UNIFIED APPROACH */}
      <SpriteAnimatedSingle
        src={image.src}
        spriteConfig={spriteConfig}
        onImageLoaded={onImageLoaded}
        onAnimationEnd={undefined}
        controlledAnimation={{ playAnimation: true }}
        renderOutline={engineConfig.state.renderItemOutline}
        renderFill={engineConfig.state.renderItemFill}
        outlineColor={engineConfig.state.itemOutlineColor}
        fillColor={engineConfig.state.itemFillColor}
        loggerString={`item animation (${config.configId})`}
      />
    </Group>
  );
};

export default ItemImageDefault;
