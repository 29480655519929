import {
  CharacterDialogPosition,
  FrameSequenceValue,
  GameConfigType,
  SpriteDirection,
  TextAlign,
} from "game-engine/types";

const canvasX = 340;
const canvasY = 250;

const GAME_CONFIG: GameConfigType = {
  name: { en: "Story of Valentiris", cz: "Příběh o Valentiris" },

  saveFilename: "valentiris-saved-games",
  saveVersion: "1.0",

  render: {
    blur: 0,
  },

  window: {
    canvasDimensions: {
      // canvas (pixel art) dimensions will be scaled to display dimensions
      x: canvasX,
      y: canvasY,
    },
    displayDimensions: {
      x: canvasX * 3,
      y: canvasY * 3, // may not work as expected due to keeping canvas aspect-ratio
    },
  },

  scene: {
    initFadeInDurationSec: 0.5,

    dimensions: {
      x: 320,
      y: 160,
      offsetY: 10, // 'x' is centered, offsetY is for positioning in GUI
    },

    defaultDepthSettings: {
      scaleMin: 0.5,
      scaleMax: 1,
    },

    walkOutAreas: {
      widthInsideGame: 8,
      widthOutsideGame: 20,
    },

    animations: {
      frameDuration: 100, // milliseconds
    },

    idle: {
      idleAfterMinSec: 10,
      idleAfterMaxSec: 30,
    },
  },

  actions: {
    // seconds until (e.g) 'item dropped' labels are reset
    labelOverrideTimeoutSec: 2,

    walkSpeed: {
      // milliseconds per step animation frame
      slow: 60,
      fast: 30,
    },

    talkSpeed: {
      millisecondsBase: 500,
      // milliseconds per character in text added to millisecondsBase
      millisecondsPerCharInText: 70,
      // speed coefficients
      slow: 2,
      medium: 1,
      fast: 0.5,
    },
  },

  sprites: {
    defaultSpriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 100,
      frameHeight: 100,
      frameCount: 1,
      frameDurationMilliseconds: 100,
      frameSequence: [FrameSequenceValue.original],
      originOffset: { x: 0, y: 0 },
      clipOffset: { x: 0, y: 0 },
      playCount: -1, // = infinite loop
    },
  },

  character: {
    dialogConfig: {
      aboveOffsetMax: 70, // measured from origin
      aboveOffsetMin: 50,
      offsetBelowMax: -5,
      paddingX: 5,
      paddingY: 1,
      verticalPosition: CharacterDialogPosition.auto,
      align: TextAlign.center,
      maxWidth: 160,
      color: { r: 255, g: 255, b: 255, a: 255 },
      outlined: true,
    },
    animations: {
      talk: {
        frameDuration: 140, // milliseconds
      },
      idle: {
        idleAfterMinSec: 10, // seconds, min random wait until idle (for randomizer)
        idleAfterMaxSec: 40, // seconds, max random wait until idle (for randomizer)
      },
    },
  },

  skills: {
    resetAfter: {
      sceneChangeCount: 5, // reset after 5 scene changes
      durationSec: 60, // reset after 1 minute
    },
  },

  gui: {
    fadeDurationSec: 0.5,

    label: {
      origin: { x: 14, y: 176 },
    },

    inventory: {
      origin: { x: 100, y: 199 },
      rows: 2,
      cols: 6,
      slotWidth: 18,
      slotHeight: 20,
      gapX: 3,
      gapY: 3,
      fillColor: "#000",
    },

    skillSlots: {
      origin: { x: 260, y: 200 },
      slotWidth: 18,
      slotHeight: 40,
      gap: 4,
    },
  },

  menu: {
    colorMain: "#0F52BA",
    colorHighlight: "#16a5c9",
    colorShadow: "#00115f",
    colorText: { r: 255, g: 255, b: 255, a: 255 },
    colorTextHighlight: { r: 120, g: 245, b: 255, a: 255 },
    colorTextWarning: { r: 255, g: 210, b: 180, a: 255 },
    colorTextError: { r: 255, g: 180, b: 180, a: 255 },
    textOpacityDefault: 0.8,
    textOpacityActive: 1,
  },

  audio: {
    globalVolume: 0.8,
    // sub-volumes:
    backgroundMusicVolume: 1,
    backgroundSoundVolume: 1,
    itemSoundVolume: 0.6,
  },

  cinematics: {
    textSpeed: {
      speed: 1.5,
      millisecondsBase: 500,
      millisecondsPerCharInText: 70, // milliseconds per character in text added to millisecondsBase
    },
    textPauseBefore: {
      durationSec: 0.5,
    },
    textPauseAfter: {
      durationSec: 0.5,
    },
    textPauseBetweenItems: {
      durationSec: 0.2,
    },

    fadeFillColor: "#000",

    fadeTextDurationSec: 0.5,
    fadeSceneDurationSec: 0.5,
    fadeViewportDurationSec: 1,
  },
};

export default GAME_CONFIG;
