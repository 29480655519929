import Box, { BoxMessage } from "game-engine/_dev/basic-components/Box";
import ItemList, {
  ListDividerType,
  ListItemType,
} from "game-engine/_dev/basic-components/ItemList";

import SCENES_LAYOUTS from "game-files/scenes/SCENE_LAYOUTS";
import useGame from "game-engine/hooks/useGame";

const GameItems = (props: { isOpen?: boolean }) => {
  const { gameItems, gamePlay } = useGame();

  const thumbnailSize = 46;

  const sceneIds = SCENES_LAYOUTS.sceneIds;

  //
  // ITEMS IN CURRENT SCENE
  //
  const itemsInCurrentScene = gameItems.state.items.filter(
    (item) =>
      item.inScene?.sceneId === gamePlay.state.currentScene.uniqueSceneId
  );

  //
  // ITEMS IN ALL SCENES
  //
  const itemsInScenes: ListItemType[] = gameItems.state.items.filter(
    (item) => item.inScene && sceneIds.includes(item.inScene.sceneId)
  );

  const itemsInScenesDividers = [];
  const itemsInScenesDivided = [];

  itemsInScenes
    .sort((a, b) => (a?.inScene?.sceneId < b?.inScene?.sceneId ? -1 : 1))
    .forEach((item) => {
      const divider = `Scene Id = '${item.inScene.sceneId}'`;
      if (!itemsInScenesDividers.includes(divider)) {
        itemsInScenesDividers.push(divider);
        itemsInScenesDivided.push({ divider });
      }
      itemsInScenesDivided.push(item);
    });

  //
  // ITEMS IN SCENE OVERLAY
  //
  const itemsInSceneOverlay: ListItemType[] = gameItems.state.items.filter(
    (item) => item?.inSceneOverlay
  );

  //
  // ITEMS IN INVENTORY
  //
  const itemsInInventory: ListItemType[] = gameItems.state.items
    .filter((item) => item?.inInventory)
    .sort((a, b) => (a?.inInventory?.index < b?.inInventory?.index ? -1 : 1));
  //
  // ITEMS IN CURSOR
  //
  const itemsInCursor: ListItemType[] = gameItems.state.items.filter(
    (item) => item?.inCursor
  );

  //
  // ITEMS IN TRASH
  //
  const itemsInTrash: ListItemType[] = gameItems.state.items.filter(
    (item) => item?.inTrash
  );

  //
  // ITEMS THAT ARE NOT ACCESSIBLE
  //
  const unusedItems: ListItemType[] = [];
  gameItems.state.items.forEach((item) => {
    if (item?.inScene && !sceneIds.includes(item.inScene.sceneId)) {
      // item has inScene defined, but the scene doesn't exist
      const messages: BoxMessage[] = [
        {
          text: `Scene "${item.inScene.sceneId}" is not in scenes layout.`,
          type: "warning",
        },
      ];
      unusedItems.push({
        ...item,
        messages,
      });
    }
    if (!item.inScene && !item.inInventory && !item.inCursor && !item.inTrash) {
      const messages: BoxMessage[] = [
        {
          text: `Not in a scene, inventory, cursor, or trash.`,
          type: "warning",
        },
      ];
      unusedItems.push({
        ...item,
        messages,
      });
    }
  });

  //
  // SECTIONS
  //
  const getItemsSection = (options: {
    name: string;
    items: (ListItemType | ListDividerType)[];
  }) => {
    return {
      name: options.name,
      items: options.items,
      count: options.items.filter((obj) => (obj as any).divider === undefined)
        .length,
    };
  };

  //
  // RENDER
  //
  return (
    <Box label="Items" childMinWidth="80%" isOpen={props.isOpen} level={1}>
      {[
        getItemsSection({
          name: "Items in Current Scene",
          items: itemsInCurrentScene,
        }),
        getItemsSection({
          name: "Items in Any Scene",
          items: itemsInScenesDivided,
        }),
        getItemsSection({
          name: "Items in Scene Overlay",
          items: itemsInSceneOverlay,
        }),
        getItemsSection({
          name: "Items in Inventory",
          items: itemsInInventory,
        }),
        getItemsSection({
          name: "Items in Cursor",
          items: itemsInCursor,
        }),
        getItemsSection({
          name: "Items in Trash",
          items: itemsInTrash,
        }),
        getItemsSection({
          name: "Items Without Access",
          items: unusedItems,
        }),
      ].map(({ name, items, count }, i) => (
        <Box
          key={i}
          label={`${name}${count ? ` (${count})` : ""}`}
          useFlex
          gap="10px"
          childMinWidth="60%"
          level={2}
        >
          <ItemList
            items={items}
            thumbnail={{ width: thumbnailSize, height: thumbnailSize }}
            preview={{ width: 75, height: 40, scale: 4 }}
          />
        </Box>
      ))}
    </Box>
  );
};

export default GameItems;
