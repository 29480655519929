import { ActionId, Action_ItemInCursorToTrash } from "game-engine/types";

import { createActionName } from ".";

//
// CREATE BASIC ACTION THAT THROWS ITEM IN CURSOR TO TRASH
//
type DeleteItemProps = {};

type DeleteItemReturn = () => Action_ItemInCursorToTrash;

export const deleteItemInCursor: (
  props?: DeleteItemProps
) => DeleteItemReturn = (/* props */) => () => {
  return {
    _name: createActionName({
      name: `delete item in cursor`,
    }),
    id: ActionId.itemInCursorToTrash,
  };
};
