/* eslint-disable react-hooks/exhaustive-deps */

import {
  ColorRGBA,
  FontType,
  Position,
  TextAlign,
  TextVerticalAlign,
  TranslatedString,
} from "game-engine/types";
import { Group, Rect } from "react-konva";
import {
  PixelColorMapType,
  PixelMatrixType,
  TextPixelData,
  getPixelMatrixDataFromText,
} from "game-engine/utils";

import FontMain from "game-engine/glyphs/FontMain";
import Origin from "../Origin";
import Pixels from "../Pixels";
import useGame from "game-engine/hooks/useGame";
import { useMemo } from "react";

const Text = (props: {
  text: string | TranslatedString;
  textData?: TextPixelData;
  font?: FontType;
  position?: Position;
  offsetX?: number;
  offsetY?: number;
  outlined?: boolean;
  shadow?: boolean;
  opacity?: number;
  minWidth?: number;
  maxWidth?: number;
  align?: TextAlign;
  verticalAlign?: TextVerticalAlign;
  color?: ColorRGBA;
  textFillColor?: string;
  onClick?: () => void;
}) => {
  const { gamePlay, engineConfig, gameFns } = useGame();

  const outlined = props.outlined ?? true;
  const shadow = props.shadow;
  const font = props.font || FontMain;
  const minWidthPx = props.minWidth;
  const maxWidthPx = props.maxWidth;
  const align = props.align || TextAlign.left;

  const text = useMemo(() => {
    return typeof props.text === "object"
      ? gameFns.t(props.text as TranslatedString)
      : props.text || "";
  }, [props.text, gamePlay.state?.settings?.language]);

  //
  // DATA
  //
  const textData = useMemo(() => {
    if (props.textData) {
      return props.textData;
    }
    if (!text?.length) {
      return undefined;
    }
    const data = getPixelMatrixDataFromText({
      font,
      text,
      minWidthPx,
      maxWidthPx,
      outlined,
      shadow,
      align,
    });

    return data;
  }, [
    props.textData,
    font,
    text,
    minWidthPx,
    maxWidthPx,
    outlined,
    shadow,
    align,
  ]);

  const getVerticalOffset = () => {
    if (!textData) {
      return 0;
    }
    switch (props.verticalAlign) {
      case TextVerticalAlign.bottom:
        return -textData.height;
      case TextVerticalAlign.center:
        return Math.round(-textData.height / 2);
      default:
        return 0;
    }
  };

  //
  // COLOR
  //
  const colorMap: PixelColorMapType = useMemo(() => {
    return props.color
      ? {
          "-1": { r: 0, g: 0, b: 0, a: 255 }, // black
          "0": { r: 0, g: 0, b: 0, a: 0 }, // transparent
          "1": props.color, // font color
        }
      : undefined;
  }, [props.color]);

  //
  // RENDER
  //
  return !textData ? null : (
    <Group
      position={{
        x: Math.round(props.position?.x || 0) + (props.offsetX || 0),
        y: Math.round(props.position?.y || 0) + (props.offsetY || 0),
      }}
      onClick={props.onClick}
      opacity={props.opacity}
    >
      <Group position={{ x: 0, y: Math.round(getVerticalOffset()) }}>
        {engineConfig.state.renderTextOrigin && (
          <Origin color={engineConfig.state.textOriginColor} />
        )}

        <Group
          position={{
            x:
              align === TextAlign.center
                ? Math.round(-textData.width / 2)
                : align === TextAlign.right
                ? -textData.width
                : 0,
            y: 0,
          }}
        >
          {(props.textFillColor || engineConfig.state.renderTextFill) && (
            <Rect
              width={textData.width}
              height={textData.height}
              fill={
                engineConfig.state.renderTextFill
                  ? engineConfig.state.textFillColor
                  : props.textFillColor
              }
            />
          )}

          {textData.matrix && (
            <Pixels
              matrix={textData.matrix as PixelMatrixType}
              colorMap={colorMap}
            />
          )}

          {engineConfig.state.renderTextOutline && (
            <Rect
              listening={false}
              width={textData.width + 1}
              height={textData.height + 1}
              stroke={engineConfig.state.textOutlineColor}
              strokeWidth={1}
              position={{ x: -0.5, y: -0.5 }} // fixes stroke blur
            />
          )}
        </Group>
      </Group>
    </Group>
  );
};

export default Text;
