/* eslint-disable react-hooks/exhaustive-deps */

import { BlendingMode, ImageTransitionType, Position } from "game-engine/types";
import SpriteAnimatedSingle, {
  SpriteAnimatedSingleProps,
} from "../SpriteAnimatedSingle";
import { useEffect, useRef, useState } from "react";

import { Group } from "react-konva";
import Transition from "../Transition";
import { TransitionFadeAnimation } from "../Transition/types/fade";

export type SpriteAnimatedProps = SpriteAnimatedSingleProps & {
  position?: Position;
  scale?: { x: number; y: number };
  scaleX?: number;
  scaleY?: number;
  opacity?: number;
  blendingMode?: BlendingMode;
  srcTransitionOnChange?: ImageTransitionType;
};

const SpriteAnimated = (props: SpriteAnimatedProps) => {
  const { position = { x: 0, y: 0 } } = props;

  const [activeImageIndex, setActiveImageIndex] = useState(1);
  const [renderImageIndex, setRenderImageIndex] = useState(1);

  const [imageProps1, setImageProps1] = useState<SpriteAnimatedSingleProps>();
  const [imageProps2, setImageProps2] = useState<SpriteAnimatedSingleProps>();

  const [animatedOpacity1, setAnimatedOpacity1] =
    useState<TransitionFadeAnimation>({ from: 1, to: 1 });
  const [animatedOpacity2, setAnimatedOpacity2] =
    useState<TransitionFadeAnimation>({ from: 0, to: 0 });

  const imageLoadCountRef = useRef(0);

  const onImageLoaded = (imageIndex: number) => {
    imageLoadCountRef.current = imageLoadCountRef.current + 1;
    setRenderImageIndex(imageIndex);
    props.onImageLoaded?.();
  };

  useEffect(() => {
    if (props.src === imageProps1?.src) {
      setImageProps1(props);
      setActiveImageIndex(1);
      return;
    }
    if (props.src === imageProps2?.src) {
      setImageProps2(props);
      setActiveImageIndex(2);
      return;
    }

    if (activeImageIndex === 1) {
      setImageProps2(props);
      setActiveImageIndex(2);
    } else {
      setImageProps1(props);
      setActiveImageIndex(1);
    }
  }, [props]);

  //
  // ANIMATED TRANSITION
  //
  useEffect(() => {
    if (!imageLoadCountRef.current) {
      return;
    }

    const durationSec =
      imageLoadCountRef.current === 1
        ? 0
        : props.srcTransitionOnChange?.durationSec ?? 0;

    if (renderImageIndex === 1 && animatedOpacity1.to !== 1) {
      return setAnimatedOpacity1({
        from: 0,
        to: 1,
        durationSec,
        onEnd: () => {
          setAnimatedOpacity2({ from: 0 });
          setImageProps2(undefined);
        },
      });
    }

    if (renderImageIndex === 2 && animatedOpacity2.to !== 1) {
      return setAnimatedOpacity2({
        from: 0,
        to: 1,
        durationSec,
        onEnd: () => {
          setAnimatedOpacity1({ from: 0 });
          setImageProps1(undefined);
        },
      });
    }
  }, [renderImageIndex]);

  const renderData = [
    {
      renderIndex: 1,
      props: imageProps1,
    },
    {
      renderIndex: 2,
      props: imageProps2,
    },
  ];

  //
  // RENDER
  //
  return (
    <Group
      position={position}
      scale={props.scale}
      scaleX={props.scaleX}
      scaleY={props.scaleY}
      opacity={props.opacity}
      onClick={props.onClick} // handle on click in the wrapper
    >
      {renderData
        .sort((a, b) => (a.renderIndex === renderImageIndex ? 1 : -1))
        .map((data) => {
          if (!data.props) {
            return null;
          }
          return (
            <Transition
              key={data.renderIndex}
              fade={
                data.renderIndex === 1 ? animatedOpacity1 : animatedOpacity2
              }
            >
              {data.props && (
                <SpriteAnimatedSingle
                  {...data.props}
                  key={data.props?.src} // key ensures re-render from scratch
                  onClick={undefined}
                  onImageLoaded={() => onImageLoaded(data.renderIndex)}
                  handleAnimationFinished
                />
              )}
            </Transition>
          );
        })}
    </Group>
  );
};

export default SpriteAnimated;
