/* eslint-disable react-hooks/exhaustive-deps */

import {
  CinematicOnEnd,
  CinematicSceneType,
  CinematicScreenSize,
  CinematicScreenType,
  CinematicType,
  CinematicTypeEnum,
} from "game-engine/types";
import { useEffect, useMemo } from "react";

import { CinematicId } from "game-files/ids";
import CinematicScene from "./types/cinematic-scene";
import CinematicScreen from "./types/cinematic-screen";
import { GUIBlockerProps } from "game-engine/components/game-viewport/GameViewport";
import { StopActionsType } from "../Scene/logic/useSceneActions";
import { getCinematicById } from "game-files/cinematics/CINEMATIC_CONFIGS";
import useGame from "game-engine/hooks/useGame";

const Cinematic = (props: {
  cinematicId: CinematicId;
  onEndSettings?: CinematicOnEnd;
  stopActionsRef?: { current: StopActionsType };
  isVisible?: boolean;
  setGuiBlocker: (data: GUIBlockerProps) => void;
  onGuiBlockerClickRef: any;
  setForceGuiBlockerRef: { current: (b: boolean) => void };
  onCinematicLoaded?: () => void;
}) => {
  const {
    cinematicId,
    onEndSettings,
    stopActionsRef,
    isVisible,
    setGuiBlocker,
    onGuiBlockerClickRef,
    setForceGuiBlockerRef,
    onCinematicLoaded,
  } = props;

  const { gameFns } = useGame();

  const cinematic: CinematicType = useMemo(() => {
    return getCinematicById(cinematicId);
  }, [cinematicId]);

  //
  // HANDLE HIDE GUI
  //
  useEffect(() => {
    if (isVisible && cinematic) {
      const isViewportSize =
        (cinematic as CinematicScreenType)?.size ===
        CinematicScreenSize.viewport;

      gameFns.setHideGui({
        isHidden: isViewportSize || cinematic.hideGui,
        durationSec: isViewportSize ? 0 : undefined, // do not animate hideGui on viewportSize (would be over the screen)
      });
    }
  }, [isVisible, cinematic]);

  //
  // RENDER
  //
  switch (cinematic?.type) {
    case CinematicTypeEnum.scene:
      return (
        <CinematicScene
          key={cinematicId}
          cinematicScene={cinematic as CinematicSceneType}
          onEndSettings={onEndSettings}
          stopActionsRef={stopActionsRef}
          isVisible={isVisible}
          setGuiBlocker={setGuiBlocker}
          onGuiBlockerClickRef={onGuiBlockerClickRef}
          setForceGuiBlockerRef={setForceGuiBlockerRef}
          onCinematicLoaded={onCinematicLoaded}
        />
      );

    case CinematicTypeEnum.screen:
      return (
        <CinematicScreen
          key={cinematicId}
          cinematicScreen={cinematic as CinematicScreenType}
          onEndSettings={onEndSettings}
          isVisible={isVisible}
          setGuiBlocker={setGuiBlocker}
          onCinematicLoaded={onCinematicLoaded}
        />
      );

    default:
      return null;
  }
};

export default Cinematic;
