// enums are necessary due to circular dependencies
// when using cinematicId in actions in other scenes

export enum CharacterId {
  "MainCharacter" = "MainCharacter",
  "TestCharacter" = "TestCharacter",
}

export enum ItemConfigId {
  "Test_Key" = "Test_Key",
  "Test_Flask" = "Test_Flask",
  "Test_FlaskWater" = "Test_FlaskWater",
  "Test_FlaskToxic" = "Test_FlaskToxic",
  "LanternEmpty" = "LanternEmpty",
  "LanternCelestialFlame" = "LanternCelestialFlame",
}

export enum SkillId {
  "Invisibility" = "Invisibility",
  "Healing" = "Healing",
  "Frost" = "Frost",
  "CelestialFlame" = "CelestialFlame",
}

export enum SceneOverlayAnimationId {
  "frost" = "frost",
}

export enum CinematicSequenceId {
  "NewGame" = "NewGame",
}

export enum CinematicId {
  "TestCinematicScene01" = "TestCinematicScene01",
  "TestCinematicScene02" = "TestCinematicScene02",
  "TestCinematicScreen01" = "TestCinematicScreen01",
  "TestCinematicScreen02" = "TestCinematicScreen02",
  "TestCinematicScreen03" = "TestCinematicScreen03",
  "TestCinematicScreen04" = "TestCinematicScreen04",
  "TestCinematicScreen05" = "TestCinematicScreen05",
  "TestCinematicScreen06" = "TestCinematicScreen06",
}

export enum SceneTag {
  "DeathByDarkness" = "DeathByDarkness",
}
