import { ITEM_GENDER, getItemSuffixes } from "game-files/items/_utils";

import IMG_defaultSprite from "./assets/default-sprite.png";
import { ItemConfigId } from "game-files/ids";
import { ItemConfigType } from "game-engine/types/item";
import { SpriteDirection } from "game-engine/types";

const LanternCelestialFlame: ItemConfigType = {
  configId: ItemConfigId.LanternCelestialFlame,
  name: {
    en: "Lantern with Celestial Flame",
    cz: "Lucerna s Nebeským plamenem",
  },

  suffix: getItemSuffixes({ gender: ITEM_GENDER.feminine }),

  // TODO - SOUNDS
  /* sounds: {
    grab: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Grab.id,
    },
    drop: {
      soundConfigId: SOUND_CONFIGS.Test_Vial_Full_Drop.id,
    },
  }, */

  render: {
    image: { src: IMG_defaultSprite },
    width: undefined, //  specified in spriteConfig
    height: undefined, // specified in spriteConfig
    offsetY: 5,
    noClickThroughAlpha: true,

    spriteConfig: {
      direction: SpriteDirection.Horizontal,
      frameWidth: 11,
      frameHeight: 19,
      frameCount: 10,
      frameDurationMilliseconds: 40,
      playCount: undefined,
    },
  },
};

export default LanternCelestialFlame;
