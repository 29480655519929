import {
  GameItemsType,
  ItemConfigType,
  ItemPropInInventory,
  ItemPropInScene,
  ItemSuffixType,
  ItemType,
  SpriteConfigType,
  TranslatedString,
} from "game-engine/types";

import { getItemConfigById } from "game-files/items/ITEM_CONFIGS";
import { getRandomId } from "../by-types";

//
// ITEM ACTION LABELS
//
export const getItemLabel: (props: {
  item: ItemType;
  type: ItemSuffixType;
}) => TranslatedString = (props) => {
  const { item, type } = props;
  const itemConfig = getItemConfigById(item.configId);
  const suffix = itemConfig?.suffix[type];

  const translatedLabel = {};
  Object.entries(itemConfig?.name).forEach(([lang, itemName]) => {
    translatedLabel[lang] = `${itemName} ${suffix[lang]}`;
  });
  return translatedLabel as TranslatedString;
};

export const getItemGrabbedLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.grab,
  });

export const getItemDroppedLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.drop,
  });

export const getItemStoredLabel = (item: ItemType) =>
  getItemLabel({
    item,
    type: ItemSuffixType.store,
  });

//
// GENERATE A NEW ITEM WITH GLOBAL HANDLED INDEX, PUT IT INTO ITEMS ARRAY AND RETURN IT (so it can be put into cursor etc.)
//
export const generateItem: (props: {
  gameItems: {
    state: GameItemsType;
    setState: (
      s: GameItemsType | ((prevState: GameItemsType) => GameItemsType)
    ) => void;
  };
  config: ItemConfigType;
  inInventory?: ItemPropInInventory;
  inScene?: ItemPropInScene;
  inCursor?: boolean;
}) => ItemType = ({ config, gameItems, inInventory, inScene, inCursor }) => {
  const newItem: ItemType = {
    id: `${config.configId}_${getRandomId()}`,
    configId: config.configId,
    inInventory,
    inScene,
    inCursor,
  };

  gameItems.setState((prevState) => ({
    ...prevState,
    items: [...prevState.items, newItem],
  }));

  return newItem;
};

//
// GET ITEM DIMENSIONS
//
export const getItemDimensions = (itemConfig: ItemConfigType) => {
  const spriteConfig: SpriteConfigType = itemConfig.render.spriteConfig;

  const width = spriteConfig?.frameWidth ?? itemConfig.render.width;
  const height = spriteConfig?.frameHeight ?? itemConfig.render.height;

  return { width, height };
};
