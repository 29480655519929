/* eslint-disable react-hooks/exhaustive-deps */

import {
  CharacterConfigType,
  CharacterDevToolsRenderAssetsType,
  CharacterRenderMode,
  Direction,
} from "game-engine/types";
import React, { useEffect, useRef, useState } from "react";
import {
  capitalizeFirstLetter,
  splitByCapitalLetters,
} from "game-engine/utils";
import styled, { css } from "styled-components";

import AssetPreview from "game-engine/_dev/basic-components/AssetPreview";
import Box from "../Box";
import Character from "game-engine/components/game-elements/Character";
import Checkbox from "../inputs/Checkbox";
import GAMEPLAY from "game-files/gamePlay";

const CharacterPreview = styled.div<{ width?: string }>`
  ${(props) => css`
    max-width: ${props.width};
  `}
`;

const CharacterRenderAssets = (props: {
  isOpen?: boolean;
  characterConfig: CharacterConfigType;
  characterRender: CharacterDevToolsRenderAssetsType;
  previewSettings?: {
    width: number;
    height: number;
    scale: number;
  };
}) => {
  const { isOpen, characterConfig, characterRender, previewSettings } = props;

  const { width, height, scale } = previewSettings || {
    width: 100,
    height: 100,
    scale: 4,
  };

  //
  // RENDER SETTINGS
  //
  const [renderSettings, setRenderSettings] = useState<{
    renderMode: CharacterRenderMode;
    direction: Direction;
    walkAnimationFrame: number;
    playAnimation?: {
      id: string;
      onAnimationEnd?: () => void;
    };
  }>({
    renderMode: CharacterRenderMode.default,
    direction: Direction.left,
    walkAnimationFrame: 0,
    playAnimation: undefined,
  });

  const onClickDefault = () => {
    setRenderSettings({
      renderMode: CharacterRenderMode.default,
      direction: Direction.left,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickTalk = () => {
    setRenderSettings({
      renderMode: CharacterRenderMode.talk,
      direction: Direction.left,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickWalk = (direction: Direction) => {
    setRenderSettings({
      renderMode: CharacterRenderMode.walk,
      direction,
      walkAnimationFrame: 0,
      playAnimation: undefined,
    });
  };

  const onClickAnimation = (animationId: string) => {
    setRenderSettings({
      renderMode: CharacterRenderMode.animation,
      direction: Direction.left,
      walkAnimationFrame: 0,
      playAnimation: {
        id: animationId,
        onAnimationEnd: onClickDefault,
      },
    });
  };

  const renderButton = (options: {
    name: string;
    isActive: boolean;
    onClick: () => void;
  }) => {
    return (
      <Checkbox
        label={options.name}
        value={options.isActive}
        // to let animations play until the end (otherwise there are problems)
        isDisabled={renderSettings.renderMode === CharacterRenderMode.animation}
        onChange={(v) => {
          if (v) {
            options.onClick();
          } else {
            onClickDefault();
          }
        }}
      />
    );
  };

  //
  // WALK ANIMATION FRAME
  //
  const timerRef = useRef<any>();
  const animationFrameRef = useRef(0);

  useEffect(() => {
    animationFrameRef.current = 0;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    if (renderSettings.renderMode === CharacterRenderMode.walk) {
      timerRef.current = setInterval(() => {
        animationFrameRef.current = animationFrameRef.current + 1;
        setRenderSettings({
          ...renderSettings,
          walkAnimationFrame: animationFrameRef.current,
        });
      }, GAMEPLAY.settings.walkSpeed);
    }
  }, [renderSettings.renderMode, renderSettings.direction]);

  //
  // RENDER
  //
  return (
    <Box
      label={`Preview - ${characterRender.name}`}
      level={3}
      gap="20px"
      isOpen={isOpen ?? true}
      useFlex
    >
      {/* RENDERED CHARACTER */}
      <CharacterPreview width={`${width * scale}px`}>
        <AssetPreview width={width} height={height} scale={scale}>
          <Character
            config={characterConfig}
            characterRender={characterRender.render}
            position={{
              x: Math.round(width / 2),
              y: Math.round(height * 0.75),
            }}
            facing={renderSettings.direction}
            renderMode={renderSettings.renderMode}
            playAnimation={renderSettings.playAnimation}
            walkAnimationFrame={renderSettings.walkAnimationFrame}
            ignoreIdleAnimations
            renderOrigin
          />
        </AssetPreview>
      </CharacterPreview>

      {/* ANIMATION BUTTONS */}
      <Box
        label="Basic Render Modes"
        level={2}
        isOpen={true}
        childMinWidth="90%"
        minWidth="200px"
        contentPaddingTop="10px"
      >
        {/* DEFAULT */}
        {renderButton({
          name: `Default`,
          isActive: renderSettings.renderMode === CharacterRenderMode.default,
          onClick: onClickDefault,
        })}

        {/* TALK */}
        {renderButton({
          name: `Talk`,
          isActive: renderSettings.renderMode === CharacterRenderMode.talk,
          onClick: onClickTalk,
        })}

        {/* WALK */}
        {[Direction.left, Direction.right, Direction.up, Direction.down].map(
          (direction) => (
            <React.Fragment key={direction}>
              {renderButton({
                name: `Walk ${capitalizeFirstLetter(direction)}`,
                isActive:
                  renderSettings.renderMode === CharacterRenderMode.walk &&
                  renderSettings.direction === direction,
                onClick: () => onClickWalk(direction),
              })}
            </React.Fragment>
          )
        )}
      </Box>

      <Box
        label="Animation Render Modes"
        level={2}
        isOpen={true}
        childMinWidth="90%"
        minWidth="200px"
        contentPaddingTop="10px"
      >
        {/* ANIMATIONS */}
        {Object.values(characterRender?.animationIds || {}).map(
          (animationId) => (
            <React.Fragment key={animationId}>
              {renderButton({
                name: `Animation ${splitByCapitalLetters(
                  capitalizeFirstLetter(animationId),
                  " - "
                )}`,
                isActive:
                  renderSettings.renderMode === CharacterRenderMode.animation &&
                  renderSettings.playAnimation?.id === animationId,
                onClick: () => onClickAnimation(animationId),
              })}
            </React.Fragment>
          )
        )}
      </Box>
    </Box>
  );
};

export default CharacterRenderAssets;
