/* eslint-disable react-hooks/exhaustive-deps */

import { useCallback, useEffect, useRef, useState } from "react";

import Konva from "konva";

export type ChangeImageColorType = {
  colorBlack: [number, number, number];
  colorWhite: [number, number, number];
};

// Define the custom filter with dynamic colors
(Konva.Filters as any).ColorizeGradient = function (imageData) {
  const { data } = imageData;
  const [rBlack, gBlack, bBlack] = this.colorBlack || [0, 0, 0];
  const [rWhite, gWhite, bWhite] = this.colorWhite || [255, 255, 255]; // Use white default if not set

  for (let i = 0; i < data.length; i += 4) {
    // Calculate grayscale value (0-255)
    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;

    // Normalize grayscale value to the range 0-1
    const ratio = avg / 255; // 0 for black, 1 for white

    // Interpolate between colorBlack and colorWhite
    data[i] = rBlack * (1 - ratio) + rWhite * ratio; // Red
    data[i + 1] = gBlack * (1 - ratio) + gWhite * ratio; // Green
    data[i + 2] = bBlack * (1 - ratio) + bWhite * ratio; // Blue
  }

  return imageData;
};

const useLocalImage = (props: {
  src: any;
  isDevMode?: boolean;
  clickThroughAlpha?: boolean;
  imageRef?: { current: Konva.Image };
  changeColor?: ChangeImageColorType;
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const imageRef = useRef<any>();
  const [imageData, setImageData] = useState<{
    width: number;
    height: number;
  }>();

  // Store the actual image instance in a ref for access outside of the hook
  const imgRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    // Reset image loaded state
    setImageLoaded(false);

    // Create a new Image instance
    const newImage = new window.Image();
    newImage.src = props.src;

    // Store the image in a ref for external access
    imgRef.current = newImage;

    const handleLoad = () => {
      setImageLoaded(true);
    };

    const handleError = (e) => {
      console.error("Image load error", props.src, e);
    };

    newImage.onload = handleLoad;
    newImage.onerror = handleError;

    // Cleanup function to prevent memory leaks
    return () => {
      newImage.onload = null; // Clear the onload handler
      newImage.onerror = null; // Clear the onerror handler
      imgRef.current = null; // Clear the image reference
    };
  }, [props.src]); // Dependency on props.src

  const getImageRef = () => {
    if (props.imageRef !== undefined) {
      return props.imageRef;
    }
    return imageRef;
  };

  const drawHitFromCache = useCallback(
    (img: Konva.Image) => {
      if (img && imageLoaded) {
        const alphaThreshold = 0;
        img.cache();
        img.drawHitFromCache(alphaThreshold);

        setImageData({
          width: img.width(),
          height: img.height(),
        });
      }
    },
    [imageLoaded]
  );

  useEffect(() => {
    if (props.clickThroughAlpha && imageLoaded && getImageRef()?.current) {
      drawHitFromCache(getImageRef().current);
    }
  }, [imageLoaded, props.isDevMode]);

  // New useEffect to apply color filter
  useEffect(() => {
    const imageNode = imageRef.current;

    if (imageNode) {
      if (props.changeColor) {
        // Apply the color filter
        imageNode.cache();
        imageNode.filters([(Konva.Filters as any).ColorizeGradient]);
        imageNode.colorBlack = props.changeColor.colorBlack;
        imageNode.colorWhite = props.changeColor.colorWhite;
      } else {
        // Reset the filter and color properties
        imageNode.filters([]); // Remove all filters
      }

      imageNode.getLayer()?.batchDraw();
    }
  }, [imageLoaded, props.changeColor]);

  return {
    image: imgRef.current,
    imageLoaded,
    imageRef: getImageRef(),
    imageData,
  };
};

export default useLocalImage;
