import { SceneType, SpriteDirection } from "game-engine/types";

import { ACTION } from "game-engine/utils";
import GAME_CONFIG from "game-files/gameConfig";
import { GameEvent } from "game-files/gameEvents";
import { GameObjective } from "game-files/gameObjectives";
import IMAGE_Layer_00_DEPTH_MAP from "./assets/layer - 00 - DEPTH MAP_00.png";
import IMAGE_Layer_00_DROP_MAP from "./assets/layer - 00 - DROP MAP_00.png";
import IMAGE_Layer_00_WALK_MAP from "./assets/layer - 00 - WALK MAP_00.png";
import IMAGE_Layer_01_Background_FireToIce_Sprite from "./assets/layer - 01 - background - fire-to-ice - sprite.png";
import IMAGE_Layer_01_Background_Fire_Sprite from "./assets/layer - 01 - background - fire - sprite.png";
import IMAGE_Layer_01_Background_Ice from "./assets/layer - 01 - background - ice_00.png";
import IMAGE_Layer_02_Bush_FireToIce_Sprite from "./assets/layer - 02 - bush - fire-to-ice - sprite.png";
import IMAGE_Layer_02_Bush_Fire_Sprite from "./assets/layer - 02 - bush - fire - sprite.png";
import IMAGE_Layer_02_Bush_Ice from "./assets/layer - 02 - bush - ice_00.png";
import IMAGE_Layer_03_Foreground_FireToIce_Sprite from "./assets/layer - 03 - foreground - fire-to-ice - sprite.png";
import IMAGE_Layer_03_Foreground_Fire_Sprite from "./assets/layer - 03 - foreground - fire - sprite.png";
import IMAGE_Layer_03_Foreground_Ice from "./assets/layer - 03 - foreground - ice_00.png";
import IMAGE_Preview from "./assets/preview - fire_00.png";
import MUSIC_CONFIGS from "game-files/audio/MUSIC_CONFIGS";
import SHARED_DATA from "game-files/scenes/SHARED_DATA";
import SOUND_CONFIGS from "game-files/audio/SOUND_CONFIGS";
import { SkillId } from "game-files/ids";

//import IMAGE_Layer_03_Foreground_FireToIce_Sprite from "./assets/layer - 03 - foreground - fire-to-ice - sprite.png";

//import IMAGE_Layer_03_Foreground_Ice from "./assets/layer - 03 - foreground - ice.png";

const DevScene07: SceneType = {
  configId: "DevScene07", // --------- KEEP SAME AS COMPONENT NAME
  isDev: false, // ----------------- DEV SCENES ARE HIDDEN IN SCENES LAYOUT EDITOR
  isUsingTestObjectives: false, // - OBJECTIVES WILL BE DISPLAYED UNDER TEST NAMES IN DEV TOOLS
  sceneNeighbors: undefined, // ---- GENERATED FROM SCENES LAYOUT
  uniqueId: "DevScene07", // ---------- GENERATED FROM SCENES LAYOUT (put manual id here for scenes out of scenes layout)
  preview: { src: IMAGE_Preview },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE NAME
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  name: {
    [GameObjective.GAME_START]: {
      en: "Freezing Skill - freeze the fire",
      cz: undefined,
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE AUDIO
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  audio: {
    [GameObjective.GAME_START]: {
      music: {
        musicConfigId: MUSIC_CONFIGS.Test_01.id,
        volume: undefined,
      },
      sound: {
        soundConfigId: SOUND_CONFIGS.Fire.id,
        volume: undefined,
        loop: true,
      },

      conditional: ({ events }) => [
        {
          condition: events.test_burningBush > 0,
          state: {
            sound: {
              soundConfigId: null,
            },
          },
        },
      ],
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE WALK PATHS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  sceneWalkPaths: {
    [GameObjective.GAME_START]: {
      left: undefined,
      right: undefined,
      up: undefined,
      down: {
        edgeWalkX: 203,
        walkTo: { x: 203, y: 128 },
      },
    },
  },

  images: {
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE BACKGROUND
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    background: {
      dataByCurrentObjective: {
        [GameObjective.GAME_START]: {
          fillColor: undefined,
          image: {
            src: IMAGE_Layer_01_Background_Fire_Sprite,
            transition: undefined,
          },
          imageSpriteConfig: {
            direction: SpriteDirection.Vertical,
            frameWidth: 320,
            frameHeight: 160,
            frameCount: 7,
            frameDurationMilliseconds: 40,
          },
          dropMap: { src: IMAGE_Layer_00_DROP_MAP },
          walkMap: { src: IMAGE_Layer_00_WALK_MAP },
          depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
          depthSettings: {
            scaleMin: undefined,
            scaleMax: undefined,
          },

          // EXAMPLE OF CONDITIONAL SCENE BACKGROUND
          conditional: ({ events }) => [
            {
              condition: events.test_burningBush === 1,
              state: {
                image: { src: IMAGE_Layer_01_Background_FireToIce_Sprite },
                imageSpriteConfig: {
                  direction: SpriteDirection.Vertical,
                  frameWidth: 320,
                  frameHeight: 160,
                  frameCount: 20,
                  frameDurationMilliseconds: 50,
                  playCount: 1,
                },

                dropMap: { src: IMAGE_Layer_00_DROP_MAP },
                walkMap: { src: IMAGE_Layer_00_WALK_MAP },
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
              },
            },

            {
              condition: events.test_burningBush > 1,
              state: {
                image: { src: IMAGE_Layer_01_Background_Ice },

                dropMap: { src: IMAGE_Layer_00_DROP_MAP },
                walkMap: { src: IMAGE_Layer_00_WALK_MAP },
                depthMap: { src: IMAGE_Layer_00_DEPTH_MAP },
              },
            },
          ],
        },
      },

      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsWithItem: undefined,
          actions: [
            ACTION.talkRandom({
              dialogOptions: [
                ...SHARED_DATA.dialogOptions.obstacleNoSceneWalkOut,
                ...SHARED_DATA.dialogOptions.notGoingToWalk,
                ...SHARED_DATA.dialogOptions.rocksAndBushes,
              ],
            }),
          ],
        },
      },
    },

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //
    // SCENE LAYERS
    //
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    layers: [
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_02_Bush_Fire_Sprite",
        depthY: 90,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_02_Bush_Fire_Sprite },
            spriteConfig: {
              direction: SpriteDirection.Vertical,
              frameWidth: 46,
              frameHeight: 38,
              frameCount: 20,
              frameDurationMilliseconds: 40,
              frameSequence: undefined,
              originOffset: { x: 148, y: 61 },
              playCount: undefined,
            },
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,

            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
              {
                condition: events.test_burningBush === 1,
                state: {
                  image: {
                    src: IMAGE_Layer_02_Bush_FireToIce_Sprite,
                  },
                  spriteConfig: {
                    direction: SpriteDirection.Vertical,
                    frameWidth: 46,
                    frameHeight: 38,
                    frameCount: 30,
                    frameDurationMilliseconds: 40,
                    originOffset: { x: 148, y: 61 },
                    playCount: 1,
                  },
                },
              },
              {
                condition: events.test_burningBush > 1,
                state: {
                  image: {
                    src: IMAGE_Layer_02_Bush_Ice,
                  },
                },
              },
            ],
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.conditional(({ gamePlay, gameItems }) => [
                {
                  condition: gamePlay.events.test_burningBush === 0,
                  actions: [
                    ACTION.talk({
                      dialog: {
                        en: `Well that certainly doesn't seem right.`,
                        cz: `Hmm, oheň se sem moc nehodí.`,
                      },
                    }),
                  ],
                },
                {
                  condition: gamePlay.events.test_burningBush > 1,
                  actions: [
                    ACTION.talk({
                      dialog: {
                        en: `Not ideal, but better than an open fire I guess...`,
                        cz: `Není to ideální, ale asi lepší než otevřený oheň...`,
                      },
                    }),
                  ],
                },
              ]),
            ],
          },
        },
      },

      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      //
      // Scene Layer
      //
      /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

      {
        id: "IMAGE_Layer_03_Foreground_Fire_Sprite",
        depthY: 1000,
        dataByCurrentObjective: {
          [GameObjective.GAME_START]: {
            image: { src: IMAGE_Layer_03_Foreground_Fire_Sprite },
            spriteConfig: {
              direction: SpriteDirection.Vertical,
              frameWidth: 320,
              frameHeight: 160,
              frameCount: 7,
              frameDurationMilliseconds: 30,
              frameSequence: undefined,
              originOffset: { x: 0, y: 0 },
              playCount: undefined,
            },
            ignoreOnClick: false,
            isInvisible: false,
            cursorOnHover: undefined,
            offset: undefined,

            // EXAMPLE OF CONDITIONAL SCENE LAYER IMAGE
            conditional: ({ events }) => [
              {
                condition: events.test_burningBush === 1,
                state: {
                  image: {
                    src: IMAGE_Layer_03_Foreground_FireToIce_Sprite,
                  },
                  spriteConfig: {
                    direction: SpriteDirection.Vertical,
                    frameWidth: 320,
                    frameHeight: 160,
                    frameCount: 20,
                    frameDurationMilliseconds: 40,
                    playCount: 1,
                  },
                },
              },
              {
                condition: events.test_burningBush > 1,
                state: {
                  image: {
                    src: IMAGE_Layer_03_Foreground_Ice,
                  },
                },
              },
            ],
          },
        },
        actionsByCurrentObjective: {
          [GameObjective.GAME_START]: {
            actionsWithItem: undefined,
            actions: [
              ACTION.talkRandom({
                dialogOptions: [
                  ...SHARED_DATA.dialogOptions.tree,
                  ...SHARED_DATA.dialogOptions.forest,
                ],
              }),
            ],
          },
        },
      },
    ],
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE ACTIONS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  onSceneIdle: {
    // idleAfterMinSec: 1,
    // idleAfterMaxSec: 2,
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // IDLE ACTIONS ARE NOT EXECUTED AS A SEQUENCE, BUT PLACED INTO A POOL OF
        // ACTIONS TO BE RANDOMLY PICKED FROM AMONG OTHER IDLES (CHARACTER IDLE ETC.)
        actions: [],
      },
    },
  },

  onSceneInit: {
    actionsByCurrentObjective: {
      [GameObjective.GAME_START]: {
        // ACTIONS TO BE RUN IN SEQUENCE AFTER SCENE IS INITIALIZED
        actions: [],
      },
    },
  },

  onSkillStart: {
    [SkillId.Frost]: {
      actionsByCurrentObjective: {
        [GameObjective.GAME_START]: {
          actionsBeforeSkill: [
            ACTION.face({ facePosition: { x: 171, y: 87 } }),
          ],
          actions: [
            ACTION.conditional(({ gamePlay, gameItems }) => [
              {
                condition: gamePlay.events.test_burningBush === 0,
                actions: [
                  ACTION.playMusic({
                    musicConfigId: MUSIC_CONFIGS.Test_Fanfare.id,
                    volume: 0.6,
                  }),

                  ACTION.playSound({
                    soundConfigId: SOUND_CONFIGS.TreeHealing.id,
                    volume: 0.6,
                  }),

                  ACTION.setEvent({
                    key: GameEvent.test_burningBush,
                    value: 1,
                  }),

                  ACTION.pause({ durationSec: 2 }),

                  ACTION.talkRandom({
                    dialogOptions: [
                      {
                        en: `Well, there goes my chance to roast sausages.`,
                        cz: `No, teď už si buřty neopeču.`,
                      },
                    ],
                  }),

                  ACTION.setEvent({
                    key: GameEvent.test_burningBush,
                    value: 2,
                  }),
                ],
              },
            ]),
          ],
        },
      },
    },
  },

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  //
  // SCENE CHARACTERS
  //
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  characters: [],
};

export default DevScene07;
