/* eslint-disable react-hooks/exhaustive-deps */

import { ReactNode, useEffect, useState } from "react";
import styled, { css } from "styled-components";

import AnimateHeight from "react-animate-height";
import { ReactComponent as TrashIcon } from "assets/icons/trash.svg";
import devToolsConfig from "game-engine/configs/devtools-config";

const NoBoxWrapper = styled.div<{
  minWidth?: string;
}>`
  position: relative;
  font-family: "Arial";
  display: flex;

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth} !important;
    `}
`;

const BoxWrapper = styled.div<{
  minWidth?: string;
  level?: number;
  isOpen?: boolean;
  backgroundColor?: string;
  borderColor?: string;
}>`
  --edgeColor: ${(props) => props.borderColor || devToolsConfig.boxBorderColor};
  --edgeBumpHeight: 6px;

  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
  border: 1px solid var(--edgeColor);
  border-top: 2px solid var(--edgeColor);
  margin-top: var(--edgeBumpHeight);

  font-family: "Arial";

  &:after {
    content: "";
    position: absolute;
    background: var(--edgeColor);
    transition: all 0.2s ease;

    left: -1px;
    bottom: 100%;
    height: var(--edgeBumpHeight);
    width: 60px;
    border-top-right-radius: var(--edgeBumpHeight);

    ${(props) =>
      props.isOpen &&
      css`
        border-top-right-radius: 0px;
        width: calc(100% + 2px);
      `}
  }

  ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth} !important;
    `}

  ${(props) =>
    props.level === 1 || !props.level
      ? css`
          background: ${devToolsConfig.background};
          padding-left: 0;

          --edgeColor: #666;
          border-left: none;
          border-right: none;
          border-bottom: none;
        `
      : props.level === 2
      ? css`
          background: #0001;
          //gap: 10px;
        `
      : props.level === 3 &&
        css`
          background: #0001;
          //gap: 10px;
        `}

  ${(props) =>
    props.backgroundColor &&
    css`
      background: ${props.backgroundColor};
    `}
`;

const Title = styled.div<{
  level: number;
  isOpen: boolean;
  background: string;
}>`
  user-select: none;

  background: ${(props) => props.background || "none"};

  ${(props) =>
    props.level === 1 || !props.level
      ? css`
          font-size: 14px;
          text-transform: uppercase;
          color: #fff8;
          margin: 6px 0;
        `
      : props.level === 2
      ? css`
          font-size: 13px;
          color: #fff8;
        `
      : props.level === 3 &&
        css`
          font-size: 12px;
          color: #fff5;
        `}

  ${(props) =>
    !props.isOpen &&
    css`
      //background: #111;
    `}
`;

const Content = styled.div<{
  isOpen: boolean;
  childMinWidth?: string;
  useFlex?: boolean;
  gap?: string;
  contentPaddingTop?: string;
  contentPaddingBottom?: string;
  contentPaddingLeft?: string;
  contentPaddingRight?: string;
  level: number;
}>`
  gap: ${(props) => props.gap || "6px"};

  margin-top: ${(props) =>
    props.contentPaddingTop || "0px"}; // margin to allow negative values

  margin-bottom: ${(props) =>
    props.contentPaddingBottom ||
    (props.level === 1 ? "20px" : "10px")}; // margin to allow negative values

  margin-left: ${(props) =>
    props.contentPaddingLeft || "0px"}; // margin to allow negative values

  margin-right: ${(props) =>
    props.contentPaddingRight || "0px"}; // margin to allow negative values

  flex: 1;

  ${(props) =>
    !props.isOpen &&
    css`
      overflow: hidden;
    `}

  ${(props) =>
    props.useFlex
      ? css`
          display: flex;
          flex-wrap: wrap;
          justify-content: stretch;

          & > * {
            flex: 1;
            min-width: ${props.childMinWidth || "auto"};
          }
        `
      : css`
          display: grid;
          grid-template-columns: repeat(
            auto-fit,
            minmax(${props.childMinWidth ? props.childMinWidth : "80px"}, 1fr)
          );
        `}
`;

const TitleWrapper = styled.div<{ keepClosed: boolean }>`
  cursor: ${(props) => (props.keepClosed ? "default" : "pointer")};
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  word-break: break-all;
`;

const Messages = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const Message = styled.div<{ color: string }>`
  color: ${(props) => props.color};
  font-size: 12px;
  font-weight: 600;
`;

const TrashIconWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  flex: 1;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0px;

  svg {
    height: 16px;
    fill: #fff4;
  }

  &:hover {
    svg {
      height: 16px;
      fill: ${devToolsConfig.warning};
    }
  }
`;

const AnimateHeightWrapper = styled.div<{
  contentCenteredVertical?: boolean;
}>`
  ${(props) =>
    props.contentCenteredVertical &&
    css`
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `}
`;

export type BoxMessage = { text: string; type?: "warning" | "info" };

export type BoxProps = {
  label?: any;
  level?: number;
  useFlex?: boolean;
  gap?: string;
  minWidth?: string;
  childMinWidth?: string;
  children?: any;
  isOpen?: boolean;
  keepClosed?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  contentPaddingTop?: string;
  contentPaddingBottom?: string;
  contentPaddingLeft?: string;
  contentPaddingRight?: string;
  contentCenteredVertical?: boolean;
  messages?: BoxMessage[];
  messagesOnlyWhenClosed?: boolean;
  backgroundColor?: string;
  borderColor?: string;
  headerBackground?: string;
  renderComponentUnderHeader?: () => ReactNode;
  renderContentOnly?: boolean;
  trashIcon?: {
    onClick: () => void;
  };
};

const Box = (props: BoxProps) => {
  const [isOpen, setIsOpen] = useState(props.keepClosed ? false : props.isOpen);
  const renderContentOnly = props.renderContentOnly;

  useEffect(() => {
    setIsOpen(props.keepClosed ? false : props.isOpen);
  }, [props.isOpen]);

  useEffect(() => {
    if (props.onOpenChange) {
      props.onOpenChange(isOpen);
    }
  }, [isOpen]);

  const hasMessages = props.messages?.length > 0;

  const showMessages =
    hasMessages && (isOpen ? !props.messagesOnlyWhenClosed : true);

  const renderContent = () => {
    const paddingTop =
      renderContentOnly && props.contentPaddingTop === undefined
        ? "0px"
        : props.contentPaddingTop;

    const paddingBottom =
      renderContentOnly && props.contentPaddingBottom === undefined
        ? "0px"
        : props.contentPaddingBottom;

    const paddingLeft =
      renderContentOnly && props.contentPaddingLeft === undefined
        ? "0px"
        : props.contentPaddingLeft;

    const paddingRight =
      renderContentOnly && props.contentPaddingRight === undefined
        ? "0px"
        : props.contentPaddingRight;

    return (
      <Content
        isOpen={isOpen}
        level={props.level}
        childMinWidth={props.childMinWidth}
        useFlex={props.useFlex}
        gap={props.gap}
        contentPaddingTop={paddingTop}
        contentPaddingBottom={paddingBottom}
        contentPaddingLeft={paddingLeft}
        contentPaddingRight={paddingRight}
      >
        {props.children}
      </Content>
    );
  };

  //
  // RENDER
  //
  return renderContentOnly ? (
    <NoBoxWrapper minWidth={props.minWidth}>{renderContent()}</NoBoxWrapper>
  ) : (
    <BoxWrapper
      minWidth={props.minWidth}
      level={props.level}
      isOpen={isOpen}
      backgroundColor={props.backgroundColor}
      borderColor={props.borderColor}
    >
      <TitleWrapper
        keepClosed={props.keepClosed}
        onClick={() => setIsOpen(props.keepClosed ? false : !isOpen)}
      >
        {props.label && (
          <Title
            level={props.level}
            isOpen={isOpen}
            background={props.headerBackground}
          >
            {props.label}
          </Title>
        )}

        {showMessages && (
          <Messages>
            {props.messages.map((warning, i) => {
              let color = "#fff6";
              switch (warning.type) {
                case "warning":
                  color = "#ff6b6b99";
                  break;
              }

              return (
                <Message key={i} color={color}>
                  {warning.text}
                </Message>
              );
            })}
          </Messages>
        )}

        {props.trashIcon && (
          <TrashIconWrapper onClick={props.trashIcon.onClick}>
            <TrashIcon />
          </TrashIconWrapper>
        )}
      </TitleWrapper>

      {props.renderComponentUnderHeader
        ? props.renderComponentUnderHeader()
        : null}

      <AnimateHeightWrapper
        contentCenteredVertical={props.contentCenteredVertical}
      >
        <AnimateHeight duration={200} height={isOpen ? "auto" : 0}>
          {isOpen && renderContent()}
        </AnimateHeight>
      </AnimateHeightWrapper>
    </BoxWrapper>
  );
};

export default Box;
