import {
  ActionQueueType,
  ActionsByCurrentObjectiveType,
  ColorRGBA,
  Direction,
  ImageType,
  Position,
  SceneCharacterDataType,
  SpriteConfigType,
  StateConditionalData,
  StateConditionalProps,
  TextAlign,
  TranslatedString,
} from ".";

import { CharacterId } from "game-files/ids";
import { CursorRenderType } from "./cursor";
import { GameObjective } from "game-files/gameObjectives";

//
// CHARACTER
//
export type CharacterConfigType = {
  id: CharacterId;
  name: TranslatedString; // TYPICALLY NOT USED IN GAME, NO NEED TO BE CONDITIONAL
  cursorOnHover?: CursorRenderType; // TODO

  dialogConfig?: CharacterDialogConfigByCurrentObjective;

  idleConfig?: CharacterIdleConfigType; // TODO - BY OBJECTIVE

  render: CharacterRenderByCurrentObjective;

  onSceneIdle?: {
    actionsByCurrentObjective?: {
      [key in GameObjective]?: {
        actions?: ActionQueueType;
      };
    };
  };

  onClick?: {
    actionsByCurrentObjective?: ActionsByCurrentObjectiveType;
  };
};

//
// CHARACTER - RENDER MODE
//
export enum CharacterRenderMode {
  "default" = "default",
  "walk" = "walk",
  "talk" = "talk",
  "talkIdle" = "talkIdle",
  "idle" = "idle",
  "animation" = "animation",
}

//
// CHARACTER - RENDER
//
export type CharacterRenderType = {
  facing?: Direction.left | Direction.right;

  offsetX?: number;
  offsetY?: number; // offset from origin (to GLOBALLY position feet on the origin)

  default?: CharacterRenderDefaultType;

  talk?: CharacterRenderTalkType;

  walkLeft?: CharacterRenderWalkType;
  walkUp?: CharacterRenderWalkType;
  walkDown?: CharacterRenderWalkType;

  animations?: CharacterRenderAnimationType[];
};

export type CharacterRenderConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: CharacterRenderType })[];

export type CharacterRenderByCurrentObjective = {
  [key in GameObjective]?: CharacterRenderType & {
    conditional?: CharacterRenderConditionalFunc;
  };
};

//
// RENDER - DEFAULT
//
export type CharacterRenderDefaultType = {
  image?: ImageType;
  width?: number;
  height?: number;
  offsetX?: number;
  offsetY?: number; // offset from origin (to position feet on the origin)
  sprite?: ImageType;
  spriteConfig?: SpriteConfigType;
};

//
// RENDER - TALK
//
export type CharacterRenderTalkType = {
  offsetX?: number;
  offsetY?: number; // offset from origin (to position feet on the origin)
  headOffsetX?: number; // offset to position head to bodyImage
  headOffsetY?: number; // offset to position head to bodyImage
  bodyImage?: ImageType;
  headSprite?: ImageType;
  headSpriteConfig?: SpriteConfigType;
};

//
// RENDER - WALK
//
export type CharacterRenderWalkType = {
  offsetX?: number;
  offsetY?: number; // offset from origin (to position feet on the origin)
  sprite?: ImageType;
  spriteConfig?: SpriteConfigType;
  pixelsWalked: number;
};

//
// RENDER - ANIMATION
//
export type CharacterRenderAnimationType = {
  animationId: string;
  isIdle?: boolean;
  offsetX?: number; // offset from origin (to position feet on the origin)
  offsetY?: number; // offset from origin (to position feet on the origin)
  sprite: ImageType;
  spriteConfig: SpriteConfigType;
};

//
// CHARACTER IDLE CONFIG
//
export type CharacterIdleConfigType = {
  idleAfterMinSec?: number;
  idleAfterMaxSec?: number;
};

//
// CHARACTER DIALOG OPTIONS
//
export enum CharacterDialogPosition {
  above = "above",
  below = "below",
  auto = "auto",
}

export type CharacterDialogConfigType = {
  aboveOffsetMax?: number;
  aboveOffsetMin?: number;
  offsetBelowMax?: number;
  verticalPosition?: CharacterDialogPosition;
  align?: TextAlign;
  maxWidth?: number;
  color?: ColorRGBA;
  outlined?: boolean;
};

export type CharacterDialogConfigConditionalFunc = (
  props: StateConditionalProps
) => (StateConditionalData & { state: CharacterDialogConfigType })[];

export type CharacterDialogConfigByCurrentObjective = {
  [key in GameObjective]?: CharacterDialogConfigType & {
    conditional?: CharacterDialogConfigConditionalFunc;
  };
};

//
// CHARACTER - ACTION - TALK
//
export type CharacterDialogType = TranslatedString | TranslatedString[]; // array is meant for multi-text dialog (<say something 1> -> <say something 2>)

//
// CHARACTER - ACTION - WALK
//
export type CharacterPathPoint = {
  position: Position;
  facing: Direction;
  scale: number;
};
export type CharacterWalkPath = CharacterPathPoint[];

export type WalkConfigType = {
  horizontal: {
    frameCount: number;
    pixelsWalked: number;
  };
  vertical: {
    frameCount: number;
    pixelsWalked: number;
  };
};

//
// DEVTOOLS PREVIEWS
//
export type CharacterDevToolsPreviewType = {
  config: CharacterConfigType;
  renderAssets: CharacterDevToolsRenderAssetsType[];
  sceneCharacter?: SceneCharacterDataType;
};

export type CharacterDevToolsRenderAssetsType = {
  name: string;
  render: CharacterRenderType;
  animationIds: { [key: string]: string };
};
