import { ColorRGBA, Position } from ".";

import { ItemType } from "./item";
import { PixelMatrixType } from "game-engine/utils";

export type CursorGlyphType = {
  pixels: PixelMatrixType;
  color?: ColorRGBA;
  outlined?: any;
  outlineNoDiagonal?: boolean;
  outlineColor?: ColorRGBA;
  offsetY?: number;
  offsetX?: number;
};

export enum CursorRenderType {
  default = "default",
  arrowLeft = "arrowLeft",
  arrowRight = "arrowRight",
  arrowUp = "arrowUp",
  arrowDown = "arrowDown",
  notAllowed = "notAllowed",
}

export type CursorGlyphsType = {
  [key in CursorRenderType]: CursorGlyphType;
};

export type CursorConfigType = {
  renderMode: CursorRenderType;
  isHidden: boolean;
  itemInCursor?: ItemType;
  dropAnimation?: {
    itemId: string;
    from: Position;
  };
};
