import {
  CharacterId,
  CinematicId,
  ItemConfigId,
  SceneOverlayAnimationId,
  SkillId,
} from "game-files/ids";
import {
  CinematicOnEnd,
  Direction,
  GameEffectFadeScene,
  GameEffectFadeViewport,
  GameEffectShakeViewport,
  GameItemsType,
  GamePlayAudioType,
  GamePlayCurrentSceneType,
  GamePlayType,
  ItemPropInSceneOverlay,
  Position,
  TranslatedString,
} from ".";
import { GameEvent, GameEventsType } from "game-files/gameEvents";

import { CharacterWalkPath } from "./character";
import { GameObjective } from "game-files/gameObjectives";

//
// ACTIONS
//
export type ActionType =
  | Action_Sequence
  | Action_Conditional
  | Action_CharacterWalk
  | Action_CharacterTalk
  | Action_CharacterFace
  | Action_CharacterDeath
  | Action_CharacterAnimation
  | Action_Pause
  | Action_Execute
  | Action_ItemInCursorSwapConfig
  | Action_ItemInCursorToTrash
  | Action_ItemInCursorToSceneOverlay
  | Action_PutNewItemIntoCursor
  | Action_SaveGame
  | Action_Audio
  | Action_Sound
  | Action_Scene
  | Action_SceneOverlayAnimation
  | Action_Objective
  | Action_Event
  | Action_Effect
  | Action_Cinematic
  | Action_Skill;

export enum ActionId {
  "sequence" = "sequence",
  "random" = "random",
  "conditional" = "conditional",
  "execute" = "execute",
  "audio" = "audio",
  "sound" = "sound",
  "characterWalk" = "characterWalk",
  "characterTalk" = "characterTalk",
  "characterFace" = "characterFace",
  "characterDeath" = "characterDeath",
  "characterAnimation" = "characterAnimation",
  "pause" = "pause",
  "itemInCursorSwapConfig" = "itemInCursorSwapConfig",
  "itemInCursorToTrash" = "itemInCursorToTrash",
  "itemInCursorToSceneOverlay" = "itemInCursorToSceneOverlay",
  "putNewItemIntoCursor" = "putNewItemIntoCursor",
  "saveGame" = "saveGame",
  "scene" = "scene",
  "sceneOverlayAnimation" = "sceneOverlayAnimation",
  "objective" = "objective",
  "event" = "event",
  "effect" = "effect",
  "cinematic" = "cinematic",
  "skill" = "skill",
}

//
// ACTION QUEUE
//
export type ActionQueueType = (() => ActionType | ActionType[])[];

export type ActionsByCurrentObjectiveType = {
  [key in GameObjective]?: {
    actions?: ActionQueueType;
    actionsWithItem?: Partial<
      Record<ItemConfigId | "default", ActionQueueType>
    >;
  };
};

type ActionBase = {
  _name?: string;
  id: ActionId;
  blockGui?: boolean;
  unskippable?: boolean;
};

//
// ACTION ARRAYS
//
export type Action_Sequence = ActionBase & {
  id: ActionId.sequence;
  actionOrArray: ActionQueueType;
};

//
// CONDITIONAL ACTIONS
//
export type ActionConditionalData = {
  gamePlay: GamePlayType;
  gameItems: GameItemsType;
};

export type ActionConditionalItem = {
  condition: boolean;
  actions: ActionQueueType;
};

export type Action_Conditional = ActionBase & {
  id: ActionId.conditional;
  getConditionalActions: (
    data: ActionConditionalData
  ) => ActionConditionalItem[];
};

//
// INDIVIDUAL ACTIONS
//
export type ActionFacingProps = {
  faceCharacterId?: CharacterId;
  faceDirection?: Direction;
  faceItemId?: string;
  facePosition?: Position;
};

export enum TalkDirection {
  "default" = "default",
  "up" = "up",
  "down" = "down",
  "random" = "random",
}

export type TalkRenderOptionsType = {
  eyesClosed?: true | false | "random";
  direction?: TalkDirection;
};

export type ExtendWalkPathType = {
  direction: Direction;
  distance: number;
};

export type Action_CharacterWalk = ActionBase & {
  id: ActionId.characterWalk;
  characterId?: CharacterId;
  walkPath?: CharacterWalkPath;

  walkFromPosition?: Position;
  walkToPosition?: Position;

  walkInDirection?: Direction;
  walkInDirectionTo?: Position;
  walkInDirectionFrom?: Position;
  walkInDirectionDistance?: number;

  walkIntoSceneFromDirection?: Direction;
  walkIntoSceneToPosition?: Position;

  walkOutOfSceneToDirection?: Direction;
  walkOutOfSceneWithBlockedGui?: boolean;
  walkOutOfSceneWithBlockedGuiInitiatedRef?: any;

  walkAnimationFrame: number;
  extendPath?: ExtendWalkPathType;
  ignoreWalkMap?: boolean;
};

export type Action_CharacterTalk = ActionBase & {
  id: ActionId.characterTalk;
  blockGui: true;
  characterId?: CharacterId;
  dialog: TranslatedString;
  talkRenderOptions?: TalkRenderOptionsType;
} & ActionFacingProps;

export type Action_CharacterFace = ActionBase & {
  id: ActionId.characterFace;
  characterId?: CharacterId;
} & ActionFacingProps;

export type Action_CharacterDeath = ActionBase & {
  id: ActionId.characterDeath;
};

export type Action_CharacterAnimation = ActionBase & {
  id: ActionId.characterAnimation;
  blockGui: true;
  unskippable: true;
  characterId?: CharacterId;
  animationId: string;
  keepLastFrame?: boolean;
};

export type Action_Pause = ActionBase & {
  id: ActionId.pause;
  blockGui: true;
  unskippable: true;
  durationSec: number;
};

export type Action_Execute = ActionBase & {
  id: ActionId.execute;
  execute: () => void;
};

export type Action_ItemInCursorSwapConfig = ActionBase & {
  id: ActionId.itemInCursorSwapConfig;
  newItemConfigId: ItemConfigId;
};

export type Action_ItemInCursorToTrash = ActionBase & {
  id: ActionId.itemInCursorToTrash;
};

export type Action_ItemInCursorToSceneOverlay = ActionBase &
  ItemPropInSceneOverlay & {
    id: ActionId.itemInCursorToSceneOverlay;
  };

export type Action_PutNewItemIntoCursor = ActionBase & {
  id: ActionId.putNewItemIntoCursor;
  newItemConfigId: ItemConfigId;
};

export enum Action_SaveGameTypeEnum {
  "deathsave" = "deathsave",
}

export type Action_SaveGame = ActionBase & {
  id: ActionId.saveGame;
  type: Action_SaveGameTypeEnum;
};

export enum ActionAudioTypeEnum {
  "setBackgroundSound" = "setBackgroundSound",
  "setBackgroundMusic" = "setBackgroundMusic",
  "setOverrideMusic" = "setOverrideMusic",
}

export type Action_Audio = ActionBase &
  GamePlayAudioType & {
    id: ActionId.audio;
    type?: ActionAudioTypeEnum;
  };

export type PlaySoundFunctionProps = {
  soundConfigId: string;
  volume?: number;
  repeat?: number;
  onEnd?: () => void;
};

export type Action_Sound = ActionBase &
  PlaySoundFunctionProps & {
    id: ActionId.sound;
  };

export type Action_Scene = ActionBase &
  GamePlayCurrentSceneType & {
    id: ActionId.scene;
  };

export type ActionCinematicOnEnd = CinematicOnEnd & {
  returnToThisScene?: boolean;
};

export type Action_Cinematic = ActionBase & {
  id: ActionId.cinematic;
  cinematicId: CinematicId;
  onEnd: ActionCinematicOnEnd;
};

export type Action_Objective = ActionBase & {
  id: ActionId.objective;
  newObjective?: GameObjective;
  nextObjective?: GameObjective;
};

export type Action_Event = ActionBase & {
  id: ActionId.event;
  getUpdatedEvents?: (events) => GameEventsType;
  setEvent?: { key: GameEvent; value: any };
};

export type Action_Effect = ActionBase & {
  id: ActionId.effect;
  durationSec: number;
  effectShakeViewport?: GameEffectShakeViewport;
  effectFadeViewport?: GameEffectFadeViewport;
  effectFadeScene?: GameEffectFadeScene;
};

export enum SkillActionTypeEnum {
  "StartSkill" = "StartSkill",
  "StopSkill" = "StopSkill",
  "StopAllSkills" = "StopAllSkills",
  "ResetSkill" = "ResetSkill",
}

export type Action_Skill = ActionBase & {
  id: ActionId.skill;
  skillId?: SkillId;
  skillActionType: SkillActionTypeEnum;
  appendStopActions?: boolean;
  prependStopActions?: boolean;
};

export type Action_SceneOverlayAnimation = ActionBase & {
  id: ActionId.sceneOverlayAnimation;
  sceneOverlayAnimationId: SceneOverlayAnimationId;
};
